import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";

function Card(props) {
  const [skeleton, setSkeleton] = useState(false);

  useEffect(() => {
    setSkeleton(props.isLoading);
  }, [props.isLoading]);

  return (
    <section className={props.classN ? props.classN + " card" : "card"}>
      <AnimatePresence>
        {skeleton && (
          <motion.div
            className={"skeleton-parent"}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {props.type === "table" ? (
              <div className="whole-space">
                <div className="skeleton-text"> </div>
                <div className="skeleton-text"> </div>
                <div className="skeleton-text"> </div>
                <div className="skeleton-text"> </div>
                <div className="skeleton-text"> </div>
                <div className="skeleton-text"> </div>
                <div className="skeleton-text"> </div>
                <div className="skeleton-text"> </div>
                <div className="skeleton-text"> </div>
              </div>
            ) : props.type === "mosaic" ? (
              <div className="flex-container mosaic-container whole-space">
                {props.cantidad ? (
                  new Array(props.cantidad)
                    .fill(0)
                    .map((e, i) => <div key={i} className="skeleton-picture"></div>)
                ) : (
                  <>
                    <div className="skeleton-picture"></div>
                    <div className="skeleton-picture"></div>
                    <div className="skeleton-picture"></div>
                    <div className="skeleton-picture"></div>
                    <div className="skeleton-picture"></div>
                    <div className="skeleton-picture"></div>
                    <div className="skeleton-picture"></div>
                    <div className="skeleton-picture"></div>
                  </>
                )}
              </div>
            ) : (
              <>
                <div>
                  <div className="skeleton-picture"></div>
                  <div className="skeleton-picture"></div>
                </div>
                <div>
                  <div className="skeleton-text"> </div>
                  <div className="skeleton-text"> </div>
                  <div className="skeleton-text"> </div>
                  <div className="skeleton-text"> </div>
                  <div className="skeleton-text"> </div>
                  <div className="skeleton-text"> </div>
                </div>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {props.children}
    </section>
  );
}

export default Card;
