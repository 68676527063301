import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSubmit from "../../../Hooks/useSubmit";
import Form from "../../../Components/Form/Form";
import FormToast from "../../../Components/Form/FormToast";
import ModalRemove from "../../../Components/Other/ModalRemove";
import Modal from "../../../Components/Other/Modal";
import Options from "../../../Components/DataBooks/Options";
import NoResults from "../../../IMG/NoResults.svg";
import {
  almacenarFuenteLetra,
  eliminarFuenteLetra,
  obtenerFuentesLetra,
} from "../../../Redux/reducers/fuentesLetraSlice";
import { useForm } from "react-hook-form";
import Table from "../../../Components/DataBooks/Table";

function FuentesLetra() {
  const [isRemovingItem, setIsRemovingItem] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [fuenteLetra, setFuenteLetra] = useState(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fontsStyles, setFontsStyles] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const { fuentesLetra} = useSelector((state) => state.fuentesLetra);

  const { reset } = useForm();
  const dispatch = useDispatch();
  const headers = [
    { id: 1, name: "Fuente" },
    { id: 2, name: "Extensión" },
  ];

  const { onSubmit, sendingData, successCode } = useSubmit(
    false,
    almacenarFuenteLetra,
    null
  );

  useEffect(() => {
    cargarDatos();
    setTimeout(() => {
      setIsModalVisible(false);
    }, 1000);
  }, [successCode]);

  const cargarDatos = () => {
    setIsLoading(true);
    dispatch(obtenerFuentesLetra());
  };

  useEffect(() => {
    if (fontsStyles?.length >0 && fuentesLetra?.length >0)
        setIsLoading(false);
  }, [fuentesLetra, fontsStyles]);

  const eliminar = (id) => {
    dispatch(eliminarFuenteLetra(id));
  };

  useEffect(() => {
    fuentesLetra?.forEach((e) => {
      saveFontFace(
        e.ArchivoId,
        e.ArchivoNombre,
        `data:application/octet-stream;base64,${e.Data}`
      );
    });
  }, [fuentesLetra]);

  const saveFontFace = async (id, name, data) => {
    let fontsArr = fontsStyles;
    const fontFace = new FontFace(name, `url(${data})`);
    document.fonts.add(fontFace); //
    try {
      // Carga la fuente
      await fontFace.load();
      fontsArr.push({ font: <h2 style={{ fontFamily: name }}>{name}</h2>, id });
      setFontsStyles(fontsArr);
    } catch (e) {
      console.error(e);
    }
  };

  const FieldsDataForm = {
    Fuente: {
      label: "Nombre de la configuración",
      type: "file",
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const renderTable = () => {
    return (
      <Table class="table display dataTable" isLoading={isLoading}>
        <thead>
          <tr>
            {headers?.map((header) => (
              <th key={header.id}>{header.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fuentesLetra?.length>0?
          fuentesLetra?.map((item) => (
            <tr key={item.ArchivoId}>
              <td>{fontsStyles.find((e) => e.id === item.ArchivoId)?.font}</td>
              <td>{item.ContentType}</td>
              <td>
                <Options
                  actions={[
                    {
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Eliminar",
                      onclick: () => {
                        setIsRemovingItem(true);
                        setFuenteLetra(item);
                      },
                    },
                  ]}
                />
              </td>
            </tr>
          )):
          <div className="no-results-container">
              <img className="img-no-results" src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>}
        </tbody>
      </Table>
    );
  };

  return (
    <AnimatePresence>
      <div className="form-title" key="fontsOption">
      <FormToast
        code={successCode}
        title={successCode == 1 ? "¡Bien!" : "Algo anda mal"}
        desc={
          successCode == 1
            ? "Datos almacenados correctamente"
            : "Revisa los datos e intenta de nuevo"
        }
      />
        <br />
        <br />
        <button
          className="btn btn-primary settings-right-superior-container"
          onClick={() => setIsModalVisible(true)}
        >
          Nueva fuente de letra
        </button>
      </div>
      <motion.div className="flex-container data-settings-container" key="fontsSettings">
        <div>
          <div className="editor-data-settings-container">
            <div className="editor-data-settings">
              <div className="flex-container">
                <h3>Fuentes de letra</h3>
              </div>
              {renderTable()}
              <ModalRemove
                onRecoil={() => setIsRemovingItem(false)}
                title="Eliminar fuente de letra"
                onSubmit={() => eliminar(fuenteLetra?.ArchivoId)}
                isVisible={isRemovingItem}
                recordName={fuenteLetra?.ArchivoNombre}
              />
            </div>
            <Modal
              isVisible={isModalVisible}
              title={
                isEditing
                  ? "Editar configuración de datos"
                  : "Nueva configuración de datos"
              }
            >
              <Form
                dynamicForm={FieldsDataForm}
                onSubmit={(data) => onSubmit(data)}
                successCode={successCode}
                isEditing={isEditing}
                dataToEdit={isEditing && fuenteLetra}
                onRecoil={() => {
                  reset({});
                  setIsEditing(false);
                  setIsModalVisible(false);
                }}
                sendingData={sendingData}
              />
            </Modal>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default FuentesLetra;
