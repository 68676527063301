import React from "react";
import UserMenu from "./UserMenu";
import imagotipo from "../../IMG/Imagotipo.svg";
import { Link, useNavigate } from "react-router-dom";
import ThemeSwitch from "../Other/ThemeSwitch";
import Guide from "../Other/Guide";

function Header({ toggleMenu, urlToBack, wideMenu, isHeaderOnEditor }) {
  const navigate=useNavigate();
  return (
    <header className="flex-container" id="header">
        {!isHeaderOnEditor &&(
      <div className="toggle-container">
        <button className={`btn ${wideMenu?"is-hidden-on-mobile":null}`} id="toggle-btn" onClick={toggleMenu}>
          <i className="material-icons"></i>
        </button>
        <button
          className={`btn is-visible-on-mobile ${!wideMenu?"is-hidden":null}`}
          id="toggle-x-btn"
          onClick={toggleMenu}
        >
          <i className="Material-Icons-Outlined"></i>
        </button>
      </div>
        )}
        {urlToBack && (
          <div>
            <button
              className="btn"
              onClick={()=>navigate(urlToBack)}
            >
              <i className='material-icons'></i>
              Ir hacia atrás
            </button>
          </div>
        )}
      <div className="logo-container is-visible-on-mobile">
        <img src={imagotipo} alt="Logo empresa"></img>
      </div>

      <div className="flex-container is-hidden-on-mobile" id="header-menu">
        <ThemeSwitch />
        <Guide />
        <Link to={"/configuraciones"} className="btn grey-text">
          <i className="Material-Icons-Outlined"></i>
        </Link>
        <UserMenu />
      </div>
    </header>
  );
}

export default Header;
