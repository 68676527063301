import React, { useEffect, useImperativeHandle } from "react";
import { FormInput } from "../Form/FormElements";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import FormButtons from "./FormButtons";
import { forwardRef } from "react";

const Form = forwardRef((props, ref) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "dynamicFieldArray", // unique name for your Field Array
  });

  useEffect(() => {
    if (!props.isEditing && props.successCode == 1) {
      reset({});
    }
  }, [props.isEditing, props.successCode]);



  useEffect(() => {
    reset(props.dataToEdit);
  }, [reset, props.dataToEdit]);

  const sendData = (e) => {
    handleSubmit(props.onSubmit);
    e.preventDefault();
  };

  useImperativeHandle(ref, () => {
    return{
      sendData
    }
  });

  const formInputs = Object.keys(props.dynamicForm).map((e) => {
    if (props.dynamicForm[e] === props.dynamicForm.dynamicFieldArray) {
      return (
        <section key={e}>
          
          {fields.map((field, index) => (
            <>
           
              <hr/>
            <div className="form-flex" key={field.id}>
              {Object.keys(props.dynamicForm[e]).map((a) => (
                <div key={a}>
                  <Controller
                    name={`${e}.${index}.${a}`}
                    control={control}
                    rules={props.dynamicForm[e][a].rules}
                    defaultValue={props.dynamicForm[e][a].defaultValue}
                    render={({ field }) => (
                      <div>
                        <FormInput
                          error={errors[a] && "Este campo es requerido"}
                          value={field.value}
                          onChange={(v) => {
                            field.onChange(v);
                            if (props.dynamicForm[e][a].changeHand) {
                              props.dynamicForm[e][a].changeHand(v);
                            }
                          }}
                          {...props.dynamicForm[e][a]}
                        />
                      </div>
                    )}
                  />
                </div>
              ))}
              <button
                className="btn simple-btn array-btn-delete"
                onClick={() => remove(index)}
              >
                <i className="material-icons"></i>
              </button>
            </div>
            </>
          ))}
          <button
            className="btn simple-btn"
            onClick={(e) => {
              e.preventDefault();
              append({});
            }}
          >
            <i className="material-icons"></i>
            {props.btnAddToArray}
          </button>
        </section>
      );
    } else {
      const { rules, defaultValue } = props.dynamicForm[e];
      return (
        <section key={e}>
          <Controller
            name={e}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => (
              <div>
                <FormInput
                  error={errors[e] && "Este campo es requerido"}
                  value={field.value}
                  onChange={(v) => {
                    field.onChange(v);
                    if (props.dynamicForm[e].changeHand)
                      props.dynamicForm[e].changeHand(v);
                  }}
                  {...props.dynamicForm[e]}
                />
              </div>
            )}
          />
        </section>
      );
    }
  });

  return (
    <form className="form-flex">
      {formInputs}
      {!props.formButtons ? (
        <FormButtons
        isSendingRequest={props.sendingData}
          progressAction="Guardar"
          onProgress={handleSubmit(props.onSubmit)}
          onRecoil={(e) => {
            e.preventDefault();
            props.onRecoil();
          }}
        />
      ) : (
        props.formButtons
      )}
    </form>
  );
});

export default Form;
