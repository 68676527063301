import React, { useState } from "react";
import useComponentVisible from "../../Hooks/useComponentVisible";

function Options({actions}) 
{
    const [showingOptions,setShowingOptions]=useState(false);
    const { ref, isComponentVisible } =useComponentVisible(showingOptions);

  return (
    <>
    <button className={isComponentVisible==true?"btn is-showing-options":"btn"} onClick={()=>setShowingOptions(!showingOptions)}><i className="material-icons"></i></button>
    <div className="register-options-container" ref={ref}>
      {actions?.map((action,index)=>(
      <button className="btn" onClick={()=>action.onclick()} key={index}>
        {action?.icon} {action?.text}
      </button>
      ))}
    </div>
    </>
  );
}

export default Options;
