import {useState } from "react";

export const usePagination = (recordsPerPage, totalPageRecords) => {
    const totalPages = Math.ceil(totalPageRecords / recordsPerPage);
    const [startPageIndex, setStatrPageIndex] = useState(0);
    const [endPageIndex, setEndPageIndex] = useState(recordsPerPage - 1);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
  

    const displayPage = (pageNo) => {
      setCurrentPageIndex(pageNo);
      let end_page_index = recordsPerPage * pageNo;
      let start_page_index = recordsPerPage * pageNo - recordsPerPage;
      setStatrPageIndex(start_page_index);
  
      if (end_page_index > totalPageRecords) {
        setEndPageIndex(totalPageRecords);
      } else {
        setEndPageIndex(end_page_index);
      }
    };
  
    return [
      totalPages,
      startPageIndex,
      endPageIndex,
      currentPageIndex,
      displayPage,
    ];
  };


export default usePagination;
