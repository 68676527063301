import React from "react";
import ConfiguracionesGenerales from "../../Views/Configuraciones/ConfiguracionesGenerales";
import Plantillas from "../../Views/Configuraciones/Plantillas/Plantillas";
import Password from "../../Views/Configuraciones/Password";
import { useState } from "react";
import DatosPlantillas from "../../Views/Configuraciones/DatosCertificados/DatosPlantillas";
import ConfiguracionApi from "../../Views/Configuraciones/ApiExterna/ConfiguracionApi";
import FuentesLetra from "../../Views/Configuraciones/FuentesLetra/FuentesLetra";
import Carousel from "./Carousel";
import jwtDecode from "jwt-decode";
import AppCredentials from "../../Views/Configuraciones/AppCredentials/AppCredentials";

function SettingsMenu({ handleEvent }) {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      name: "Configuraciones generales",
      component: <ConfiguracionesGenerales />,
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas","generador(a) de plantillas"],
    },
    {
      name: "Fuentes de letra",
      component: <FuentesLetra />,
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas","generador(a) de plantillas"],
    },
    {
      name: "Datos de certificados",
      component: <DatosPlantillas />,
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas"],
    },
    {
      name: "Fuentes de datos web externas",
      component: <ConfiguracionApi />,
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas"],
    },
    {
      name: "Plantillas",
      component: <Plantillas />,
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas","generador(a) de plantillas"],
    },
    {
      name: "Credenciales de App",
      component: <AppCredentials />,
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas","generador(a) de plantillas"],
    },
    {
      name: "Gestión de contraseña",
      component: <Password />,
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas","generador(a) de plantillas"],
    },
  ];

  const extractRoles = () => {
    let token = localStorage.getItem("token");

    let jsonToken = jwtDecode(token);

    if (!Array.isArray(jsonToken.roles)) return [jsonToken.roles];
    else return jsonToken.roles;
  };

  const buscarCoincidencia = (arr1, arr2) => {
    for (var i = 0; i < arr1.length; i++) {
      if (arr2.indexOf(arr1[i]) != -1) return true;
    }
    return;
  };

  return (
    <div className="settings-menu-mobile">
      <div className="nav-settings-container">
        <div className="nav-content">
          <Carousel
            isLoading={false}
            classN="nav-settings-container is-visible-on-mobile"
            long={300}
          >
            {tabs.map((tab, index) =>
              buscarCoincidencia(extractRoles(), tab.roles) ? (
                <li
                  key={index}
                  onClick={() => {
                    handleEvent(tab.component);
                    setActiveTab(index);
                  }}
                  className={`nav-item-settings " ${
                    activeTab == index && "settings-tab-selected"
                  }`}
                >
                  {tab.name}
                </li>
              ) : null
            )}
          </Carousel>
          <ul className="nav-settings-container is-hidden-on-mobile">
            {tabs.map((tab, index) =>
              buscarCoincidencia(extractRoles(), tab.roles) ? (
                <li
                  key={index}
                  onClick={() => {
                    handleEvent(tab.component);
                    setActiveTab(index);
                  }}
                  className={`nav-item-settings " ${
                    activeTab == index && "settings-tab-selected"
                  }`}
                >
                  {tab.name}
                </li>
              ) : null
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SettingsMenu;
