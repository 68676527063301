import React from 'react';
import Select, { components } from 'react-select';

export default function ReactSelect(props) {
    const { isValid, isRequired,options } = props;
    const styles = {

        control: (baseStyles, state) => {
            return {
                ...baseStyles,
                boxShadow: isValid ? `0 0 0 1px #2684ff` : 0,

                borderColor: isValid ? '#a7a7a7' : 'red',
                ":hover": {
                    borderColor: isValid ? '#a7a7a7' : 'red',
                },
                // state.isFocused ? 'red' : 'grey',
                minHeight: '30px',
                marginTop: 5,
                marginBottom: 5,
            }
        },
        dropdownIndicator: (styles) => ({
            ...styles,
            paddingTop: 5,
            paddingBottom: 5,
        }),
        clearIndicator: (styles) => ({
            ...styles,
            paddingTop: 5,
            paddingBottom: 5,
        }),
        input: (style) => ({
            ...style,
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        valueContainer: (style) => ({
            ...style,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        multiValue: (style) => ({
            ...style,
            backgroundColor: '#E6F3EE',
            color: '#128D62',
        }),
        multiValueRemove: (style) => ({
            ...style,
            ":hover": {
                backgroundColor: '#00875A',
                color: '#fff'
            }
        }),
        multiValueLabel: (style) => ({
            ...style,
            backgroundColor: '#E6F3EE',
            color: '#128D62'
        }),
        menuPortal: style => ({ ...style, zIndex: 9999 })
    }

    const Control = ({ children, ...props }) => (
        <components.Control {...props}>
            
            {children}
            {/* {isRequired &&
                <i className={`material-icons to-right required-symbol ${!isValid ? "red-icon" : "input-symbol"}`}></i>
            } */}

        </components.Control>
        
    );


    return (
        <Select
            components={{ Control }}
            styles={styles}
            isClearable={false}
            options={options}
            isMulti={props.isMulti}
            isSearchable={props.isSearchable}
            isDisabled={props.isDisabled}
            classNamePrefix="select"
            placeholder={"Seleccionar"}
            {...props}
            menuPortalTarget={document.body}
            menuPosition='fixed'

        />
    )
}
