import { useRoutes } from "react-router-dom";
import ProtectedRoute from "./Components/Other/ProtectedRoute";
import Login from "./Pages/Login";
import NoPage from "./Pages/NoPage";
import Required from "./Pages/Required";
import Inicio from "./Views/Inicio";
import Settings from "./Views/Configuraciones/Settings";
import ListaPlantillas from "./Views/ListaPlantillas";
import ListaCertificados from "./Views/ListaCertificados";
import Principal from "./Pages/Principal";
import Edicion from "./Pages/Edicion";
import NewProyect from "./Views/NewProject";
import Logs from "./Views/logs/Logs";
import ListUsuarios from "./Views/ListUsuarios";
import ListEmpresas from "./Views/ListEmpresas";
import CertificadoGUID from "./Pages/CertificadoGUID";
import ForgotPassword from "./Pages/ForgotPassword";
import ChangePassword from "./Pages/ChangePassword";
import PreviewCertificates from "./Pages/PreviewCertificates";

const crumbs = () => {
  const adminPrivateRoutes = routes[0].children[0].children.find(
    (e) => e.title == "protected Admin"
  ).children;
  const c = routes[0].children[0].children;
  return c.concat(adminPrivateRoutes);
};

const routes = [
  {
    title: "protected",
    path: "/",
    element: (
      <ProtectedRoute
        allowedRoles={[
          "administrador(a) del sistema",
          "administrador(a) de plantillas",
          "generador(a) de plantillas",
        ]}
      />
    ),
    children: [
      {
        title: "certysafe",
        path: "/",
        element: <Principal crumbs={crumbs} />,
        children: [
          {
            title: "Inicio",
            path: "/",
            element: <Inicio />,
            name: "Inicio",
          },

          {
            title: "Plantillas",
            path: "plantillas",
            element: <ListaPlantillas />,
            name: "plantillas",
          },
          {
            title: "Nuevo grupo de certificados",
            path: "nuevo-certificado/:id",
            element: <NewProyect />,
            name: "nuevo-certificado",
          },
          {
            title: "Certificados",
            path: "certificados",
            element: <ListaCertificados />,
            name: "certificados",
          },
          {
            title: "Configuración",
            path: "configuraciones",
            element: <Settings />,
            name: "configuraciones",
          },
          {
            title: "protected Admin",
            path: "/",
            element: (
              <ProtectedRoute
                allowedRoles={[
                  "administrador(a) del sistema",
                  "administrador(a) de plantillas",
                ]}
              />
            ),
            children: [
              {
                title: "Cambios hechos en plantillas",
                path: "configuraciones/logs/:tableName",
                element: <Logs />,
                name: "cambiosPlantillas",
              },
              {
                title: "Empresas",
                path: "empresas",
                element: <ListEmpresas />,
                name: "empresas",
              },
              {
                title: "Usuarios",
                path: "usuarios",
                element: <ListUsuarios />,
                name: "usuarios",
              },
              {
                title: "Cambios de usuarios",
                path: "usuarios/logs/:tableName",
                element: <Logs />,
                name: "cambiosUsuarios",
              },
            ],
          },
        ],
      },
      {
        title: "Nueva plantilla",
        path: "nueva-plantilla",
        element: <Edicion />,
        name: "nuevo-certificado",
      },
      {
        title: "Edición de Plantilla",
        path: "edicion-plantilla/:id",
        element: <Edicion />,
        name: "edicion-plantilla",
      },
    ],
  },

  { title: "Login", path: "login", element: <Login /> },
  {
    title: "Verificacion Certificado",
    path: "CertificateByUUID/:guid",
    element: <CertificadoGUID />,
  },
  { title: "Required", path: "required", element: <Required /> },
  { title: "Not Found", path: "*", element: <NoPage /> },
  {
    title: "forgotPassword",
    path: "login/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    title: "recoverPassword",
    path: "Recover/Recover",
    element: <ChangePassword />,
  },
  {
    title: "Preview de certificados",
    path: "preview-certificates/:id",
    element: <PreviewCertificates />,
    name: "preview-certificates",
  },
];

export const RouteElements = () => {
  let element = useRoutes(routes);
  return element;
};
