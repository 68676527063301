import React from "react";
import FormButtons from "../Form/FormButtons";
import Modal from "./Modal";
import DeleteImg from "../../IMG/DeleteQuestion.svg";
import Success from "../../IMG/Success.svg";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

function ModalRemove({
  onRecoil,
  onSubmit,
  isVisible,
  title,
  recordName,
  question,
  success
}) {

  return (
    <Modal
      classN="modal-delete"
      title={title}
      isVisible={isVisible}
      bottomItems={
        <div className="bottom-on-delete">
          <FormButtons onRecoil={onRecoil} onProgress={onSubmit} />
        </div>
      }
    >
      <div className="modal-info">
        <AnimatePresence>
          {!success ? (
            <>
              <motion.img
                initial={{ scale: 0.2 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.1 }}
                transition={{ duration: 0.5 }}
                src={DeleteImg}
                alt="Ilustración advertencia"
                className="warning-icon"
              ></motion.img>
              <p>
                {question
                  ? question
                  : `¿Es seguro que desea eliminar/inactivar el registro de ${recordName}?`}
              </p>
            </>
          ) : (
            <motion.div
              initial={{ scale: 0.2 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.1 }}
              transition={{ duration: 1 }}
            >
              <img
                src={Success}
                alt="Ilustración operación exitosa"
                className="warning-icon"
              ></img>
              <p>Registro eliminato exitosamente.</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Modal>
  );
}

export default ModalRemove;
