import React, { useEffect, useRef, useState } from "react";

import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ContextActions from "../Components/DataBooks/ContextActions";
import Options from "../Components/DataBooks/Options";
import PageActions from "../Components/DataBooks/PageActions";
import Form from "../Components/Form/Form";
import Card from "../Components/Other/Card";
import Modal from "../Components/Other/Modal";
import ModalRemove from "../Components/Other/ModalRemove";
import usePagination from "../Hooks/usePagination";
import NoResults from "../IMG/NoResults.svg";
import { obtenerRoles } from "../Redux/reducers/usuariosSlice";
import {
  actualizarUsuario,
  agregarUsuario,
  eliminarUsuario,
  obtenerUsuarios,
} from "../Redux/reducers/usuariosSlice";
import FormToast from "../Components/Form/FormToast";
import Table from "../Components/DataBooks/Table";
import MosaicData from "../Components/DataBooks/MosaicData";

export default function ListUsuarios() {
  const [showModal, setShowModal] = useState();
  const [removingItem, setRemovingItem] = useState(null);
  const [dataEdit, setDataEdit] = useState({});
  const [data, setData] = useState();
  const [wayOfDisplayData, setWayOfDisplayData] = useState(2);
  const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
  const [sendingData, setSendingData] = useState(false);
  const [successCode, setSuccessCode] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const dispatch = useDispatch();

  const { usuarios, isLoading } = useSelector((state) => state.usuarios);
  const { roles } = useSelector((state) => state.usuarios);

  const modelForm = {
    Email: {
      label: "Correo",
      type: "text",
      placeholder: "Correo",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
    Username: {
      label: "Nombre de usuario",
      type: "text",
      placeholder: "Nombre de usuario",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
    Password: {
      label: "Contraseña",
      type: "password",
      placeholder: "Contraseña",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },

    FullName: {
      label: "Nombre completo",
      type: "text",
      placeholder: "Nombre completo",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },

    PasswordChangeNextLogin: {
      label: "Contraseña temporal ?",
      type: "checkbox",
      defaultValue: false,
    },

    PasswordExpire: {
      label: "La contraseña expira ?",
      type: "checkbox",
      defaultValue: false,
    },

    PasswordExpireAt: {
      label: "Fecha de expiración",
      type: "date",
      defaultValue: "",
    },

    IsSystemUser: {
      label: "Es usuario del sistema ?",
      type: "checkbox",
      defaultValue: false,
    },

    RolesId: {
      label: "Roles",
      type: "dropdown-multiple",
      options: roles.map((e) => {
        return { label: e.RolName, value: e.RolId };
      }),
      defaultValue: "",
      long: false,
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const modelFormNoPassword = {
    Username: {
      label: "Nombre de usuario",
      type: "text",
      placeholder: "Nombre de usuario",
      defaultValue: "",
      required: true,
      long: false,
      rules: {
        required: true,
      },
    },
    Email: {
      label: "Correo",
      type: "text",
      placeholder: "Correo",
      defaultValue: "",
      icon: <i className="material-icons input-symbol"></i>,
      required: true,
      rules: {
        required: true,
      },
    },
    FullName: {
      label: "Nombre completo",
      type: "text",
      placeholder: "Nombre completo",
      defaultValue: "",
      required: true,
      long: false,
      rules: {
        required: true,
      },
    },

    RolesId: {
      label: "Roles",
      type: "dropdown-multiple",
      options: roles.map((e) => {
        return { label: e.RolName, value: e.RolId };
      }),
      defaultValue: "",
      long: false,
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //ESLINT
    displayPage,
  ] = usePagination(registrosPorPagina, usuarios.length);

  useEffect(() => {
    setData(usuarios?.slice(startPageIndex, endPageIndex));
  }, [startPageIndex, endPageIndex, usuarios]);

  useEffect(() => {
    cargarDatos();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSuccessCode(0);
    }, 2000);
  }, [successCode]);

  const cargarDatos = (nombre = null) => {
    dispatch(obtenerUsuarios({ name: nombre }));
    dispatch(obtenerRoles());
  };

  const cambiarEstadoActivo = async (data, state) => {
    let { payload } = await dispatch(
      eliminarUsuario({ ...data, IsActive: state })
    );

    if (payload.success) {
      setDeleteSuccess(true);
      setTimeout(() => {
        setRemovingItem(null);
        setDeleteSuccess(false);
      }, 2000);
      cargarDatos();
      return true;
    } else {
      return false;
    }
  };

  const editModal = (item) => {
    setDataEdit(item);
    setShowModal(true);
  };

  const renderTable = () => {
    return (
      <Table class="table display dataTable" isLoading={isLoading}>
          <thead>
            <tr>
              <th>Nombre de Usuario</th>

              <th>Nombre Completo</th>
              <th>Email</th>
              <th>Estado</th>
              <th>Usuario del sistema</th>
              <th>Roles</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr key={item.UserId}>
                <td>{item.Username}</td>
                <td>{item.FullName}</td>
                <td>{item.Email}</td>
                <td>{item.IsActive == 1 ? "Activo" : "Inactivo"}</td>
                <td>{item.IsSystemUser == 1 ? "Si" : "No"}</td>
                <td>{item.Roles}</td>
                <td>
                  <Options
                    actions={[
                      {
                        onclick: () => editModal(item),
                        icon: <i className="Material-Icons-Outlined"></i>,
                        text: "editar",
                      },
                      {
                        onclick: () =>
                          setRemovingItem({
                            item: item,
                            activeState: item.IsActive == 1 ? 2 : 1,
                          }),
                        icon: <i className="Material-Icons-Outlined"></i>,
                        text: item.IsActive ? "desactivar" : "activar",
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
    );
  };
  const onSubmit = (data) => {
    setSendingData(true);
    if (data.UserId) {
      if (data.PasswordExpireAt == "") {
        delete data.PasswordExpireAt;
      }
      dispatch(actualizarUsuario({ ...data, Roles: data.RolesId }))
        .unwrap()
        .then((res) => {
          cargarDatos();
          setShowModal(false);
          setSendingData(false);
          setSuccessCode(1);
        })
        .catch((err) => {
          console.log(err);
          setSendingData(false);
          setSuccessCode(2);
        });
    } else {
      //agrega
      dispatch(agregarUsuario({ ...data, Roles: data.RolesId }))
        .unwrap()
        .then((res) => {
          cargarDatos();
          setShowModal(false);
          setSendingData(false);
          setSuccessCode(1);
        })
        .catch((err) => {
          console.log(err);
          setSendingData(false);
          setSuccessCode(2);
        });
    }
  };

  return (
    <>
      <FormToast
        code={successCode !== 0 && successCode}
        title={successCode === 1 ? "¡Bien!" : "Algo anda mal"}
        desc={
          successCode === 1
            ? "Datos almacenados correctamente"
            : "Revisa los datos e intenta de nuevo"
        }
      />
      <Modal isVisible={showModal} title={dataEdit ? "Editar" : "Agregar"}>
        <Form
          sendingData={sendingData}
          dynamicForm={dataEdit ? modelFormNoPassword : modelForm}
          onSubmit={onSubmit}
          onRecoil={() => {
            setShowModal(false);
          }}
          dataToEdit={dataEdit}
        />
      </Modal>

      <ContextActions
        btnNew="Nuevo usuario"
        onClick={() => {
          editModal(null);
        }}
        searchFunction={cargarDatos}
        wayOfDisplayData={wayOfDisplayData}
        setWayOfDisplayData={setWayOfDisplayData}
        logs="Users"
        showAll={false}
      />

      <ModalRemove
      success={deleteSuccess}
        title={
          removingItem?.activeState == 2
            ? "Desactivar usuario"
            : "Activar usuario"
        }
        question={
          removingItem?.activeState == 2
            ? "¿Es seguro que desea desactivar este registro?"
            : "Es seguro que desea activar este registro?"
        }
        onRecoil={() => setRemovingItem(null)}
        onSubmit={() =>
          cambiarEstadoActivo(
            removingItem?.item,
            removingItem?.activeState == 1
          )
        }
        isVisible={removingItem !== null}
        recordName={removingItem?.item.UserName}
      />

      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card isLoading={isLoading} type={"table"}>
          <h2 className="dark-text table-section-name">Usuarios</h2>
          {!data?.length > 0 ? (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          ) :  wayOfDisplayData === 1 ? (
            <MosaicData
              data={data}
              idField="UserId"
              titleField="Username"
              editar={(id)=>editModal(data.find(e=>e.UserId==id))}
              borrar={(item) => setRemovingItem(item)}
            />
          ) : (
            <div className="table-container">{renderTable()}</div>
          )}
          <PageActions
            count={totalPages} //usuarios.PageCount
            onChange={(value) => displayPage(value)}
            changeRegistersCount={(value) => setRegistrosPorPagina(value)}
            registersCount={registrosPorPagina} //usuarios.RowsCount
          />
        </Card>
      </motion.main>
    </>
  );
}
