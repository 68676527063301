import React, { useState } from "react";
import Header from "../Components/Layout/Header";
import Loading from "../IMG/Loading Robot.gif";
import spinner from "../IMG/SpinnerSending.gif";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import CertificadoCanvas from "../Components/Editor/CertificadoCanvas";
import Palette, { PaletteMovil } from "../Components/Editor/Palette";
import {
  almacenarPlantilla,
  actualizarPlantilla,
  obtenerPlantillaPorId,
  obtenerPlantillaInfoPorId,
} from "../Redux/reducers/plantillasSlice";
import PaletteMenu from "../Components/Editor/PaletteMenu";
import ShapesToolbar from "../Components/Editor/MenuSections/ShapesToolbar";
import TemplatesToolbar from "../Components/Editor/MenuSections/TemplatesToolbar";
import FormToast from "../Components/Form/FormToast";
import useUndoRedo from "../Hooks/useUndoRedo";
import { FormInput } from "../Components/Form/FormElements";
import StylePalette from "../Components/Editor/StylePalette";
import useSubmit from "../Hooks/useSubmit";
import useCanvasScale from "../Components/Editor/Hooks/useCanvasScale";
import useCanvasToPdf from "../Components/Editor/Hooks/useCanvasToPdf";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { obtenerConfiguracionDatosSeleccionada } from "../Redux/reducers/datosCertificadoSlice";
import { obtenerFuentesLetra } from "../Redux/reducers/fuentesLetraSlice";
import QRGenerator from "../Components/Editor/MenuSections/QRGenerator";
import WebApi from "../Api/WebApi";

let emptyBackground = [
  [
    {
      category: "shape",
      categoriaId: 2,
      tipoId: 1,
      x: -20,
      y: -20,
      type: "rect",
      width: 1000,
      height: 800,
      fill: "#ffffff",
      strokeWidth: 0,
      componenteId: 0,
      isDraggable: false,
    },
  ],
];

function Edicion() {
  const params = useParams();
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedId, selectShape] = useState([]);
  const [wideMenu, setWideMenu] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [resourcesLoading, setResourcesLoading] = useState(false);
  const [draggedElement, setDraggedElement] = useState();
  const [data, setData] = useState({ img: "", content: null });
  const [projectName, setProjectName] = useState("Certificado");
  const [isEditing, setIsEditing] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const { canvasSize, scale, setScale } = useCanvasScale(680, 420);
  const addPagesToPdf = useCanvasToPdf(
    canvasSize,
    scale,
    projectName,
    setScale
  );
  const { handleRedo, handleUndo, addStepToHistory, history, historyIndex } =
    useUndoRedo(emptyBackground);
  const { onSubmit, successCode, sendingData } = useSubmit(
    isEditing,
    almacenarPlantilla,
    actualizarPlantilla
  );
  const { plantilla, plantillaInfo } = useSelector((state) => state.plantillas);
  const { configuracionSeleccionada } = useSelector(
    (state) => state.datosCertificados
  );
  const { fuentesLetra } = useSelector((state) => state.fuentesLetra);

  const addElementToCanvas = async (obj) => {
    let componenteId = Math.random().toString().slice(10);

    let element = { ...obj };

    if (element["data"] && element.type !== "image") delete element["data"];
    if (element["Data"]) {
      element["data"] = element["Data"];
      delete element["Data"];
    }
    if (element?.archivoNombre === "ComponenteQR.png") {
      const response = await WebApi.api().post("Files", {
        FileName: element.ArchivoNombre,
        ContentType: "image/png",
        DataString: element.data,
      });
      console.log("s")
      element = { ...element, FileId: response.data.Result.FileId };
    }
    console.log(element)

    let newStep = history[historyIndex].concat([
      {
        ...element,
        componenteId,
      },
    ]);
    addStepToHistory(newStep);
  };
  const fetchFuentesImagenes = async (urls) => {
    try {
      const responses = await Promise.all(
        urls.map(async (url) => {
          const response = await WebApi.api().get(url);
          return response.data;
        })
      );
      const updatedData = fuentesLetra?.map((item) => {
        const response = responses.find(
          (res) => res.Result.FileId === item.FileId
        );
        return {
          ...item,
          ...response?.Result,
        };
      });
      setDataSource(updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const saveFontFace = async (id, name, data) => {
    const fontFace = new FontFace(name, `url(${data})`);
    document.fonts.add(fontFace);
    try {
      // Carga la fuente
      await fontFace.load();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (fuentesLetra?.length > 0) {
      const urls = fuentesLetra?.map(
        (fuente) => `${process.env.REACT_APP_BASEURL}Files/${fuente.FileId}`
      );
      fetchFuentesImagenes(urls);
    }
  }, [fuentesLetra]);

  useEffect(() => {
    if (dataSource?.length > 0) {
      dataSource?.forEach((e) => {
        saveFontFace(
          e.FileId,
          e.FileName.split(".")[0],
          `data:application/octet-stream;base64,${e.Data}`
        );
      });
    }
  }, [dataSource]);

  useEffect(() => {
    dispatch(obtenerFuentesLetra());
    dispatch(obtenerConfiguracionDatosSeleccionada());
    if (window.matchMedia("(max-width: 600px)").matches) {
      setScale(55);
    } else {
      setScale(100);
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      setTemplateId(params.id);
      setIsEditing(true);
      dispatch(obtenerPlantillaPorId({ PlantillaCertificadoId: params.id }));
      dispatch(obtenerPlantillaInfoPorId(params.id));
    }
  }, [params.id, dispatch]);

  useEffect(() => {
    if (plantilla?.Contenido !== undefined && templateId !== null) {
      addStepToHistory(plantilla?.Contenido);
      setProjectName(plantilla?.PlantillaNombre);
    }
  }, [plantilla]);

  const paletteMenu = (submenuActive) => (
    <PaletteMenu
      isSubmenuActive={true}
      content={
        submenuActive === 1 ? (
          <TemplatesToolbar
            setTemplate={(t) => addStepToHistory(t)}
            setTemplateId={(id) => setTemplateId(id)}
            quitPageLoading={() => setPageLoading(false)}
          />
        ) : submenuActive === 5 ? (
          <QRGenerator addElement={(obj) => addElementToCanvas(obj)} />
        ) : (
          <ShapesToolbar
            addElement={(obj) => addElementToCanvas(obj)}
            setIsLoadingResources={(b) => setResourcesLoading(b)}
            elementCategory={
              submenuActive === 2 ? 3 : submenuActive === 3 ? 1 : 2
            }
            setDraggedElement={(e) => setDraggedElement(e)}
          />
        )
      }
    />
  );

  const handleSubmit = async (data) => {
    const response = await WebApi.api().post("/Files", data.File);
    onSubmit(
      {
        Contenido: data.Contenido,
        PlantillaCertificado: data.PlantillaCertificado,
        FileId: response.data.Result.FileId,
      },
      false
    );
  };
  const handleSubmitPut = async (data) => {
    const response = await WebApi.api().post("/Files", data.File);
    onSubmit(
      {
        id: data.id,
        obj: { ...data.obj, FileId: response.data.Result.FileId },
      },
      true
    );
  };

  return (
    <>
      <AnimatePresence>
        {(pageLoading ||
          (params.id &&
            (plantillaInfo == null || plantilla?.Contenido == undefined))) && (
          <motion.div
            className="logo-frame"
            id="frameLogo"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <img src={Loading} alt="Cargando" />
          </motion.div>
        )}
      </AnimatePresence>
      <div id="principal-container" className="root-editor">
        <Palette
          isOpened={wideMenu}
          disabled={resourcesLoading}
          paletteMenu={(submenuActive) => paletteMenu(submenuActive)}
        />

        <div id="sub-principal-container">
          <Header
            toggleMenu={() => setWideMenu(!wideMenu)}
            isHeaderOnEditor={true}
            urlToBack="/plantillas"
          />
          <div style={{ overflowX: "scroll", overflowY: "hidden" }}>
            <StylePalette
              fuentesLetra={fuentesLetra}
              camposConfiguracion={configuracionSeleccionada}
              name={projectName}
              setProjectName={(nombre) => setProjectName(nombre)}
              totalPages={0}
              page={0}
              elements={data?.content}
              selectedIDs={selectedId}
              setData={(d) => addStepToHistory(d)}
              undo={handleUndo}
              redo={handleRedo}
              download={() => addPagesToPdf()}
            />

            <CertificadoCanvas
              id={"canvas"}
              isEditable={true}
              selectShape={selectShape}
              selectedId={selectedId}
              setData={(d) => setData(d)}
              data={data}
              undo={handleUndo}
              draggedElement={draggedElement}
              canvasSize={canvasSize}
              scale={scale}
              history={history}
              historyIndex={historyIndex}
              addStepToHistory={addStepToHistory}
            />
          </div>
        </div>
        <div className="edition-bottom-bar">
          <PaletteMovil
            disabled={false}
            paletteMenu={(submenuActive) => paletteMenu(submenuActive)}
          />
          <div className="is-hidden-on-mobile">
            <FormInput
              id="rangeScale"
              type="range"
              value={scale}
              max="150"
              unit="%"
              onChange={(val) => setScale(val)}
            />
          </div>
          {templateId && !plantillaInfo?.EsPredefinida && (
            <button
              disabled={sendingData}
              className="btn btn-primary editor-btn-save"
              onClick={() => {
                setIsEditing(true);
                handleSubmitPut({
                  id: templateId,
                  obj: {
                    PlantillaCertificado: {
                      PlantillaNombre: projectName,
                      EmpresaId: null,
                      EsPredefinida: false,
                    },
                    Contenido: data.content,
                  },
                  File: {
                    FileName: `${projectName}.png`,
                    ContentType: "image/png",
                    DataString: data.img,
                  },
                });
              }}
            >
              Guardar cambios
            </button>
          )}

          <button
            className="btn btn-secondary"
            disabled={sendingData}
            onClick={() => {
              handleSubmit({
                PlantillaCertificado: {
                  PlantillaNombre: projectName,
                  EmpresaId: null,
                  EsPredefinida: false,
                },
                Contenido: data.content,
                File: {
                  FileName: `${projectName}.png`,
                  ContentType: "image/png",
                  DataString: data.img,
                },
              });
            }}
          >
            <span className="is-hidden-on-mobile">Guardar nueva</span>
            <i className="material-icons is-visible-on-mobile"></i>
          </button>

          <div>
            <img
              src={spinner}
              alt="animación enviando datos"
              hidden={!sendingData}
            />
          </div>
          <button className="btn btn-primary" onClick={() => addPagesToPdf()}>
            <i className="Material-Icons-Outlined"></i>
          </button>
        </div>
        <FormToast
          code={successCode !== 0 && successCode}
          title={successCode === 1 ? "¡Bien!" : "Algo anda mal"}
          desc={
            successCode === 1
              ? "Datos almacenados correctamente"
              : "Revisa los datos e intenta de nuevo"
          }
        />
      </div>
    </>
  );
}

export default Edicion;
