import React, { useEffect, useState } from "react";
import { FormInput } from "../Form/FormElements";

const fontSizes = [
  { name: 6, value: 6 },
  { name: 8, value: 8 },
  { name: 10, value: 10 },
  { name: 12, value: 12 },
  { name: 14, value: 14 },
  { name: 16, value: 16 },
  { name: 18, value: 18 },
  { name: 20, value: 20 },
  { name: 22, value: 22 },
  { name: 24, value: 24 },
  { name: 26, value: 26 },
  { name: 28, value: 28 },
  { name: 30, value: 30 },
  { name: 32, value: 32 },
  { name: 34, value: 34 },
  { name: 36, value: 36 },
  { name: 38, value: 38 },
  { name: 40, value: 40 },
  { name: 42, value: 42 },
  { name: 44, value: 44 },
  { name: 46, value: 46 },
  { name: 48, value: 48 },
  { name: 50, value: 50 },
  { name: 52, value: 52 },
];

const fontFamilies = [
  { ArchivoId: 1111, ArchivoNombre: "Helvetica" },
  { ArchivoId: 2222, ArchivoNombre: "Arial" },
  { ArchivoId: 3333, ArchivoNombre: "Brush Script MT" },
  { ArchivoId: 4444, ArchivoNombre: "Courier New" },
  { ArchivoId: 5555, ArchivoNombre: "Garamond" },
  { ArchivoId: 6666, ArchivoNombre: "Impact" },
  { ArchivoId: 7777, ArchivoNombre: "Palatino" },
  { ArchivoId: 8888, ArchivoNombre: "Verdana" },
  { ArchivoId: 9999, ArchivoNombre: "Tahoma" },
  { ArchivoId: 1000, ArchivoNombre: "Trebuchet MS" },
  { ArchivoId: 1222, ArchivoNombre: "Impact" },
  { ArchivoId: 1333, ArchivoNombre: "Gill Sans" },
  { ArchivoId: 1444, ArchivoNombre: "Times New Roman" },
  { ArchivoId: 1555, ArchivoNombre: "Georgia" },
  { ArchivoId: 1666, ArchivoNombre: "Lucida" },
  { ArchivoId: 1777, ArchivoNombre: "Monaco" },
  { ArchivoId: 1888, ArchivoNombre: "Luminari" },
  { ArchivoId: 1999, ArchivoNombre: "Comic Sans MS" },
  { ArchivoId: 2000, ArchivoNombre: "Pacifico" },
];

const textJustifications = [
  { id: 1, icon: <i className="material-icons"></i>, justification: "left" },
  { id: 2, icon: <i className="material-icons"></i>, justification: "center" },
  { id: 3, icon: <i className="material-icons"></i>, justification: "right" },
  {
    id: 4,
    icon: <i className="material-icons"></i>,
    justification: "justify",
  },
];

function StylePalette({
  elements,
  selectedIDs,
  setData,
  undo,
  redo,
  name = "Certificado",
  setProjectName,
  camposConfiguracion,
  fuentesLetra,
}) {
  const [allElementsArray, setAllElementsArray] = useState();
  const [selectedElements, setSelectedElements] = useState([]);
  const [modifiedArray, setModifiedArray] = useState([]);

  useEffect(() => {
    if (elements) setAllElementsArray(elements?.slice());
  }, [elements]);

  useEffect(() => {
    fontFamilies?.concat(fuentesLetra);
  }, [fuentesLetra]);

  useEffect(() => {
    if (allElementsArray && selectedIDs)
      setSelectedElements(
        allElementsArray.filter((e) => selectedIDs.includes(e.componenteId))
      );
  }, [selectedIDs, allElementsArray]);

  useEffect(() => {
    if (modifiedArray?.length > 0) {
      let dataArray = allElementsArray;
      selectedElements.forEach((el) => {
        let position = dataArray.indexOf(
          dataArray.find((i) => i.componenteId == el.componenteId)
        );
        if (position !== -1 && selectedIDs?.length > 0) {
          dataArray[position] = {
            ...modifiedArray.find(
              (obj) => obj.componenteId == dataArray[position].componenteId
            ),
          };
        }
      });
      setData(JSON.parse(JSON.stringify(dataArray)));
    }
  }, [modifiedArray]);

  const changeProperties = (attr) => {
    let array = selectedElements;
    selectedElements.forEach((item, i) => {
      array[i] = { ...array[i], ...attr };
    });
    setModifiedArray(array);
  };

  const validateProperty = (property, value = null) => {
    let booleano = true;
    if (value == null) {
      selectedElements.forEach((item) => {
        if (!item[property]) booleano = false;
      });
    } else {
      selectedElements.forEach((item) => {
        if (item[property] != value) booleano = false;
      });
    }
    return booleano;
  };

  return (
    <div className="flex-container edition-top-bar">
      <div className=" flex-container tools-container">
        <input
          type="text"
          onChange={(e) => setProjectName(e.target.value)}
          value={name}
          className="input-project-name"
        />
        {selectedElements.length > 0 && (
          <>
            {validateProperty("stroke") && (
              <>
                <FormInput
                  label="Linea"
                  type="color-picker"
                  onChange={(e) => changeProperties({ stroke: e.target.value })}
                  value={selectedElements[0].stroke}
                />
                <div style={{ position: "relative", right: "25px" }}>
                  <FormInput
                    type="number"
                    value={selectedElements[0].strokeWidth}
                    unit="px"
                    onChange={(val) => changeProperties({ strokeWidth: +val })}
                  />
                </div>
              </>
            )}

            {validateProperty("fill") && (
              <>
                <FormInput
                  label="Color"
                  type="color-picker"
                  onChange={(e) => changeProperties({ fill: e.target.value })}
                  value={selectedElements[0].fill}
                />
              </>
            )}
            {validateProperty("type", "text") && (
              <div className="editor-text-properties-container flex-container">
                <div className="edition-fontsize-container">
                  <FormInput
                    type="writable-dropdown"
                    options={fontSizes}
                    value={
                      selectedElements[0].fontSize &&
                      selectedElements[0].fontSize
                    }
                    onChange={(val) => changeProperties({ fontSize: +val })}
                  />
                </div>

                <FormInput
                  type="dropdown"
                  placeholder="Fuente de letra"
                  value={
                    selectedElements.length == 1
                      ? selectedElements[0].fontFamily
                      : null
                  }
                  onChange={(val) =>
                    changeProperties({
                      fontFamily: val.target.value,
                    })
                  }
                  options={fontFamilies}
                  optionIdName="ArchivoNombre"
                  optionValueName="ArchivoNombre"
                />
                <FormInput
                  type="dropdown"
                  value={
                    selectedElements.length == 1
                      ? selectedElements[0].reference
                      : null
                  }
                  placeholder="Referencia"
                  onChange={(val) =>
                    changeProperties({
                      reference: val.target.value,
                    })
                  }
                  options={camposConfiguracion}
                  optionIdName="Clave"
                  optionValueName="Clave"
                />
                <button
                  className="btn-bar-edition"
                  onClick={() => {
                    changeProperties({
                      align:
                        selectedElements[0].align == "left"
                          ? "center"
                          : selectedElements[0].align == "center"
                          ? "right"
                          : selectedElements[0].align == "right"
                          ? "justify"
                          : "left",
                    });
                  }}
                >
                  {selectedElements.length == 1
                    ? textJustifications.find(
                        (j) => j.justification === selectedElements[0].align
                      )?.icon
                    : "?"}
                </button>
              </div>
            )}
          </>
        )}

        <div className="flex-container editor-basic-functions-container">
          <button onClick={() => undo()} className="btn btn-secondary">
            <i className="material-icons"></i>
          </button>
          <button onClick={() => redo()} className="btn btn-secondary">
            <i className="material-icons"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default StylePalette;
