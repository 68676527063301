import { useForm, Controller } from "react-hook-form";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../Components/Other/Modal";
import {
  actualizarConfiguraciones,
  obtenerConfiguraciones,
  configuracionesGeneralesDefault,
} from "../../Redux/reducers/configuracionesSlice";

import { useNavigate } from "react-router-dom";
import FormToast from "../../Components/Form/FormToast";
import { Field } from "./SettingsField";

function ConfiguracionesGenerales(props) {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confGeneral, setConfGeneral] = useState({});
  const [defValues, setDefVaules] = useState({});
  const [successCode, setSuccessCode] = useState(0);
  const [toastMessage, setToastMessage] = useState(null);

  const [defaultValuesActive, setDefaultValuesActive] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const { configuraciones } = useSelector((state) => state.configuraciones);

  useEffect(() => {
    cargarDatos();
  }, [setDefaultValuesActive]);

  useEffect(() => {
    let indexHeader, indexMenu, indexNavigation;
    if (showModal || modalVisibility) {
      indexHeader = indexMenu = indexNavigation = 0;
    } else {
      indexHeader = 10;
      indexMenu = 7;
      indexNavigation = 3;
    }
    document
      .getElementById("header")
      .setAttribute("style", `z-index: ${indexHeader}`);
    document
      .querySelector(".vertical-menu")
      .setAttribute("style", `z-index: ${indexMenu}`);
    document
      .getElementById("navigation-tab")
      .setAttribute("style", `z-index: ${indexNavigation}`);
  }, [showModal, modalVisibility]);

  const cargarDatos = () => {
    dispatch(obtenerConfiguraciones());
  };

  const toggleModalVisibility = (value, e) => {
    e?.preventDefault();
    setModalVisibility(value);
  };

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      formData.append(key, object[key]);
    });
    return formData;
  }

  const onSubmit = async (data) => {
    let formD = getFormData(data);

    let { payload } = await dispatch(actualizarConfiguraciones(formD));
    let result = payload;
    if (result.success) {
      setSuccessCode(1);
      cargarDatos();
      setTimeout(() => setSuccessCode(0), 4000);
    } else {
      setSuccessCode(2);
      setTimeout(() => setSuccessCode(0), 4000);
    }
  };

  const reestablecerConfig = (submit) => {
    reset(defValues);
    setDefaultValuesActive(true);
    toggleModalVisibility(false);
    submit();
  };
  const restablecerValor = async (e, submit) => {
    e.preventDefault();
    let { payload } = await dispatch(
      configuracionesGeneralesDefault(confGeneral.clave)
    );
    let result = payload;
    if (result.success) {
      setSuccessCode(1);
      setToastMessage("Valor restablecido");
      setTimeout(() => setSuccessCode(0), 4000);
      cargarDatos();
      setShowModal(false);
      let formValues = getValues();

      formValues[confGeneral.clave] = confGeneral.defVal;
      reset(formValues);
    } else {
      setSuccessCode(2);
      setTimeout(() => setSuccessCode(0), 4000);
    }
  };
  const parseElement = (el) => {
    let isValid = true;
    let x = {};
    try {
      x = JSON.parse(el);
    } catch (e) {
      isValid = false;
    }
    return isValid ? x : "";
  };

  console.log(configuraciones, "configuraciones");
  const formInputs =
    configuraciones != undefined &&
    Object.keys(configuraciones)?.map((e) => {
      return (
        <div key={e}>
          <details className="detail-settings">
            <summary className="flex-container">
              <div>
                <h3>
                  <i className="material-icons"></i>
                  {configuraciones[e]?.Seccion}
                </h3>
                <hr />
              </div>
            </summary>
            {Object.keys(configuraciones[e]?.Configuraciones)?.map((c) => {
              const { Rules, ValorPorDefecto, Nombre, Valor, Label } =
                configuraciones[e].Configuraciones[c];
              return (
                <div key={configuraciones[e]?.Configuraciones[c].Id}>
                  <Controller
                    name={Nombre}
                    control={control}
                    rules={parseElement(Rules)}
                    defaultValue={Valor}
                    render={({ field: { value, onChange, ref } }) => (
                      <div>
                        <Field
                          refe={ref}
                          handleDefault={(e) => {
                            e.preventDefault();
                            setConfGeneral({
                              nombre: Label,
                              clave: Nombre,
                              defVal: ValorPorDefecto,
                            });
                            setShowModal(true);
                          }}
                          nombre={Nombre}
                          errors={errors[Nombre]?.message}
                          value={value}
                          setValue={setValue}
                          onChange={onChange}
                          control={control}
                          {...configuraciones[e]?.Configuraciones[c]}
                        />
                      </div>
                    )}
                  />
                </div>
              );
            })}
          </details>
        </div>
      );
    });
  console.log(formInputs);
  return (
    <motion.main
      id="main"
      className="register"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <FormToast
        code={successCode}
        title={successCode == 2 ? "¡Algo anda mal!" : "¡Bien!"}
        desc={
          toastMessage !== null
            ? toastMessage
            : successCode == 2
            ? "Revisa los datos e intenta nuevamente"
            : "Configuración actualizada"
        }
      />

      <form className="form-settings">
        <div className="main-admin-bar">
          <div className="header-admin-bar-items">
            <h2 className="form-title">Configuraciones Generales</h2>
          </div>
          <button
            className="btn btn-alternative"
            onClick={(e) => toggleModalVisibility(true, e)}
          >
            Restablecer configuraciones
          </button>
        </div>
        {formInputs}

        <br />
        <br />
        <div className="flex-container">
          <button className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
            Guardar
          </button>
        </div>

        <Modal
          isVisible={modalVisibility}
          title="Restablecer configuraciones generales"
          bottomItems={
            <div className="flex-container">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  reestablecerConfig(handleSubmit(onSubmit));
                }}
              >
                Confirmar
              </button>
              <button
                className="btn alternative-btn"
                onClick={(e) => toggleModalVisibility(false, e)}
              >
                Cancelar
              </button>
            </div>
          }
        >
          <h4>¿Seguro que desea restablecer todas las configuraciones?</h4>
        </Modal>

        <Modal
          isVisible={showModal}
          title="Restablecer configuraciones generales"
          bottomItems={
            <div className="flex-container">
              <button
                className="btn btn-primary"
                onClick={(e) => restablecerValor(e, handleSubmit(onSubmit))}
              >
                Confirmar
              </button>
              <button
                className="btn alternative-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(false);
                }}
              >
                Cancelar
              </button>
            </div>
          }
        >
          <h4>{`¿Seguro que desea restablecer el valor del campo ${confGeneral.nombre} ?`}</h4>
        </Modal>
      </form>
    </motion.main>
  );
}

export default ConfiguracionesGenerales;
