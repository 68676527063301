import { useRef, useState } from "react";
import { useEffect } from "react";
import { Rect, Circle, Transformer, Image, Line, Star } from "react-konva";
import { useImage } from "react-konva-utils";
import { EditableTextInput, ResizableText } from "./TextTypes";
import useGenerateQr from "./Hooks/useGenerateQr";

const Rectangle = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  onContextMenu,
  x,
  y,
}) => {
  const shapeRef = useRef();

  const handleResize = (e) => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    onChange({
      ...shapeProps,
      x: node.x(),
      y: node.y(),
      // set minimal value
      width: Math.max(5, node.width() * scaleX),
      height: Math.max(node.height() * scaleY),
      rotation: node.attrs.rotation,
    });
  };

  return (
    <>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        x={x}
        y={y}
        ref={shapeRef}
        draggable={shapeProps.isDraggable}
        {...shapeProps}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          handleResize(e);
        }}
        onContextMenu={onContextMenu}
      />
    </>
  );
};

const Circ = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  onContextMenu,
  x,
  y,
}) => {
  const shapeRef = useRef();

  const handleResize = (e) => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    //reset it back
    node.scaleX(1);
    node.scaleY(1);
    onChange({
      ...shapeProps,
      x: node.x(),
      y: node.y(),
      width: Math.max(5, node.width() * scaleX),
      height: Math.max(node.height() * scaleY),
      rotation: node.attrs.rotation,
    });
  };

  return (
    <>
      <Circle
        onClick={onSelect}
        onTap={onSelect}
        x={x}
        y={y}
        ref={shapeRef}
        draggable
        {...shapeProps}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          handleResize(e);
        }}
        onContextMenu={onContextMenu}
      />
    </>
  );
};

const RETURN_KEY = 13;
const ESCAPE_KEY = 27;

function EditableText({
  x,
  y,
  isSelected,
  onSelect,
  textTitle,
  onChange,
  textProps,
  onContextMenu,
}) {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isSelected && isEditing) {
      setIsEditing(false);
    }
  }, [isSelected, isEditing]);

  function toggleEdit() {
    setIsEditing(!isEditing);
  }

  function handleEscapeKeys(e) {
    if ((e.keyCode === RETURN_KEY && !e.shiftKey) || e.keyCode === ESCAPE_KEY) {
      toggleEdit(e);
    }
  }

  if (isEditing) {
    return (
      <EditableTextInput
        x={x}
        y={y}
        value={textTitle}
        onChange={(obj) => onChange(obj)}
        onKeyDown={handleEscapeKeys}
        textProps={textProps}
        isEditing={isEditing}
      />
    );
  }
  return (
    <ResizableText
      textProps={textProps}
      x={x}
      y={y}
      fontSize={textProps.fontSize}
      isSelected={isSelected}
      onSelect={onSelect}
      onChange={(obj) => onChange(obj)}
      onDoubleClick={toggleEdit}
      text={textTitle}
      onContextMenu={onContextMenu}
    />
  );
}

function LineShape({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  onContextMenu,
  x,
  y,
}) {
  const shapeRef = useRef();
  const trRef = useRef();

  const handleResize = (e) => {
    const node = shapeRef.current;
    const oldPoints = node.points();
    const newPoints = [];
    for (var i = 0; i < oldPoints.length / 2; i++) {
      const point = {
        x: oldPoints[i * 2] * node.scaleX(),
        y: oldPoints[i * 2 + 1] * node.scaleY(),
      };
      newPoints.push(point.x, point.y);
    }
    node.scaleX(1);
    node.scaleY(1);
    trRef.current.isVisible(false);
    onChange({
      ...shapeProps,
      points: newPoints,
      rotation: node.attrs.rotation,
    });
    trRef.current.isVisible(true);
  };

  return (
    <>
      <Line
        onClick={onSelect}
        onTap={onSelect}
        x={x}
        y={y}
        strokeScaleEnabled={false}
        ref={shapeRef}
        draggable
        {...shapeProps}
        onDragEnd={(e) => {
          trRef.current.isVisible(false);
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
          trRef.current.isVisible(true);
        }}
        onTransformEnd={(e) => {
          handleResize(e);
        }}
        onContextMenu={onContextMenu}
      />

      {isSelected && <Transformer useSingleNodeRotation={false} ref={trRef} />}
    </>
  );
}

const StarShape = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  onContextMenu,
  x,
  y,
}) => {
  const shapeRef = useRef();

  const handleResize = (e) => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);

    onChange({
      ...shapeProps,
      x: node.x(),
      y: node.y(),
      innerRadius: Math.max(5, node.innerRadius() * scaleX),
      outerRadius: Math.max(5, node.outerRadius() * scaleX),
      width: Math.max(5, node.width() * scaleX),
      height: Math.max(node.height() * scaleY),
      rotation: node.attrs.rotation,
    });
  };

  return (
    <>
      <Star
        onClick={onSelect}
        onTap={onSelect}
        x={x}
        y={y}
        ref={shapeRef}
        draggable
        {...shapeProps}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          handleResize(e);
        }}
        onContextMenu={onContextMenu}
      />
    </>
  );
};

function Imagen({
  imageProps,
  onChange,
  onContextMenu,
  onSelect,
  UUID = null,
}) {
  const [image] = useImage(imageProps.data);
  const imageRef = useRef();
  imageRef.crossOrigin = "Anonymous";

  const colorbg = imageProps.fill ? imageProps.fill : null;
  const colorqr = imageProps.stroke ? imageProps.stroke : null;
  const location = window.location.hostname;

  const { generateQR } = useGenerateQr(
    imageProps,
    onChange,
    location + "/" + UUID,
    colorbg,
    colorqr
  );

  useEffect(() => {
    if (UUID !== null && imageProps.archivoNombre == "ComponenteQR.png") {
      generateQR();
    }
  }, [UUID]);

  const handleResize = (e) => {
    const node = imageRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    let newWidth = Math.max(5, node.width() * scaleX);
    let newHeight = Math.max(node.height() * scaleY);
    node.scaleX(1);
    node.scaleY(1);

    onChange({
      ...imageProps,
      x: node.x(),
      y: node.y(),
      width: newWidth,
      height: newHeight,
      rotation: node.attrs.rotation,
    });
    imageRef.current.width(newWidth);
    imageRef.current.height(newHeight);
  };

  return (
    <>
      <Image
        image={image}
        x={imageProps.x}
        y={imageProps.y}
        onClick={onSelect}
        onTap={onSelect}
        ref={imageRef}
        draggable
        componenteId={imageProps.componenteId}
        onDragEnd={(e) => {
          onChange({
            ...imageProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          handleResize(e);
        }}
        onContextMenu={onContextMenu}
        width={imageProps.width !== 0 ? imageProps.width : null}
        height={imageProps.height !== 0 ? imageProps.height : null}
        scaleX={imageProps.width == 0 ? imageProps.scaleX : 1}
        scaleY={imageProps.height == 0 ? imageProps.scaleY : 1}
      />
    </>
  );
}

export default function Element({
  UUID,
  type,
  text,
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  onContextMenu,
}) {
  switch (type) {
    case "rect":
      return (
        <Rectangle
          x={shapeProps.x}
          y={shapeProps.y}
          shapeProps={shapeProps}
          isSelected={isSelected}
          onSelect={onSelect}
          onChange={(attrs) => onChange(attrs)}
          onContextMenu={onContextMenu}
        />
      );
    case "circle":
      return (
        <Circ
          x={shapeProps.x}
          y={shapeProps.y}
          shapeProps={shapeProps}
          isSelected={isSelected}
          onSelect={onSelect}
          onChange={onChange}
          onContextMenu={onContextMenu}
        />
      );
    case "text":
      return (
        <EditableText
          x={shapeProps.x}
          y={shapeProps.y}
          text={text}
          textProps={shapeProps}
          isSelected={isSelected}
          onSelect={onSelect}
          textTitle={text}
          onChange={onChange}
          onContextMenu={onContextMenu}
        />
      );
    case "image":
      return (
        <Imagen
          UUID={UUID}
          x={shapeProps.x}
          y={shapeProps.y}
          imageProps={shapeProps}
          isSelected={isSelected}
          onSelect={onSelect}
          onChange={onChange}
          onContextMenu={onContextMenu}
        />
      );
    case "line":
      return (
        <LineShape
          x={shapeProps.x}
          y={shapeProps.y}
          shapeProps={shapeProps}
          isSelected={isSelected}
          onSelect={onSelect}
          onChange={(attrs) => onChange(attrs)}
          onContextMenu={onContextMenu}
        />
      );

    case "star":
      return (
        <StarShape
          x={shapeProps.x}
          y={shapeProps.y}
          shapeProps={shapeProps}
          isSelected={isSelected}
          onSelect={onSelect}
          onChange={(attrs) => onChange(attrs)}
          onContextMenu={onContextMenu}
        />
      );
    default:
      return null;
  }
}
