import React, { useState } from "react";
import Loading from "../IMG/Loading Robot.gif";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import CertificadoCanvas from "../Components/Editor/CertificadoCanvas";
import spinner from "../IMG/SpinnerSending.gif";
import { v4 } from "uuid";
import { obtenerPlantillaPorId } from "../Redux/reducers/plantillasSlice";
import { almacenarProyecto } from "../Redux/reducers/proyectosSlice";
import FormToast from "../Components/Form/FormToast";
import useUndoRedo from "../Hooks/useUndoRedo";
import useSubmit from "../Hooks/useSubmit";
import useCanvasScale from "../Components/Editor/Hooks/useCanvasScale";
import useCanvasToPdf from "../Components/Editor/Hooks/useCanvasToPdf";
import useCanvasPagination from "../Components/Editor/Hooks/useCanvasPagination";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { obtenerConfiguracionDatosSeleccionada } from "../Redux/reducers/datosCertificadoSlice";
import WebApi from "../Api/WebApi";
import { almacenarDesarrolloCertificado } from "../Redux/reducers/desarrolloSlice";
import axios from "axios";

function PreviewCertificates() {
  const [selectedId, selectShape] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [data, setData] = useState({ img: "", content: null });
  const [fieldsData, setFieldsData] = useState(["0"]);
  const [projectName, setProjectName] = useState("Certificado");
  const [templateId, setTemplateId] = useState(null);
  const [fullfilledFields, setFullfilledFields] = useState([]);
  const [imgDatas, setImgDatas] = useState([]);
  const [mappingLoading, setMappingLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { canvasSize, scale, setScale } = useCanvasScale(680, 420);
  const addPagesToPdf = useCanvasToPdf(canvasSize, 100, projectName, setScale);
  const [loading, setLoading] = useState(false);

  const [totalPages, pageIntersected, scrollFunction] = useCanvasPagination(
    "#projectScrollArea",
    "0px",
    0.5
  );
  const { handleUndo, addStepToHistory, history, historyIndex } = useUndoRedo([
    [{}],
  ]);
  const { onSubmit, successCode, sendingData } = useSubmit(
    false,
    almacenarProyecto,
    null
  );
  const {
    onSubmit: onSubmitExternal,
    successCode: successCodeExternal,
    sendingData: sendingDataExternal,
  } = useSubmit(false, almacenarDesarrolloCertificado, null);
  const { configuracionSeleccionada } = useSelector(
    (state) => state.datosCertificados
  );
  const [resultadosApi, setResultadosApi] = useState([]);
  const { plantilla } = useSelector((state) => state.plantillas);
  const sesionId = new URLSearchParams(location.search).get("SesionId");

  useEffect(() => {
    if (resultadosApi?.length > 0) {
      setFieldsData(resultadosApi.map((r) => ({ ...r, guid: v4() })));
    }
  }, [resultadosApi]);

  const fetchData = async () => {
    const response = await WebApi.api().get(
      "DesarrolloDocenteDumpData?filters[param][$contains]=" + params.id
    );
    setResultadosApi(response.data.Result);
  };
  useEffect(() => {
    if (params.id != null) {
      fetchData();
    }
  }, [params.id]);

  useEffect(() => {
    dispatch(obtenerConfiguracionDatosSeleccionada());
    setScale(55);
  }, []);
  useEffect(() => {
    if (successCode === 1 && successCodeExternal === 1) {
      window.location.href = process.env.REACT_APP_DESARROLLO_DOCENTE_URL
        ? `${process.env.REACT_APP_DESARROLLO_DOCENTE_URL}estudiantes-certificados/${sesionId}/certificados`
        : `https://DesarrolloDocente.nube.com.do/estudiantes-certificados/${sesionId}/certificados`;
    }
  }, [successCode, successCodeExternal]);

  useEffect(() => {
    const plantillaId = new URLSearchParams(location.search).get("PlantillaId");
    if (plantillaId) {
      setTemplateId(plantillaId);
      dispatch(obtenerPlantillaPorId({ PlantillaCertificadoId: plantillaId }));
    }
  }, [location.search, dispatch]);

  useEffect(() => {
    if (
      plantilla?.Contenido !== undefined &&
      configuracionSeleccionada?.length > 0
    ) {
      addStepToHistory(plantilla?.Contenido);
      setProjectName(plantilla?.PlantillaNombre);
      setPageLoading(false);
    }
  }, [plantilla, configuracionSeleccionada]);
  useEffect(() => {
    let imgDataArr = [];
    let fieldsDataTemp = fieldsData;

    fieldsDataTemp.forEach((field, i) => {
      imgDataArr.push("");
      if (!field.guid && field !== "0") {
        fieldsDataTemp[i] = { ...field, guid: v4() };
      }
    });
    setImgDatas(imgDataArr);
    setFieldsData(fieldsDataTemp);
  }, [fieldsData.length]);

  const addFullfilled = (fieldName) => {
    let fieldsArray = fullfilledFields;
    fieldsArray.push(fieldName);
    setFullfilledFields(fieldsArray);
  };

  const deletePage = (indexPage) => {
    let tempArr = fieldsData;
    tempArr.splice(indexPage, 1);
    setFieldsData(tempArr);
  };

  const externalSubmit = async (data) => {
    const length = data.Certificados.length;
    let divider;
    const _data = await Promise.all(
      data.Certificados.map(async (item) => {
        const response = await axios.post(
          process.env.REACT_APP_DESARROLLO_DOCENTE_UR_API + "Archivos",
          {
            ArchivoNombre: item.Archivo.ArchivoNombre,
            ContentType: item.Archivo.ContentType,
            Data: item.Archivo.Data,
          }
        );
        delete item.Archivo;
        return { ...item, FileId: response.data.ArchivoId };
      })
    );
    if (length > 50) {
      setMappingLoading(true);
      if (length > 100) divider = 3;
      else divider = 2;

      let partOfData = {
        Certificados: _data.splice(0, Math.round(length / divider)),
      };
      let { payload } = await dispatch(
        almacenarDesarrolloCertificado(partOfData)
      );
      let result = payload;
      if (result.success) {
        externalSubmit(data);
      }
    } else {
      await onSubmitExternal({ Certificados: _data });
      setLoading(false);
    }
  };

  const customSubmit = async (data) => {
    const length = data.Certificados.length;
    let divider;
    const _data = await Promise.all(
      data.Certificados.map(async (item) => {
        const response = await WebApi.api().post("Files", {
          FileName: item.Archivo.ArchivoNombre,
          ContentType: item.Archivo.ContentType,
          DataString: item.Archivo.Data,
        });
        delete item.Archivo;
        return { ...item, FileId: response.data.Result.FileId };
      })
    );
    if (length > 50) {
      setMappingLoading(true);
      if (length > 100) divider = 3;
      else divider = 2;

      let partOfData = {
        Certificados: _data.splice(0, Math.round(length / divider)),
      };
      let { payload } = await dispatch(almacenarProyecto(partOfData));
      let result = payload;
      if (result.success) {
        customSubmit(data);
      }
    } else {
      await onSubmit({ Certificados: _data });
      setLoading(false);
    }
  };
  return (
    <>
      <AnimatePresence>
        {pageLoading && (
          <motion.div
            className="logo-frame"
            id="frameLogo"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <img src={Loading} alt="Cargando" />
          </motion.div>
        )}
      </AnimatePresence>
      <div id="principal-container" className="root-project">
        <div className="preview">
          <header className="flex-container">
            <h4>Vista previa</h4>
            <h4 className="edition-page-count">
              Página {pageIntersected} de {totalPages}
            </h4>
            <div className="flex-container">
              <button
                disabled={sendingData || loading || sendingDataExternal}
                className="btn btn-primary editor-btn-save"
                onClick={() => {
                  const _data = {
                    Certificados: fieldsData.map((item, i) => ({
                      Certificado: {
                        Nombre: projectName,
                        Descripcion: "Grupo de certificados de docentes",
                        PlantillaCertificadoId: templateId,
                        MetodoGeneracionId: 2,
                        GUID: item.guid,
                        SesionId: sesionId,
                      },
                      CertificadoCandidatosDatos: configuracionSeleccionada.map(
                        (campoConfig) => ({
                          PlantillaDatoId: campoConfig.Id,
                          Valor: item[campoConfig.Clave],
                        })
                      ),
                      Archivo: {
                        Data: imgDatas[i],
                        ArchivoNombre: `${projectName} número ${i + 1}.png`,
                        ContentType: "image/png",
                      },
                    })),
                  };
                  setLoading(true);
                  customSubmit(_data);
                  externalSubmit(_data);
                }}
              >
                Guardar cambios
              </button>
              <button className="btn btn-alternative" onClick={addPagesToPdf}>
                <i className="Material-Icons-Outlined"></i>
              </button>
              <img
                src={spinner}
                alt="animación enviando datos"
                hidden={!loading}
              />
            </div>
          </header>
          <div className="flex-container generator">
            <div className="project-sub-principal-container">
              <div id="projectScrollArea" onScroll={scrollFunction}>
                {fieldsData?.map((register, index) => {
                  return (
                    <div style={{ position: "relative" }}>
                      <CertificadoCanvas
                        key={index}
                        id={index.toString()}
                        isEditable={false}
                        selectShape={selectShape}
                        selectedId={fieldsData.length == 1 ? selectedId : null}
                        setData={(d) => setData(d)}
                        data={data}
                        addFullfilled={(name) => addFullfilled(name)}
                        undo={handleUndo}
                        canvasSize={canvasSize}
                        scale={scale}
                        history={history}
                        historyIndex={historyIndex}
                        addStepToHistory={addStepToHistory}
                        textRegister={register !== "0" && register}
                        guid={register.guid}
                        setImgArray={(img) => {
                          let arr = imgDatas;
                          arr[index] = img;
                          setImgDatas(arr);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <FormToast
          code={
            successCode !== 0 &&
            successCode &&
            successCodeExternal !== 0 &&
            successCodeExternal
          }
          title={
            successCode === 1 && successCodeExternal === 1
              ? "¡Bien!"
              : "Algo anda mal"
          }
          desc={
            successCode === 1 && successCodeExternal === 1
              ? "Datos almacenados correctamente"
              : "Revisa los datos e intenta de nuevo"
          }
        />
      </div>
    </>
  );
}

export default PreviewCertificates;
