import React from "react";
import { FormInput } from "../../Form/FormElements";
import useGenerateQr from "../Hooks/useGenerateQr";

function QRGenerator({ addElement }) {
  const qrProps = {
    x: 100,
    y: 110,
    width: 0,
    height: 0,
    fill: "",
    rotation: 0,
    stroke: "#ffffff",
    strokeWidth: 0,
    isDraggable: true,
    isReusable: true,
    tipoId: 6,
    type: "image",
    categoriaId: 3,
    category: "image",
    scaleX: 0.4,
    scaleY: 0.4,
    archivoNombre: "ComponenteQR.png",
    contentType: "image/png",
  };

  const {
    setQrBgColor,
    qrBgColor,
    setQrColor,
    qrColor,
    generateQR,
  } = useGenerateQr(qrProps,  addElement, "Gracias por utilizar CertySafe");
  
  return (
    <section className="sub-menu-container palette">
      <div className="qr-config-container">
        <h3>Generación de código QR</h3>
        <FormInput
          value={qrBgColor}
          type="color-picker"
          label="Color de fondo"
          onChange={(e) => setQrBgColor(e.target.value)}
        />
        <FormInput
          value={qrColor}
          type="color-picker"
          label="Color principal"
          onChange={(e) => setQrColor(e.target.value)}
        />
        <br />

        <button className="btn btn-primary" onClick={generateQR}>
          Generar plantilla de código
        </button>
        <br />
        <span>
          La dirección url del código debe ser provista cuando se generen los
          certificados reales
        </span>
      </div>
    </section>
  );
}

export default QRGenerator;
