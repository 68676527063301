import React, { useState } from "react";
import { useEffect } from "react";
import useCanvasResources from "../Hooks/useCanvasResources";
import useSubmit from "../../../Hooks/useSubmit";
import { almacenarRecursos } from "../../../Redux/reducers/recursosSlice";
import { FormInput } from "../../Form/FormElements";
import Card from "../../Other/Card";
import WebApi from "../../../Api/WebApi";

export default function ShapesToolbar({
  elementCategory,
  setDraggedElement,
  addElement,
  setIsLoadingResources,
}) {
  const { onSubmit, successCode } = useSubmit(false, almacenarRecursos, null);
  const [imgDefault, setImgDefault] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resources, loadResources, isLoading] = useCanvasResources(
    elementCategory,
    imgDefault
  );

  const uploadImage = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const response = await WebApi.api().post("Files", {
        FileName: file.name,
        ContentType: "image/png",
        DataString: reader.result,
      });
      onSubmit({
        categoriaId: 3,
        tipoId: 6,
        fileId: response.data.Result.FileId,
        x: 6,
        y: 110,
        scaleX: 0.4,
        scaleY: 0.4,
        isDraggable: true,
        isReusable: true,
        rotation: 0,
        width: 0,
        height: 0,
        fill: "#ffffff",
        stroke: "#ffffff",
        strokeWidth: 0,
      });
    };
  };

  const fetchResourcesImages = async (urls) => {
    try {
      const responses = await Promise.all(
        urls.map(async (url) => {
          const response = await WebApi.api().get(url);
          return response.data;
        })
      );
      const updatedData = resources?.map((item) => {
        const response = responses.find(
          (res) => res.Result.FileId === item.FileId
        );
        return {
          ...item,
          ...response?.Result,
        };
      });
      setDataSource(updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (resources?.length > 0) {
      const urls = resources
        ?.filter((e) => e.FileId != null)
        ?.map(
          (resource) =>
            `${process.env.REACT_APP_BASEURL}Files/${resource.FileId}`
        );
      fetchResourcesImages(urls);
    }
    if (elementCategory !== 3) setImgDefault(true);
  }, [elementCategory, resources]);

  useEffect(() => {
    if (successCode === 1) loadResources();
  }, [successCode]);

  useEffect(() => {
    setIsLoadingResources(isLoading);
  }, [isLoading]);

  const formatElement = (shape) => {
    let valuesArray = [];
    JSON.parse(shape.fillLinearGradientColorStops)?.map((st) =>
      Object.values(st)?.map((value) => valuesArray.push(value))
    );

    return {
      ...shape,
      fillLinearGradientStartPoint: { x: -50, y: -50 },
      fillLinearGradientEndPoint: { x: 50, y: 50 },
      fillLinearGradientColorStops:
        shape.fillLinearGradientColorStops &&
        shape.fillLinearGradientColorStops !== null &&
        valuesArray,
      fill: valuesArray.length > 0 ? null : shape.fill,
    };
  };

  return (
    <section className="sub-menu-container palette">
      <div className="sub-menu-top-inputs">
        {/* <input
          type="search"
          className="registers-input-search"
          placeholder="Búsqueda"
        /> */}
        {elementCategory === 3 && (
          <>
            <br />
            <div className="flex-container">
              <button
                className="btn btn-alternative"
                onClick={() => setImgDefault(true)}
                style={{ backgroundColor: imgDefault && "#24567D" }}
              >
                Predefinidas
              </button>
              <button
                className="btn btn-alternative"
                onClick={() => setImgDefault(false)}
                style={{ backgroundColor: !imgDefault && "#24567D" }}
              >
                Subidas
              </button>
            </div>
            <br />
            {!imgDefault && (
              <FormInput
                directChange={true}
                type="file"
                onChange={(e) => uploadImage(e)}
                placeholder="Subir imagen"
              />
            )}
          </>
        )}
      </div>

      {isLoading ? (
        <Card
          type="mosaic"
          cantidad={15}
          isLoading={true}
          classN="editor-card-loading"
        />
      ) : (
        <ul>
          {dataSource?.map((shape, i) => {
            return (
              <li
                key={i}
                className="item-toolbar"
                style={{ width: shape.type !== "text" && "45%" }}
              >
                <button
                  draggable="true"
                  className="btn btn-toolbar"
                  onClick={() => addElement(formatElement(shape))}
                  onDragStart={() => {
                    setDraggedElement(formatElement(shape));
                  }}
                >
                  {shape.type === "text" ? (
                    <h4
                      style={{
                        margin: "auto",
                        fontFamily: `${shape.fontFamily}`,
                        fontSize: "25px",
                        fontStyle: `${shape.fontStyle}`,
                        textDecoration: `${shape.textDecoration}`,
                        color: `${shape.fill}`,
                        textShadow: `${
                          shape.shadowOffsetX
                            ? shape.shadowOffsetX + "px"
                            : "0px"
                        } ${
                          shape.shadowOffsetY
                            ? shape.shadowOffsetY + "px "
                            : "0px"
                        } ${shape.shadowBlur + "px"} ${shape.shadowColor} `,
                        width: "100%",
                        background: `linear-gradient(${
                          JSON.parse(shape.fillLinearGradientColorStops) !==
                          null
                            ? JSON.parse(shape.fillLinearGradientColorStops)[0][
                                "Color"
                              ]
                            : null
                        }, ${
                          JSON.parse(shape.fillLinearGradientColorStops) !==
                          null
                            ? JSON.parse(shape.fillLinearGradientColorStops)[1][
                                "Color"
                              ]
                            : null
                        })`,
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor:
                          JSON.parse(shape.fillLinearGradientColorStops) !==
                            null && "transparent",
                        textFillColor:
                          JSON.parse(shape.fillLinearGradientColorStops) !==
                            null && "transparent",
                      }}
                    >
                      {shape.fontFamily}
                    </h4>
                  ) : shape.Data ? (
                    <img src={shape.Data} alt="imagen en minuatura" />
                  ) : (
                    `elemento categoria ${shape.category} ${shape.type} {${shape.componenteId}}`
                  )}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
}
