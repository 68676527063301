import { Booleano, Clave, Correo, Editor, MultiOpciones, Numerico, Opciones, Radio, TextoCorto, TextoLargo } from "./SettingsInputElements";

export const Field = (props) => {

    switch (props.Tipo) {
      case "Texto Corto":
        return <TextoCorto {...props} />;
  
      case "Texto Largo":
        return <TextoLargo {...props} />;
  
      case "Booleano":
        return <Booleano {...props} />;
  
      case "Opciones":
        return <Opciones {...props} />;
  
      case "MultiOpciones":
        return <MultiOpciones {...props} />;
  
      case "Editor":
        return <Editor {...props} />;
  
      case "Radio":
        return <Radio {...props} />;
  
      case "Numero":
        return <Numerico {...props} />;
  
      case "Email":
        return <Correo {...props} />;
  
      case "Clave":
        return <Clave {...props} />;
  
      default:
        return null;
    }
  };