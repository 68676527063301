import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerResultadosApi = createAsyncThunk(
  "configuracionApi/obtenerResultadosApi",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("MAPPING", data);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerCamposApiProyecto = createAsyncThunk(
  "configuracionApi/obtenerCamposApiProyecto ",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("APICALL", data);
      console.log(res.data, "res");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerMetodosApi = createAsyncThunk(
  "configuracionApi/obtenerMetodosApi",
  async (n, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("APIImportMethods");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerMetodosAuthApi = createAsyncThunk(
  "configuracionApi/obtenerMetodosAuthApi",
  async (n, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("APIImportAuthMethods");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerTiposDatosApi = createAsyncThunk(
  "configuracionApi/obtenerTiposDatosApi",
  async (n, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("APIDataTypes");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerTiposParametrosApi = createAsyncThunk(
  "configuracionApi/obtenerTiposParametrosApi",
  async (n, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("APIImportParametersTypes");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerConfiguracionApiById = createAsyncThunk(
  "configuracionApi/obtenerConfiguracionApi",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("APIImport/" + id);
      let obj = res.data.Result;
      obj.dynamicFieldArray = [].concat(obj.APIImportParametersApiId);
      delete obj.APIImportParametersApiId;
      return obj;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerConfiguracionesApi = createAsyncThunk(
  "configuracionApi/obtenerConfiguracionesApi",
  async (n, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "ApiImport?filters[EstadoId][$eq][0]=1"
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerParametrosApi = createAsyncThunk(
  "configuracionApi/obtenerParametrosApi",
  async (n, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("APIImport/GetNonFixedParameters");
      console.log(res, "e");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const almacenarConfiguracionApi = createAsyncThunk(
  "configuracionApi/almacenarConfiguracionApi",
  async (data, { rejectWithValue }) => {
    try {
      data?.dynamicFieldArray?.forEach((element) => {
        element.EstadoId = 1;
        element.ApiId = 0;
      });
      data.APIImportParametersApiId = data.dynamicFieldArray;
      delete data["dynamicFieldArray"];
      const res = await WebApi.api().post("APIImport", data);
      return {
        success: true,
        message: "Configuración actualizada",
        show: true,
      };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response);
    }
  }
);

export const actualizarConfiguracionApi = createAsyncThunk(
  "configuracionApi/actualizarConfiguracionApi",
  async (data, { rejectWithValue }) => {
    try {
      let id = data.ApiId;
      data.APIImportParametersApiId = data.dynamicFieldArray;
      data?.dynamicFieldArray?.forEach((element) => {
        element.EstadoId = 1;
        element.ApiId = id;
        if (!element?.ParameterId) {
          element.ParameterId = 0;
        }
      });
      delete data.ApiId;
      delete data["dynamicFieldArray"];
      const res = await WebApi.api().put("APIImport/" + id, data);
      return {
        success: true,
        message: "Configuración actualizada",
        show: true,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const eliminarConfiguracionApi = createAsyncThunk(
  "configuracionApi/eliminarrConfiguracionApi",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put("APIImport/" + id, { EstadoId: 2 });
      return {
        success: true,
        message: "Configuración actualizada",
        show: true,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  configuracionApi: {},
  isLoading: true,
  resultadosApi: [],
  configuracionesApi: [],
  metodosApi: [],
  metodosAuthApi: [],
  tiposDatosApi: [],
  tiposParametrosApi: [],
  parametrosApi: [],
  camposApiProyecto: [],
};

export const apiExternalSlice = createSlice({
  name: "configuracionApi",
  initialState,
  extraReducers: {
    [obtenerConfiguracionesApi.fulfilled]: (state, action) => {
      state.configuracionesApi = action.payload;
      state.isLoading = false;
    },
    [obtenerConfiguracionApiById.fulfilled]: (state, action) => {
      state.configuracionApi = action.payload;
    },
    [obtenerMetodosApi.fulfilled]: (state, action) => {
      state.metodosApi = action.payload;
    },
    [obtenerMetodosAuthApi.fulfilled]: (state, action) => {
      state.metodosAuthApi = action.payload;
    },
    [obtenerTiposDatosApi.fulfilled]: (state, action) => {
      state.tiposDatosApi = action.payload;
    },
    [obtenerTiposParametrosApi.fulfilled]: (state, action) => {
      state.tiposParametrosApi = action.payload;
    },
    [obtenerParametrosApi.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerParametrosApi.fulfilled]: (state, action) => {
      state.parametrosApi = action.payload;
      state.isLoading = false;
    },
    [obtenerCamposApiProyecto.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerCamposApiProyecto.fulfilled]: (state, action) => {
      state.camposApiProyecto = action.payload;
      state.isLoading = false;
    },
    [obtenerResultadosApi.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerResultadosApi.fulfilled]: (state, action) => {
      state.resultadosApi = action.payload;
      state.isLoading = false;
    },
  },
});

export default apiExternalSlice.reducer;
