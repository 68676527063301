import React from "react";
import loginImg from "../IMG/LookingAtDiploma.svg";
import { FormInput } from "../Components/Form/FormElements";
import WebApi from "../Api/WebApi";
import imagotipo from '../IMG/Imagotipo.svg';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { autenticarUsuario } from "../Redux/reducers/usuariosSlice";
import { useState } from "react";
import FormToast from "../Components/Form/FormToast";

function Login() {
  const {
    register,
    trigger,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [sendingData, setSendingData] = useState();
  const [successCode, setSuccessCode] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    let result;
    setSendingData(true);
    let { payload } = await dispatch(autenticarUsuario(data));
      result = payload;

    if (result.success) {
      localStorage.setItem("token", result.token);
        localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
        navigate("/");
    } else {
      setSuccessCode(2);
      setSendingData(false);
      setTimeout(() => setSuccessCode(0), 3000);
    }
  };

  const loginForm = {
    usuario: {
      label: "Nombre de usuario",
      type: "text",
      placeholder: "Nombre de usuario",
      defaultValue: "",
      icon:(<i className='material-icons input-symbol'></i>),
      required: true,
      rules: {
        required: true,
      },
    },
    contrasenia: {
      label: "Contraseña",
      type: "password",
      placeholder: "Contraseña",
      defaultValue: "",
      icon:(<i className='material-icons input-symbol'></i>),
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const formInputs = Object.keys(loginForm).map((e) => {
    const { rules, defaultValue } = loginForm[e];
    return (
      <section key={e}>
        <Controller
          name={e}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
              <FormInput
                error={errors[e] && "Este campo es requerido"}
                value={field.value}
                onChange={field.onChange}
                {...loginForm[e]}
              />
          )}
        />
      </section>
    );
  });

  return (
    <div id="principal-container" className="login-container">
      <main id="main" className="container-col-75">
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormToast
                code={successCode}
                title={successCode == 2 && "¡Algo anda mal!"}
                desc={successCode == 2 && "Revisa tus credenciales e intenta de nuevo."}
              />
          <div className="logo-container">
          <img src={imagotipo} alt="Logo empresa" />
          </div>
          <h1 className="grey-text">Inicio de sesión</h1>

          {formInputs}

          <a className="login-forgot-password" href="./login/forgotPassword">
            ¿Has olvidado tu contraseña?
          </a> 
          <input
            type="submit"
            className="btn btn-primary"
            value="Iniciar sesión"
            disabled={sendingData}
          />
        </form>
      </main>
      <div>
        <img src={loginImg} alt="Ilustración estadisticas" />
      </div>
    </div>
  );
}

export default Login;
