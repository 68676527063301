import React, { useState } from "react";
import Loading from "../IMG/Loading Robot.gif";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import CertificadoCanvas from "../Components/Editor/CertificadoCanvas";
import Modal from "../Components/Other/Modal";
import spinner from "../IMG/SpinnerSending.gif";
import { v4 } from "uuid";
import { obtenerPlantillaPorId } from "../Redux/reducers/plantillasSlice";
import { almacenarProyecto } from "../Redux/reducers/proyectosSlice";
import Generator from "../Components/Editor/MenuSections/Generator";
import FormToast from "../Components/Form/FormToast";
import useUndoRedo from "../Hooks/useUndoRedo";
import useSubmit from "../Hooks/useSubmit";
import useCanvasScale from "../Components/Editor/Hooks/useCanvasScale";
import useCanvasToPdf from "../Components/Editor/Hooks/useCanvasToPdf";
import useCanvasPagination from "../Components/Editor/Hooks/useCanvasPagination";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { obtenerConfiguracionDatosSeleccionada } from "../Redux/reducers/datosCertificadoSlice";
import GeneratorOptions from "../Components/Editor/MenuSections/GeneratorOptions";
import {
  obtenerCamposApiProyecto,
  obtenerParametrosApi,
  obtenerResultadosApi,
} from "../Redux/reducers/apiexternaSlice";
import { Controller, useForm } from "react-hook-form";
import { Field } from "./Configuraciones/SettingsField";
import FormButtons from "../Components/Form/FormButtons";
import { obtenerFuentesLetra } from "../Redux/reducers/fuentesLetraSlice";
import WebApi from "../Api/WebApi";

function NewProyect() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedId, selectShape] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [data, setData] = useState({ img: "", content: null });
  const [fieldsData, setFieldsData] = useState(["0"]);
  const [projectName, setProjectName] = useState("Certificado");
  const [projectDescription, setProjectDescription] = useState(
    "Grupo de certificados de docentes"
  );
  const [creationMethod, setCreationMethod] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [fullfilledFields, setFullfilledFields] = useState([]);
  const [stateParametrosApi, setStateParametrosApi] = useState([]);
  const [imgDatas, setImgDatas] = useState([]);
  const [mappingLoading, setMappingLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { canvasSize, scale, setScale } = useCanvasScale(680, 420);
  const addPagesToPdf = useCanvasToPdf(canvasSize, 100, projectName, setScale);

  const [totalPages, pageIntersected, scrollFunction] = useCanvasPagination(
    "#projectScrollArea",
    "0px",
    0.5
  );
  const { handleUndo, addStepToHistory, history, historyIndex } = useUndoRedo([
    [{}],
  ]);
  const { onSubmit, successCode, sendingData } = useSubmit(
    false,
    almacenarProyecto,
    null
  );
  const { configuracionSeleccionada } = useSelector(
    (state) => state.datosCertificados
  );
  const { parametrosApi, camposApiProyecto, resultadosApi, isLoading } =
    useSelector((state) => state.configuracionApi);
  const { plantilla } = useSelector((state) => state.plantillas);
  const { fuentesLetra } = useSelector((state) => state.fuentesLetra);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const watchAllFields = watch(); // when pass nothing as argument, watching everything

  const getApiData = (datos) => {
    dispatch(obtenerResultadosApi(datos));
  };

  useEffect(() => {
    if (
      camposApiProyecto.DatosColumns?.length > 0 &&
      camposApiProyecto.ApiColumns?.length > 0 &&
      fieldsData[0] === "0" &&
      Object.values(watchAllFields).length >=
        camposApiProyecto.DatosColumns?.length &&
      isLoading == false
    ) {
      setMappingLoading(true);
      getApiData(watchAllFields);
    }
  }, [
    watchAllFields,
    camposApiProyecto,
    fieldsData,
    isLoading,
    setMappingLoading,
  ]);

  const sendParametersValue = (datos) => {
    console.log(datos, "datos");
    dispatch(obtenerCamposApiProyecto(datos));
    setStateParametrosApi([]);
  };
  const fetchFuentesImagenes = async (urls) => {
    try {
      const responses = await Promise.all(
        urls.map(async (url) => {
          const response = await WebApi.api().get(url);
          return response.data;
        })
      );
      const updatedData = fuentesLetra?.map((item) => {
        const response = responses.find(
          (res) => res.Result.FileId === item.FileId
        );
        return {
          ...item,
          ...response?.Result,
        };
      });
      setDataSource(updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (fuentesLetra?.length > 0) {
      const urls = fuentesLetra?.map(
        (fuente) => `${process.env.REACT_APP_BASEURL}Files/${fuente.FileId}`
      );
      fetchFuentesImagenes(urls);
    }
  }, [fuentesLetra]);
  useEffect(() => {
    if (dataSource?.length > 0) {
      dataSource?.forEach((e) => {
        saveFontFace(
          e.FileId,
          e.FileName.split(".")[0],
          `data:application/octet-stream;base64,${e.Data}`
        );
      });
    }
  }, [dataSource]);

  const saveFontFace = async (name, data) => {
    const fontFace = new FontFace(name, `url(${data})`);
    document.fonts.add(fontFace); //
    try {
      // Carga la fuente
      await fontFace.load();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (resultadosApi?.length > 0) {
      setIsModalVisible(false);
      setFieldsData(resultadosApi.map((r) => ({ ...r, guid: v4() })));
      setMappingLoading(false);
    }
  }, [resultadosApi]);

  useEffect(() => {
    dispatch(obtenerFuentesLetra());
    dispatch(obtenerConfiguracionDatosSeleccionada());
    dispatch(obtenerParametrosApi());
    setScale(55);
  }, []);

  useEffect(() => {
    if (params.id) {
      setTemplateId(params.id);
      dispatch(obtenerPlantillaPorId({ PlantillaCertificadoId: params.id }));
    }
  }, [location.pathname, params.id, dispatch]);

  useEffect(() => {
    if (
      plantilla?.Contenido !== undefined &&
      configuracionSeleccionada?.length > 0
    ) {
      addStepToHistory(plantilla?.Contenido);
      setProjectName(plantilla?.PlantillaNombre);
      setPageLoading(false);
    }
  }, [plantilla, configuracionSeleccionada]);

  useEffect(() => {
    console.log(parametrosApi, "parametrosApi");
    setStateParametrosApi(parametrosApi);
  }, [parametrosApi]);

  useEffect(() => {
    let imgDataArr = [];
    let fieldsDataTemp = fieldsData;

    fieldsDataTemp.forEach((field, i) => {
      imgDataArr.push("");
      if (!field.guid && field !== "0") {
        fieldsDataTemp[i] = { ...field, guid: v4() };
      }
    });
    setImgDatas(imgDataArr);
    setFieldsData(fieldsDataTemp);
  }, [fieldsData.length]);

  const addFullfilled = (fieldName) => {
    let fieldsArray = fullfilledFields;
    fieldsArray.push(fieldName);
    setFullfilledFields(fieldsArray);
  };

  const deletePage = (indexPage) => {
    let tempArr = fieldsData;
    tempArr.splice(indexPage, 1);
    setFieldsData(tempArr);
  };

  const buscarCoincidenciaApiDatos = (campo) => {
    let coincidencia = camposApiProyecto?.ApiColumns.find(
      (col) => col.toLowerCase() === campo.Clave?.toLowerCase()
    );
    return coincidencia !== undefined ? coincidencia : null;
  };

  const customSubmit = async (data) => {
    const length = data.Certificados.length;
    let divider;
    if (length > 50) {
      setMappingLoading(true);
      if (length > 100) divider = 3;
      else divider = 2;

      let partOfData = {
        Certificados: data.Certificados.splice(0, Math.round(length / divider)),
      };
      let { payload } = await dispatch(almacenarProyecto(partOfData));
      let result = payload;
      if (result.success) {
        customSubmit(data);
      }
    } else {
      onSubmit(data);
    }
  };
  return (
    <>
      <AnimatePresence>
        {pageLoading && (
          <motion.div
            className="logo-frame"
            id="frameLogo"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <img src={Loading} alt="Cargando" />
          </motion.div>
        )}
      </AnimatePresence>
      <div id="principal-container" className="root-project">
        <GeneratorOptions
          settings={configuracionSeleccionada}
          setCreationMethod={setCreationMethod}
          setFieldsData={setFieldsData}
          setIsModalVisible={setIsModalVisible}
        />
        <div className="flex-container generator">
          <Generator
            setCreationMethod={setCreationMethod}
            projectDescription={projectDescription}
            setProjectDescription={(des) => setProjectDescription(des)}
            projectName={projectName}
            setProjectName={(name) => setProjectName(name)}
            filledFields={fullfilledFields}
            settings={configuracionSeleccionada}
            fieldsData={fieldsData}
            setFieldsData={setFieldsData}
            pageIntersected={pageIntersected}
          />

          <div className="project-sub-principal-container">
            <h4>Vista previa</h4>
            <div id="projectScrollArea" onScroll={scrollFunction}>
              {fieldsData?.map((register, index) => {
                return (
                  <div style={{ position: "relative" }}>
                    <button
                      className="btn btn-remove-page"
                      onClick={() => deletePage(index)}
                    >
                      x
                    </button>
                    <CertificadoCanvas
                      key={index}
                      id={index.toString()}
                      isEditable={false}
                      selectShape={selectShape}
                      selectedId={fieldsData.length == 1 ? selectedId : null}
                      setData={(d) => setData(d)}
                      data={data}
                      addFullfilled={(name) => addFullfilled(name)}
                      undo={handleUndo}
                      canvasSize={canvasSize}
                      scale={scale}
                      history={history}
                      historyIndex={historyIndex}
                      addStepToHistory={addStepToHistory}
                      textRegister={register !== "0" && register}
                      guid={register.guid}
                      setImgArray={(img) => {
                        let arr = imgDatas;
                        arr[index] = img;
                        setImgDatas(arr);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <span className="edition-page-count">
              página {pageIntersected} de {totalPages}
            </span>
          </div>
        </div>
        <div className="flex-container project-bottom-bar">
          <button
            disabled={sendingData}
            className="btn btn-primary editor-btn-save"
            onClick={() => {
              customSubmit({
                Certificados: fieldsData.map((item, i) => ({
                  Certificado: {
                    Nombre: projectName,
                    Descripcion: projectDescription,
                    PlantillaCertificadoId: templateId,
                    MetodoGeneracionId: creationMethod,
                    GUID: item.guid,
                  },
                  CertificadoCandidatosDatos: configuracionSeleccionada.map(
                    (campoConfig) => ({
                      PlantillaDatoId: campoConfig.Id,
                      Valor: item[campoConfig.Clave],
                    })
                  ),
                  Archivo: {
                    Data: imgDatas[i],
                    ArchivoNombre: `${projectName} número ${i + 1}.png`,
                    ContentType: "image/png",
                  },
                })),
              });
            }}
          >
            Guardar cambios
          </button>
          <button className="btn btn-alternative" onClick={addPagesToPdf}>
            <i className="Material-Icons-Outlined"></i>
          </button>
          <img
            src={spinner}
            alt="animación enviando datos"
            hidden={!sendingData && !mappingLoading}
          />
        </div>
        <Modal
          isVisible={isModalVisible}
          title={
            stateParametrosApi.length > 0
              ? "Parámetros requeridos"
              : "Enlace de datos a plantilla"
          }
        >
          {camposApiProyecto.DatosColumns?.length > 0 && !mappingLoading ? (
            <form>
              {camposApiProyecto.DatosColumns?.map((campo) => {
                let coin = buscarCoincidenciaApiDatos(campo);
                return (
                  <Controller
                    name={campo.Clave}
                    control={control}
                    defaultValue={coin !== null && coin}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <div>
                        <Field
                          error={errors[campo] && "Este campo es requerido"}
                          value={field.value}
                          valor={coin !== null && coin}
                          onChange={field.onChange}
                          Label={campo.Label}
                          Tipo="Opciones"
                          required={true}
                          Opciones={camposApiProyecto.ApiColumns?.map((op) => ({
                            Id: op,
                            Opcion: op,
                          }))}
                        />
                      </div>
                    )}
                  />
                );
              })}
              <FormButtons
                isSendingRequest={false}
                progressAction="Obtener datos"
                onProgress={handleSubmit(getApiData)}
                onRecoil={(e) => {
                  e.preventDefault();
                  setIsModalVisible(false);
                }}
              />
            </form>
          ) : stateParametrosApi.length > 0 && !mappingLoading ? (
            <form>
              {stateParametrosApi?.map((c) => {
                const id = c.KeyName;
                return (
                  <div key={id}>
                    <Controller
                      name={id}
                      control={control}
                      rules={{ required: c.EsObligatorio }}
                      render={({ field }) => (
                        <div>
                          <Field
                            error={errors[c.Clave] && "Este campo es requerido"}
                            value={field.value}
                            onChange={field.onChange}
                            Label={c.Label}
                            Tipo={c.DataTypeName}
                            required={c.EsObligatorio}
                          />
                        </div>
                      )}
                    />
                  </div>
                );
              })}
              <FormButtons
                isSendingRequest={false}
                progressAction="Siguiente"
                onProgress={handleSubmit(sendParametersValue)}
                onRecoil={(e) => {
                  e.preventDefault();
                  setIsModalVisible(false);
                }}
              />
            </form>
          ) : (
            <img src={spinner} alt="animación enviando datos" hidden={false} />
          )}
        </Modal>
        <FormToast
          code={successCode !== 0 && successCode}
          title={successCode === 1 ? "¡Bien!" : "Algo anda mal"}
          desc={
            successCode === 1
              ? "Datos almacenados correctamente"
              : "Revisa los datos e intenta de nuevo"
          }
        />
      </div>
    </>
  );
}

export default NewProyect;
