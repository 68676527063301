import React from "react";
import useDarkMode from "../../Hooks/useTheme";
import { FormInput } from "../Form/FormElements";

function ThemeSwitch() {

    const [darkMode, toggleDarkMode]=useDarkMode();

  return (
    <div className="switch-container">
      <input type="checkbox" id="switch" className="checkbox-switch" onChange={()=>toggleDarkMode()}/>
      <label className="fake-switch" htmlFor="switch">
        <span><i className='material-icons'></i></span> 
        <span><i className='material-icons'></i></span>
      </label>
    </div>
  );
}

export default ThemeSwitch;
