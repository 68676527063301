import { useEffect, useState} from "react";

function useUndoRedo(initialStep) {
  const [history, setHistory] = useState(initialStep);
  const [historyIndex, setHistoryIndex] = useState(0);

  const handleUndo = () => {
    if (historyIndex === 0) {
      return;
    }
    setHistoryIndex(historyIndex - 1);
  };

  const handleRedo = () => {
    if (historyIndex === history.length - 1) {
      return;
    }
    setHistoryIndex(historyIndex + 1);
  };
  
    const addStepToHistory = (newStep) => {
      let historyCopy = history;
      historyCopy.push(newStep);
      setHistory(historyCopy);
      setHistoryIndex(historyCopy.length - 1);
    };


  return {handleRedo,handleUndo,addStepToHistory,history, historyIndex}
}

export default useUndoRedo