import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";


export const obtenerEmpresaPorId = createAsyncThunk(
  "empresas/obtenerEmpresaPorId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("Empresas/" + id);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerEmpresas = createAsyncThunk(
  "empresas/obtenerEmpresas",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`Empresas`);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const agregarEmpresa = createAsyncThunk(
  "empresas/agregarEmpresa",
  async (data, { rejectWithValue }) => {
    try {
      await WebApi.api().post("Empresas", data);
      return {
        show: true,
        message: "Campos agregados",
        success:true,
        result: [],
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const actualizarEmpresa = createAsyncThunk(
  "empresas/actualizarEmpresa",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put("Empresas/" + data.EmpresaId, data);
      return {
        show: true,
        message: "Campos actualizados",
        success:true,
        result: [],
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const eliminarEmpresa = createAsyncThunk(
  "empresas/eliminarEmpresa",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put("Empresas/" + data.EmpresaId, {EstadoId:2});
      return {
        show: true,
        message: "Empresa desactivado",
       success:true,
        result: [],
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const empresasSlice = createSlice({
  name: "empresas",
  initialState: {
    empresas: [],
    isLoading:true,
    empresa: {},
    result: { success: false, message: "", show: false },
  },
  extraReducers: {
    [obtenerEmpresaPorId.fulfilled]: (state, action) => {
      state.empresa = action.payload;
    },
    [obtenerEmpresas.fulfilled]: (state, action) => {
      state.empresas = action.payload;
      state.isLoading=false;
    },
  },
});

export default empresasSlice.reducer;
