import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoResults from "../../../IMG/NoResults.svg";
import Card from "../../../Components/Other/Card";
import Options from "../../../Components/DataBooks/Options";
import { useNavigate } from "react-router-dom";
import FormToast from "../../../Components/Form/FormToast";
import {
  obtenerAppCredentialById,
  obtenerAppCredentials,
} from "../../../Redux/reducers/appCredentialsSlice";
import FromAppCredentials from "./FormAppCredentials";

function AppCredentials() {
  const [formState, setFormState] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [successCode, setSuccessCode] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appCredentials, appCredential, isLoading } = useSelector(
    (state) => state.appCredentials
  );
  const [toastMessage, setToastMessage] = useState();

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = () => {
    dispatch(obtenerAppCredentials());
  };

  const obtenerRegistro = (id) => {
    dispatch(obtenerAppCredentialById(id));
  };

  const setToast = (code, message) => {
    setSuccessCode(code);
    setToastMessage(message);
    setTimeout(() => {
      setSuccessCode(0);
      setToastMessage(null);
    }, 4000);
  };

  const renderTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Código App</th>
          </tr>
        </thead>
        <tbody>
          {appCredentials?.map((item, index) => (
            <tr key={item.Id}>
              <td>{item.AppId}</td>
              <td>
                <Options
                  actions={[
                    {
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Editar",
                      onclick: () => {
                        setFormState(true);
                        setIsEditing(true);
                        obtenerRegistro(item.Id);
                      },
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <FormToast
        code={successCode}
        title={successCode == 2 ? "¡Algo anda mal!" : "¡Bien!"}
        desc={
          toastMessage !== null
            ? toastMessage
            : successCode == 2
            ? "Revisa los datos e intenta nuevamente"
            : "Credencial de App guardada correctamente"
        }
      />

      <FromAppCredentials
        isEditing={isEditing}
        close={(e) => {
          e?.preventDefault();
          setFormState(false);
          setIsEditing(false);
        }}
        isVisible={formState}
        data={isEditing ? appCredential : {}}
        reload={() => cargarDatos()}
        setCode={(a) => setToast(a, null)}
      />
      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="form-title">
          <div className="flex-container settings-right-superior-container">
            <button
              className="btn btn-primary"
              onClick={() => {
                setIsEditing(false);
                setFormState(true);
              }}
            >
              Nueva credencial
            </button>
          </div>
          <br />
        </div>
        <Card
          classN="card plantillas-card container-col-whole-space scroll-view"
          isLoading={isLoading}
          type="table"
        >
          {appCredentials?.length > 0 ? (
            <div className="table-container">{renderTable()}</div>
          ) : (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          )}
        </Card>
      </motion.main>
    </>
  );
}
export default AppCredentials;
