import {useState } from 'react'
import QRCode from "qrcode";

function useGenerateQr(qrProps, callback, urlGuid, colorbg="#ffffff", colorqr="#262626") {

const [qrBgColor, setQrBgColor] = useState(colorbg);
  const [qrColor, setQrColor] = useState(colorqr);

  const generateQR = () => {
    QRCode.toDataURL(
      urlGuid,
      {
        color: {
          dark: qrColor,
          light: qrBgColor,
        },
      },
      (err, url) => callback({ ...qrProps, GUID:urlGuid, data: url, stroke:qrColor, fill:qrBgColor  })
    );
  };


  return {setQrBgColor, qrBgColor,setQrColor, qrColor,generateQR}
}

export default useGenerateQr