import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormButtons from "../../../Components/Form/FormButtons";
import { FormInput } from "../../../Components/Form/FormElements";
import FormToast from "../../../Components/Form/FormToast";
import Modal from "../../../Components/Other/Modal";
import useSubmit from "../../../Hooks/useSubmit";
import {
  actualizarAppCredential,
  almacenarAppCredential,
  obtenerEmpresa,
} from "../../../Redux/reducers/appCredentialsSlice";

function FromAppCredentials({ data, isVisible, close, isEditing, reload }) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { onSubmit, sendingData, successCode } = useSubmit(
    isEditing,
    almacenarAppCredential,
    actualizarAppCredential
  );
  const { empresa } = useSelector((state) => state.appCredentials);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(obtenerEmpresa());
  }, []);

  useEffect(() => {
    if (successCode == 1) {
      reset({});
      setTimeout(() => {
        close();
        reload();
      }, 1000);
    }
  }, [successCode]);

  useEffect(() => {
    reset(data);
  }, [data]);

  useEffect(() => {
    let indexHeader, indexMenu, indexNavigation;
    if (isVisible) {
      indexHeader = indexMenu = indexNavigation = 0;
    } else {
      indexHeader = 10;
      indexMenu = 7;
      indexNavigation = 3;
    }
    document
      .getElementById("header")
      .setAttribute("style", `z-index: ${indexHeader}`);
    document
      .querySelector(".vertical-menu")
      .setAttribute("style", `z-index: ${indexMenu}`);
    document
      .getElementById("navigation-tab")
      .setAttribute("style", `z-index: ${indexNavigation}`);
  }, [isVisible]);

  const form = {
    AppId: {
      label: "Código App",
      type: "text",
      placeholder: "AppId",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
    Secret: {
      label: "Secret",
      type: "password",
      placeholder: "Secret",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const formInputs = Object.keys(form).map((e) => {
    const { rules, defaultValue } = form[e];
    return (
      <section key={e}>
        <Controller
          name={e}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <FormInput
                error={errors[e] && "Este campo es requerido"}
                value={field.value}
                onChange={field.onChange}
                {...form[e]}
              />
            </div>
          )}
        />
      </section>
    );
  });

  return (
    <Modal
      isVisible={isVisible}
      title={isEditing ? "Edición de credenciales" : "Nueva credencial"}
    >
      <form>
        {formInputs}

        {errors.cuerpo && <p className="error">{errors.cuerpo.message}</p>}

        <FormButtons
          onProgress={handleSubmit((data) =>
            onSubmit({ ...data, EmpresaId: empresa[0]?.EmpresaId })
          )}
          progressAction="Guardar"
          isSendingRequest={sendingData}
          onRecoil={(e) => {
            reset({});
            close(e);
          }}
          recoilAction="Cancelar"
        />
        <FormToast
          code={successCode}
          title={successCode == 1 ? "¡Bien!" : "Algo anda mal"}
          desc={
            successCode == 1
              ? "Credencial almacenada"
              : "Revisa los datos e intenta de nuevo"
          }
        />
      </form>
    </Modal>
  );
}

export default FromAppCredentials;
