import React, { useState } from 'react'
import Card from './Card'

function Carousel({title,children, isNotShowing, isLoading, classN,long}) {
    const [positionX,setPositionX]=useState(0)


    const MoveElementsLeft=()=>{
        if (positionX>-long)
        setPositionX(positionX-220)
    }

    const MoveElementsRight=()=>{
        if (positionX<0)
        setPositionX(positionX+220)
    }

  return (
    <div  className={`carousel-container ${classN && classN}`}>
      {title && 
          <h2 className="section-name">{title}</h2>
      }
          <button className={`btn btn-carousel-right ${isNotShowing || positionX<=-1100? 'is-hidden':null}`} onClick={MoveElementsLeft}><i className='material-icons'></i></button>
          <button className={`btn btn-carousel-left ${isNotShowing || positionX>=0? 'is-hidden':null}`} onClick={MoveElementsRight}><i className='material-icons'></i></button>
          <Card isLoading={isLoading} classN="card-transparent carousel">
          <div className="flex-container carousel-group" style={{transform:`translateX(${positionX}px)`}}>
            {children}
            </div>
          </Card>
        </div>
  )
}

export default Carousel