import React from 'react'
import { Link, NavLink } from 'react-router-dom'

export default function MenuItem(props) {
  return (
    <>
    <NavLink to={props.route? props.route:"nowhere"} className={props.class} end> 
       {props.icon}
        <span>{props.name}</span></NavLink>
    </>
  )
  }
