import { useEffect, useState } from 'react'

function useCanvasScale(width,height) {

const [scale, setScale] = useState(100);
const [canvasSize, setCanvasSize] = useState({
        canvasWidth: null,
        canvasHeight: null,
      });

  useEffect(() => {
    setCanvasSize({
      canvasWidth: (width * scale) / 100,
      canvasHeight: (height * scale) / 100,
    });
  }, [scale,width,height]);

  return {canvasSize,scale, setScale}
}

export default useCanvasScale