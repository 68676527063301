import { useNavigate } from "react-router-dom";
import "./App.css";
import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { RouteElements} from "./Routes";
import EventBus from "./services/EventBus";
import AuthService from "./services/AuthService";
function App() {

  useEffect(() => {
    document.getElementById("frameLogo").style.display = 'none';
  }, [])

  const navigate=useNavigate();

  useEffect(()=>{
      EventBus.on("logout", () => {
        AuthService.logout(navigate);
      });
  
  },[])

  return (
    <div className="App">
        <AnimatePresence>
          <RouteElements/>
        </AnimatePresence>
    </div>
  );
}

export default App;
