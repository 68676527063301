import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerRecursos= createAsyncThunk(
  "recursos/obtenerRecursos",
  async (id, { rejectWithValue }) => {
    try {
        const res = await WebApi.api().post('Componentes/ObtenerReusables',{CategoriaId:id});
        return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerRecursosPorEmpresa= createAsyncThunk(
  "recursos/obtenerRecursosPorEmpresa",
  async (id, { rejectWithValue }) => {
    try {
        const res = await WebApi.api().post('Componentes/ObtenerReusablesPorEmpresa',{CategoriaId:id});
        return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const almacenarRecursos = createAsyncThunk(
  "recursos/almacenarRecursos",
  async (data, { rejectWithValue }) => {
    try {
        const res = await WebApi.api().post("Componentes",data);
        return { success: true, message: "Recurso almacenado", show: true };
      } catch (err) {
          return { success: false, message: err, show: true };
      }
  }
);


const initialState = {
    recursos: [],
    recurso:{},
    recursosPorEmpresa:[]
  };
  
  export const recursosSlice = createSlice({
    name: "recursos",
    initialState,
    extraReducers: {
      [obtenerRecursos.fulfilled]: (state, action) => {
        state.recursos = action.payload;
      },
      [obtenerRecursosPorEmpresa.fulfilled]: (state, action) => {
        state.recursosPorEmpresa = action.payload;
      },
    },
  });
  
  export default recursosSlice.reducer;
  