import React from "react";
import NoImg from "../../IMG/no-img.png";
import Options from "./Options";

export default function Mosaic(props) {
  return (
    <div className="mosaic-card">
      <div className="mosaic-img-container">
        <img
          src={props.imagen ? props.imagen : NoImg}
          alt="Imagen del registro"
        ></img>
      </div>

      {props.title && (
        <div className="mosaic-info flex-container">
          <div>
            <span className="mosaic-title">{props.title}</span>
            <br />
            <span className="mosaic-subtitle">{props.subtitle}</span>
          </div>
          {props.actions && (
            <div className="options">
              <Options actions={props.actions} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
