import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerDatosCertificados, obtenerDatosCertificadoPorId } from "../../../Redux/reducers/datosCertificadoSlice";

function DataToolbar({setSettingsPreview,reload}) {
  const { datosCertificados } = useSelector((state) => state.datosCertificados);
  const { datosCertificado } = useSelector((state) => state.datosCertificados);
  const dispatch = useDispatch();

  useEffect(() => {
    cargarData();
    getDatabyId(12)
  }, []);

  useEffect(()=>{
    cargarData();
  },[reload])

  useEffect(()=>{
    setSettingsPreview(datosCertificado)
  },[datosCertificado])

  const cargarData = () => {
    dispatch(obtenerDatosCertificados());
  };

  const getDatabyId=(id)=>{
    dispatch(obtenerDatosCertificadoPorId(id))
  }

  return (
    <div>
      <div>
      <h4>Configuraciones de datos</h4>
      </div>
      {datosCertificados?.map((item) => (
        <button className="btn btn-secondary" key={item.ConfiguracionDatoId} onClick={()=>{getDatabyId(item.ConfiguracionDatoId)}}>
          {item.ConfiguracionDatoNombre} <i className="Material-Icons-Outlined"></i>
        </button>
      ))}
    </div>
  );
}

export default DataToolbar;
