import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerPlantillas = createAsyncThunk(
  "plantillas/obtenerPlantillas",
  async (params, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        `PlantillasCertificados?page=${params.page}&rowsCount=${params.count}&PlantillaNombre=${params.nombre}`
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerTopPlantillas = createAsyncThunk(
  "plantillas/obtenerTopPlantillas",
  async (nombre, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "PlantillasCertificados/ObtenerUltimasPlantillasInsertadas",
        { cantidad: 10 }
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerTopPlantillasProyectos = createAsyncThunk(
  "plantillas/obtenerTopPlantillasProyectos",
  async (nombre, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "PlantillasCertificados/ObtenerUltimasPlantillasUsadas",
        { cantidad: 10 }
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerPlantillaPorId = createAsyncThunk(
  "plantillas/obtenerPlantillaPorId",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "PlantillasComponentes/Obtener",
        item
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerPlantillaInfoPorId = createAsyncThunk(
  "plantillas/obtenerPlantillaInfoPorId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("PlantillasCertificados/" + id);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const almacenarPlantilla = createAsyncThunk(
  "plantillas/almacenarPlantillas",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("PlantillasCertificados", data);
      return { success: true, message: "Plantilla almacenada", show: true };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const actualizarPlantilla = createAsyncThunk(
  "plantillas/actualizarPlantillas",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put("PlantillasCertificados/" + data.id, {
        ...data.obj,
      });
      return { success: true, message: "Plantilla actualizada", show: true };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const eliminarPlantilla = createAsyncThunk(
  "plantillas/eliminarPlantillas",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().delete("PlantillasCertificados/" + id);
      return { success: true, message: "Plantilla eliminada", show: true };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

const initialState = {
  plantillas: [],
  topPlantillas: null,
  plantillasProyectosRecientes: null,
  isLoading: false,
  plantilla: {},
  plantillaInfo: null,
};

export const plantillasSlice = createSlice({
  name: "plantillas",
  initialState,
  extraReducers: {
    [obtenerPlantillas.pending]: (state, action) => {
      state.isLoading = true;
      state.plantillas=[]
    },
    [obtenerPlantillas.fulfilled]: (state, action) => {
      state.plantillas = action.payload;
      state.isLoading = false;
    },
    [obtenerTopPlantillas.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerTopPlantillas.fulfilled]: (state, action) => {
      state.topPlantillas = action.payload;
      state.isLoading = false;
    },
    [obtenerTopPlantillasProyectos.fulfilled]: (state, action) => {
      state.plantillasProyectosRecientes = action.payload;
    },

    [obtenerPlantillaPorId.fulfilled]: (state, action) => {
      state.plantilla = action.payload;
    },
    [obtenerPlantillaInfoPorId.fulfilled]: (state, action) => {
      state.plantillaInfo = action.payload;
    },
    [obtenerPlantillaInfoPorId.pending]: (state, action) => {
      state.plantillaInfo = null;
    },
  },
});

export default plantillasSlice.reducer;
