import React, { useState } from "react";
import Sidebar from "../Components/Layout/Sidebar";
import Header from "../Components/Layout/Header";
import Breadcrumb from "../Components/Layout/Breadcrumb";
import "../Styles/base.css";
import "../Styles/layout.css";
import "../Styles/modules.css";
import "../Styles/states.css";
import "../Styles/theme.css";
import Loading from "../IMG/Loading Robot.gif"
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

function Principal({ crumbs }) {
  const [wideMenu, setWideMenu] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);


  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPageLoading(false);
      }, 100);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <>
      <AnimatePresence>
      {pageLoading && (
        <motion.div className="logo-frame" id="frameLogo"  initial={{opacity:1}} animate={{opacity:1}} exit={{ opacity: 0 }}>
          <img src={Loading} alt="Cargando" />
        </motion.div>
      )}
      </AnimatePresence>
      <div id="principal-container">
        <Sidebar isOpened={wideMenu} toggleMenu={() => setWideMenu(!wideMenu)}/>
        <div id="sub-principal-container">
          <Header toggleMenu={() => setWideMenu(!wideMenu)} wideMenu={wideMenu} />
          <Breadcrumb crumbs={crumbs} />
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Principal;
