import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerAppCredentials = createAsyncThunk(
  "appCredentials/obtenerAppCredentials",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("EmpresasAppId/ObtenerPorUsuario");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerAppCredentialById = createAsyncThunk(
  "appCredentials/obtenerAppCredentialById",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("EmpresasAppId/" + id);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const actualizarAppCredential = createAsyncThunk(
  "appCredentials/actualizarAppCredential",
  async (item, { rejectWithValue }) => {
    try {
      let id = item.Id;
      const res = await WebApi.api().put("EmpresasAppId/" + id, item);
      return { success: true, message: "Cambios realizados", show: true };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const almacenarAppCredential = createAsyncThunk(
  "appCredentials/almacenarAppCredential",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("EmpresasAppId", item);
      return { success: true, message: "Plantilla almacenada", show: true };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);
export const obtenerEmpresa = createAsyncThunk(
  "appCredentials/obtenerEmpresa",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("EmpresasUsuarios/ObtenerPorUsuario");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  appCredentials: [],
  appCredential: {},
  empresa:{},
  isLoading: true,
  appCredentialsTipos: [],
};

export const appCredentialsSlice = createSlice({
  name: "appCredentials",
  reducers: {},
  initialState,
  extraReducers: {
    [obtenerAppCredentials.fulfilled]: (state, action) => {
      state.appCredentials = action.payload;
      state.isLoading = false;
    },
    [obtenerAppCredentialById.fulfilled]: (state, action) => {
      state.appCredential = action.payload;
    },
    [obtenerEmpresa.fulfilled]: (state, action) => {
      state.empresa = action.payload;
    },
  },
});

export default appCredentialsSlice.reducer;
