import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerPlantillasConfig = createAsyncThunk(
  "plantillasConfig/obtenerPlantillasConfig",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Plantillas/ObtenerPorEmpresa");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerTiposPlantillasConfig = createAsyncThunk(
  "plantillasConfig/obtenerTiposPlantillas",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("plantillasTipos");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerPlantillaConfigById = createAsyncThunk(
  "plantillasConfig/obtenerPlantillaConfigById",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("Plantillas/"+id);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const actualizarPlantillaConfig = createAsyncThunk(
  "plantillasConfig/actualizarPlantillaConfig",
  async (item, { rejectWithValue }) => {
    try {
      delete item["ModificadoPor"];
      delete item["FechaModificacion"];
      let id=item.PlantillaId;
      delete item["PlantillaId"]
      const res = await WebApi.api().put("Plantillas/"+id,item);
      return { success: true, message: "Cambios realizados", show: true };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);


export const almacenarPlantillaConfig= createAsyncThunk(
  "plantillasConfig/almacenarPlantillaConfig",
  async (item, { rejectWithValue }) => {
    try {
      let obj={...item,EstadoId:1,EsTextoPlano:false}
      const res = await WebApi.api().post("Plantillas",obj);
      return { success: true, message: "Plantilla almacenada", show: true };
    } catch (err) {
        return { success: false, message: err, show: true };
    }
  }
);


const initialState = {
  plantillasConfig: [],
  plantillaConfig:{},
  isLoading:true,
  plantillasConfigTipos:[]
};

export const plantillasConfigSlice = createSlice({
  name: "plantillasConfig",
  reducers: {},
  initialState,
  extraReducers: {
    [obtenerPlantillasConfig.fulfilled]: (state, action) => {
      state.plantillasConfig = action.payload;
      state.isLoading=false;
    },
    [obtenerPlantillaConfigById.fulfilled]: (state, action) => {
      state.plantillaConfig = action.payload;
    },
    [obtenerTiposPlantillasConfig.fulfilled]: (state, action) => {
      state.plantillasConfigTipos = action.payload;
    },
  },
});

export default plantillasConfigSlice.reducer;
