import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerDatosCertificados = createAsyncThunk(
  "datosCertificados/obtenerDatosCertificados",
  async (nombre, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "PlantillasCertificadosConfiguracionesOpciones?filters[EstadoId][$eq][0]=1"
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerDatosCertificadoPorId = createAsyncThunk(
  "datosCertificados/obtenerDatosCertificadoPorId",
  async (ConfiguracionDatoId, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "PlantillasCertificados/ObtenerConfiguraciones",
        { ConfiguracionDatoId }
      );
      let array = res.data.Result;
      let response = {
        ConfiguracionDatoId: res.data.Result[0].ConfiguracionDatoId,
        ConfiguracionDatoNombre: res.data.Result[0].ConfiguracionDatoNombre,
        dynamicFieldArray: array,
      };
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerConfiguracionDatosSeleccionada = createAsyncThunk(
  "datosCertificados/obtenerConfiguracionDatosSeleccionada",
  async (e, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "PlantillasCertificados/DatosObtenerDeConfiguracionesGenerales"
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const almacenarDatosCertificado = createAsyncThunk(
  "datosCertificados/almacenarDatosCertificados",
  async (data, { rejectWithValue }) => {
    try {
      let Configuracion = {
        ConfiguracionDatoNombre: data["ConfiguracionDatoNombre"],
      };
      let DynamicFieldArray = data.dynamicFieldArray;
      const res = await WebApi.api().post(
        "PlantillasCertificadosConfiguraciones",
        { Configuracion, DynamicFieldArray }
      );
      return {
        success: true,
        message: "Configuración de datos almacenada",
        show: true,
      };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const actualizarDatosCertificado = createAsyncThunk(
  "datosCertificados/almacenarDatosCertificados",
  async (data, { rejectWithValue }) => {
    try {
      let Configuracion = {
        ConfiguracionDatoNombre: data["ConfiguracionDatoNombre"],
      };
      let DynamicFieldArray = data.dynamicFieldArray;
      DynamicFieldArray.forEach(element => {
       delete element.ConfiguracionDatoId;
       delete element.ConfiguracionDatoNombre;
       delete element.TipoDatoNombre;
      });
      const res = await WebApi.api().put(
        "PlantillasCertificadosConfiguraciones/" +
          data.ConfiguracionDatoId,
        { Configuracion, DynamicFieldArray }
      );
      return {
        success: true,
        message: "Configuración de datos actualizado",
        show: true,
      };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const eliminarConfiguracionDatosCertificado = createAsyncThunk(
  "datosCertificados/eliminarDatosCertificados",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        "PlantillasCertificadosConfiguracionesOpciones/" + id,{estadoId:2}
      );
      return {
        success: true,
        message: "Configuración de datos eliminada",
        show: true,
      };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

const initialState = {
  datosCertificados: [],
  datosCertificado: {},
  configuracionSeleccionada: [],
};

export const datosCertificadoSlice = createSlice({
  name: "datosCertificados",
  initialState,
  extraReducers: {
    [obtenerDatosCertificados.fulfilled]: (state, action) => {
      state.datosCertificados = action.payload;
    },
    [obtenerDatosCertificadoPorId.fulfilled]: (state, action) => {
      state.datosCertificado = action.payload;
    },
    [obtenerConfiguracionDatosSeleccionada.fulfilled]: (state, action) => {
      state.configuracionSeleccionada = action.payload;
    },
  },
});

export default datosCertificadoSlice.reducer;
