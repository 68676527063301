import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

function useSubmit(boolEditing, postSubmit, putSubmit) {
  const [sendingData, setSendingData] = useState();
  const [successCode, setSuccessCode] = useState(0);
  const [isEditing, setIsEditing] = useState(boolEditing);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsEditing(boolEditing);
  }, [boolEditing]);

  const onSubmit = async (data, isAPut = null) => {
    let result;
    let edit;
    if (typeof isAPut === "boolean") {
      edit = isAPut;
    } else {
      edit = isEditing;
    }
    setSendingData(true);
    if (edit) {
      let { payload } = await dispatch(putSubmit(data));
      result = payload;
    } else {
      console.log(data);
      let { payload } = await dispatch(postSubmit(data));
      result = payload;
    }
    if (result.success) {
      setSuccessCode(1);
      setSendingData(false);
      setTimeout(() => setSuccessCode(0), 4000);
    } else {
      setSuccessCode(2);
      setSendingData(false);
      setTimeout(() => setSuccessCode(0), 4000);
    }
  };

  return { onSubmit, sendingData, successCode };
}

export default useSubmit;
