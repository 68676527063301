import React from "react";
import Mosaic from "./Mosaic";

function MosaicData({ data, actions, imageField, titleField, onClick,idField, }) {


  const renderMosaics = () => {
    let grid = data?.map((item) => (
      <div className="simple-btn mosaic-container" key={item[idField]} onClick={ ()=>onClick && onClick(item[idField])}>
      <Mosaic
        imagen={item[imageField]}
        key={item[idField]}
        id={item[idField]}
        title={item[titleField]}
        actions={actions(item)?.map((action)=>(
          {
            icon: action.icon,
            text: action.text,
            onclick: () => action.onclick(),
          }
        ))}
        />
        </div>
    ));

    return grid
  };

  return <div className="flex-container mosaic-group">{renderMosaics()}</div>;
}

export default MosaicData;
