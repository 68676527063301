import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoResults from "../../../IMG/NoResults.svg";
import Card from "../../../Components/Other/Card";
import Options from "../../../Components/DataBooks/Options";
import FormPlantillas from "./FormPlantillas";
import { useNavigate } from "react-router-dom";
import FormToast from "../../../Components/Form/FormToast";
import { almacenarPlantillaConfig, obtenerPlantillaConfigById, obtenerPlantillasConfig } from "../../../Redux/reducers/plantillasConfigSlice";

function Plantillas() {
  const [formState, setFormState] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [successCode, setSuccessCode]=useState(0)
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { plantillasConfig,plantillaConfig, isLoading } = useSelector((state) => state.plantillasConfig);
  const [toastMessage,setToastMessage]=useState();

  useEffect(() => {
   cargarDatos()
  }, []);

  const cargarDatos=()=>{
    dispatch(obtenerPlantillasConfig());
  }

  const obtenerRegistro = (id) => {
    dispatch(obtenerPlantillaConfigById(id));
  };

  const setToast=(code,message)=>{
    setSuccessCode(code);
    setToastMessage(message)
    setTimeout(() => {setSuccessCode(0);setToastMessage(null)}, 4000);
  }

  const duplicarPlantilla = async (obj) => {
    let item={...obj};
    delete item["ModificadoPor"];
    delete item["FechaModificacion"];
    delete item["PlantillaId"];
    item["PlantillaNombre"]=item["PlantillaNombre"] + " copia"
    let { payload } = await dispatch(almacenarPlantillaConfig(item));
    let result = payload;
    if (result.success) {
      setToast(1,"Plantilla duplicada");
      cargarDatos()
    } else {
      setToast(2, "No se ha podido duplicar la plantilla")
    }
  }

  const renderTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>
          {plantillasConfig?.map((item, index) => (
            <tr key={item.PlantillaId}>
              <td>{item.PlantillaNombre}</td>
              <td>
                <Options
                  actions={[
                    {
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Editar",
                      onclick: () => {
                        if(item.HaSidoUsada==false){
                          setFormState(true);
                          setIsEditing(true);
                          obtenerRegistro(item.PlantillaId);
                        }
                        else{
                          setToast(2,"La plantilla ya ha sido utilizada.")
                        }
                      },
                    },
                    {
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Duplicar",
                      onclick: () => {
                        duplicarPlantilla(item);
                      },
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <FormToast
                code={successCode}
                title={successCode == 2 ? "¡Algo anda mal!": "¡Bien!"}
                desc={toastMessage!==null?toastMessage: successCode == 2? "Revisa los datos e intenta nuevamente" : "Plantilla guardada correctamente"}
              />

      <FormPlantillas
        isEditing={isEditing}
        close={(e) => {
          e?.preventDefault();
          setFormState(false);
          setIsEditing(false);
        }}
        isVisible={formState}
        plantillaDatos={isEditing? plantillaConfig:{}}
        reload={()=>cargarDatos()}
        setCode={(a)=>setToast(a,null)}
      />
      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="form-title">
          <div className="flex-container settings-right-superior-container">
          <button
            className="btn btn-primary"
            onClick={() => {setIsEditing(false); setFormState(true);}}
          >
            Nueva plantilla
          </button>
          <button
            className="btn btn-alternative"
            onClick={() => {
              navigate('./logs/Plantillas');
            }}
          >
            Cambios realizados
          </button>

          </div>
          <br/>
        </div>
        <Card
          classN="card plantillas-card container-col-whole-space scroll-view"
          isLoading={isLoading}
          type="table"
        >
          {plantillasConfig?.length > 0 ? (
            <div className="table-container">{renderTable()}</div>
          ) : (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          )}
        </Card>
      </motion.main>
    </>
  );
}
export default Plantillas;
