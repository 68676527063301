import React from "react";
import spinner from "../../IMG/SpinnerSending.gif";
function FormButtons({
  onProgress,
  onRecoil,
  recoilAction = "Cancelar",
  progressAction = "Aceptar",
  isSendingRequest=false,
}) {

  return (
    <div className="form-btn-group">
      <button
        disabled={isSendingRequest}
        className="btn btn-alternative"
        onClick={onRecoil}
      >
        {recoilAction}
      </button>
      <button
        disabled={isSendingRequest}
        type="submit"
        className="btn btn-primary"
        onClick={onProgress}
      >
        {progressAction}
      </button>
      <div>
        <img
          src={spinner}
          alt="animación enviando datos"
          hidden={!isSendingRequest}
        />
      </div>
    </div>
  );
}

export default FormButtons;
