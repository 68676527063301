import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Breadcrumb({crumbs}) {
  const [historial, setHistorial] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setHistorial(location.pathname.split("/"));
  }, [location.pathname]);


 const generateLinks=()=>{
  let crumbsArray=crumbs().filter(({ name }) => historial.includes(name))
        .map(({ path, title }) => ({
          path, title
        }));
  
      return crumbsArray
      } 

  return (
    <div id="navigation-tab">
      <div className="flex-container">
        {generateLinks().map(({path,title}) =>
          path !== "" ? (
            <Link
              key={path}
              to=
              {path}
              className="grey-text flex-container is-hidden-on-mobile"
            >
            {title} <i className="material-icons"></i>
            </Link>
          ) : null
        )}
      </div>
      <hr />
    </div>
  );
}

export default Breadcrumb;
