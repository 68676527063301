import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const almacenarDesarrolloCertificado = createAsyncThunk(
  "desarrollo/almacenarDesarrolloCertificado",
  async (data, { rejectWithValue }) => {
    try {
      await axios.post(
        process.env.REACT_APP_DESARROLLO_DOCENTE_UR_API +"Certificados",
        data
      );
      return { success: true, message: "Certificado almacenado", show: true };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

const initialState = {};

export const desarrolloSlice = createSlice({
  name: "desarrollo",
  initialState,
  extraReducers: {},
});

export default desarrolloSlice.reducer;
