import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";



export const obtenerConfiguraciones = createAsyncThunk(
  "configuraciones/obtenerConfiguraciones",
  async (n, { rejectWithValue }) => {
    try {
        const res = await WebApi.api().get("GeneralSettings");
        return res.data.Result.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

  export const actualizarConfiguraciones = createAsyncThunk(
    "configuraciones/actualizarConfiguraciones",
    async (data, { rejectWithValue }) => {
      try {
          const res = await WebApi.api().put("GeneralSettings/",data);
          return { success: true, message: "Configuración actualizada", show: true };
        } catch (err) {
          return rejectWithValue(err.response);
      }
    }
  );

  export const configuracionesGeneralesDefault = createAsyncThunk(
    "configuraciones/configuracionesGeneralesDefault",
    async (Clave, { rejectWithValue }) => {
      try {
          const res = await WebApi.api().put("GeneralSettingsDefault/",{Clave});
          return { success: true, message: "Configuración actualizada", show: true };
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );

  
const initialState = {
    configuraciones:[],
  };
  
  export const configuracionesSlice = createSlice({
    name: "configuraciones",
    initialState,
    extraReducers: {
      [obtenerConfiguraciones.fulfilled]: (state, action) => {
        state.configuraciones= action.payload;
      },
    },
  });
  
  export default configuracionesSlice.reducer;



