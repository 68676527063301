import { configureStore } from "@reduxjs/toolkit";
import clientes from "./reducers/plantillasSlice";
import genericos from "./reducers/genericosSlice";
import configuraciones from "./reducers/configuracionesSlice";
import plantillas from "./reducers/plantillasSlice";
import plantillasConfig from "./reducers/plantillasConfigSlice";
import proyectos from "./reducers/proyectosSlice";
import recursos from "./reducers/recursosSlice";
import usuarios from "./reducers/usuariosSlice";
import datosCertificados from "./reducers/datosCertificadoSlice";
import logs from "./reducers/logsSlice";
import configuracionApi from "./reducers/apiexternaSlice";
import fuentesLetra from "./reducers/fuentesLetraSlice";
import empresas from "./reducers/empresasSlice";
import archivos from "./reducers/archivosSlice";
import appCredentials from "./reducers/appCredentialsSlice";

export default configureStore({
  reducer: {
    clientes,
    genericos,
    configuraciones,
    plantillas,
    plantillasConfig,
    proyectos,
    recursos,
    usuarios,
    datosCertificados,
    logs,
    configuracionApi,
    fuentesLetra,
    empresas,
    archivos,
    appCredentials
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
