import React, { useState} from 'react';
import jwt_decode from "jwt-decode"
import { Outlet, Navigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'


const ProtectedRoute = ({ component: Component, allowedRoles }) => {

    const [roles, setRoles] = useState([]);

    function hasJWT() {
        let flag = false;
        //check user has JWT token
        let token = localStorage.getItem("token");

        if (token) {
            let decoded = jwt_decode(token);
            flag = true;
            if (roles?.length === 0) {
                if (typeof (decoded.roles) === "string") {
                    let arr = []
                    arr.push(decoded.roles);
                    setRoles(arr);
                } else {
                    setRoles(decoded.roles);
                }
            }

        } else {
            flag = false;
        }
        return flag
    }

    return (
        hasJWT() ?
            roles?.find(x => allowedRoles?.includes(x)) ?
                <Outlet />
                :
                <Navigate to="/required" state={roles} />
            : <Navigate to="/Login" />
    )
}
export default ProtectedRoute