import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerProyectos = createAsyncThunk(
  "proyectos/obtenerProyectos",
  async (params, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Certificados/GetByEmpresa", {
        CertificadoNombre: params.CertificadoNombre,
        Page: params.Page,
        RowsCount: params.RowsCount,
      });
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const almacenarProyecto = createAsyncThunk(
  "proyectos/almacenarProyecto",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data, "daa");
      const res = await WebApi.api().post("Certificados", data);
      return { success: true, message: "Proyecto almacenada", show: true };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const obtenerCertificadoPorGUID = createAsyncThunk(
  "proyectos/obtenerCertificadoPorGUID",
  async (GUID, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Certificados/GetByGUID", { GUID });
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  proyectos: [],
  isLoading: true,
  certificado: {},
};

export const proyectosSlice = createSlice({
  name: "proyectos",
  initialState,
  extraReducers: {
    [obtenerProyectos.fulfilled]: (state, action) => {
      state.proyectos = action.payload;
      state.isLoading = false;
    },
    [obtenerProyectos.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerCertificadoPorGUID.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerCertificadoPorGUID.fulfilled]: (state, action) => {
      state.certificado = action.payload;
      state.isLoading = false;
    },
  },
});

export default proyectosSlice.reducer;
