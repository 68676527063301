import React, {useState } from "react";

function ContextMenu({borrar,moverAbsoluto, moverRelativo, x,y,copiar, pegar}) 
{
  return (
    <>
    <div className="is-showing-options" ></div>
    <div className="register-options-container" style={{top:y, left:x, right:"auto"}}>
      <button className="btn  " onClick={copiar}>
      <i className='material-icons'></i>Copiar
      </button>
      <button className="btn dark-text" onClick={(e)=>pegar(e)}>
      <i className='material-icons'></i>Pegar
      </button>
      <button className="btn dark-text" onClick={borrar}>
        <i className="Material-Icons-Outlined"></i>Eliminar
      </button>
      <button className="btn dark-text" onClick={()=>moverRelativo(1)}>
      <i className='material-icons'></i>Mover hacia adelante
      </button>
      <button className="btn dark-text" onClick={()=>moverAbsoluto()}>
      <i className='material-icons'></i>Mover hacia el frente
      </button>
      <button className="btn dark-text" onClick={()=>moverRelativo(-1)}>
      <i className='material-icons'></i>Mover detrás
      </button>
      <button className="btn dark-text" onClick={()=>moverAbsoluto(0)}>
      <i className='material-icons'></i>Mover al fondo
      </button>
    </div>
    </>
  );
}

export default ContextMenu;
 