import React from "react";
import { useState } from "react";
import Modal from "./Modal";
import Stadistics from "../../IMG/LookingAtDiploma.svg";
import listaPlantillas from "../../IMG/GuideGifs/listaPlantillas.gif"
import plantillaEdicion from "../../IMG/GuideGifs/plantillaEdicion.gif";
import configGenerales from "../../IMG/GuideGifs/configGenerales.gif";
import plantillasCorreo from "../../IMG/GuideGifs/plantillasCorreo.gif";

function Guide() {
  const guideSteps = [
    {
      img: listaPlantillas,
      title: "Utiliza plantillas predefinidas y crea tus propias plantillas.",
      text: "Cuentas con la función de añadir plantillas, editar tus diseños, y crear plantillas a partir de predefinidas. Además de esto, Certysafe te permite crear lotes de certificados con la configuración de datos que elijas.",
    },
    {
      img: plantillaEdicion,
      title: "Utiliza formas, estilos de texto, ilustraciones, y sube tus propias imágenes.",
      text: "Haz click, o arrastra un elemento para que pueda ser añadido a la hoja. Puedes guardar el progreso de tu diseño y descargar la hoja en formato pdf.",
    },
    {
      img: configGenerales,
      text: "Configura tus fuentes de datos externas, plantillas de correo y configuración de datos.",
      title: "Luego de añadir fuentes de datos API externas, y distintos campos de datos para incluir en los certificados, selecciona las configuraciones que quieres utilizar entre todas.",
    },
    {
      img: plantillasCorreo,
      title: "Crea y modifica las plantillas de notificaciones",
      text: "Personaliza plantillas de notificaciones en el sistema como lo es el correo de recuperación de contraseña.",
    },
  ];
  const [isVisible, setVisibility] = useState();
  const [activeSection, setActiveSection] = useState(-1);

  return (
    <>
      <button className="btn grey-text" onClick={() => setVisibility(true)}>
        <i className="material-icons"></i>
      </button>
      <Modal
        title="Manual de ayuda"
        isVisible={isVisible}
        bottomItems={
          <div className="form-btn-group">
            {activeSection >= 0 ? (
              <button
                type="button"
                className="btn alternative-btn"
                onClick={() => setActiveSection(activeSection - 1)}
              >
                Atrás
              </button>
            ) : (
              <button
                type="button"
                className="btn alternative-btn"
                onClick={() => {
                  setActiveSection(-1);
                  setVisibility(false);
                }}
              >
                Cerrar
              </button>
            )}
            {activeSection + 1 != guideSteps.length ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setActiveSection(activeSection + 1);
                }}
              >
                Siguiente
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-alternative"
                onClick={() => {
                  setActiveSection(-1);
                  setVisibility(false);
                }}
              >
                Cerrar
              </button>
            )}
          </div>
        }
      >
        <div className="guide-container">
          {activeSection === -1 ? (
            <section className="flex-container guide-introduction">
              <div className="container-col-30">
                <img alt="Ilustracion onboarding" src={Stadistics} />
              </div>
              <div className="container-col-75">
                <p>Crea y administra tus certificados de forma segura.</p>
              </div>
            </section>
          ) : (
            <section>
              <h4>{guideSteps[activeSection].title}</h4>
              <div className="container-col-75">
                <img alt="Gif de ayuda" src={guideSteps[activeSection].img} />
              </div>
              <div>
                <p>{guideSteps[activeSection].text}</p>
              </div>
            </section>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Guide;
