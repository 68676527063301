import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";



export const obtenerRendimiento = createAsyncThunk(
  "genericos/obtenerRendiento",
  async (n, { rejectWithValue }) => {
    try {
        const res = await WebApi.api().get("rendimiento");
        return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerLinea = createAsyncThunk(
  "genericos/obtenerLinea",
  async (n, { rejectWithValue }) => {
    try {
        const res = await WebApi.api().get("linea");
        return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerBarra = createAsyncThunk(
  "genericos/obtenerBarra",
  async (n, { rejectWithValue }) => {
    try {
        const res = await WebApi.api().get("barra");
        return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerDistribucion = createAsyncThunk(
  "genericos/obtenerDistribucion",
  async (n, { rejectWithValue }) => {
    try {
        const res = await WebApi.api().get("distribucion");
        return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerActivityCard = createAsyncThunk(
  "genericos/obtenerActivityCard",
  async (n, { rejectWithValue }) => {
    try {
        const res = await WebApi.api().get("activityCard");
        return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);



const initialState = {
  rendimiento: {},
  barra: {},
  distribucion: {},
  linea: {},
  activityCard: {},
};

export const genericosSlice = createSlice({
  name: "genericos",
  reducers: {},
  initialState,
  extraReducers: {
    [obtenerRendimiento.fulfilled]: (state, action) => {
      state.rendimiento = action.payload;
    },
    [obtenerBarra.fulfilled]: (state, action) => {
      state.barra = action.payload;
    },
    [obtenerDistribucion.fulfilled]: (state, action) => {
      state.distribucion = action.payload;
    },
    [obtenerLinea.fulfilled]: (state, action) => {
      state.linea = action.payload;
    },
    [obtenerActivityCard.fulfilled]: (state, action) => {
      state.activityCard = action.payload;
    },
  },
});

export default genericosSlice.reducer;
