import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerPlantillaPorId,
  obtenerPlantillas,
} from "../../../Redux/reducers/plantillasSlice";
import WebApi from "../../../Api/WebApi";

function TemplatesToolbar({ setTemplate, quitPageLoading, setTemplateId }) {
  const { plantillas, plantilla } = useSelector((state) => state.plantillas);
  const [imagenes, setImagenes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchImagenes = async (urls) => {
    try {
      const responses = await Promise.all(
        urls.map(async (url) => {
          const response = await WebApi.api().get(url);
          return response.data;
        })
      );

      const updatedData = plantillas?.Result?.map((item) => {
        const response = responses.find(
          (res) => res.Result.FileId === item.FileId
        );
        return {
          ...item,
          ...response?.Result,
        };
      });

      setImagenes(updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(plantillas?.Result?.length);
    console.count("1");
    if (plantillas?.Result?.length > 0) {
      console.log(2);
      const urls = plantillas?.Result?.map(
        (plantilla) =>
          `${process.env.REACT_APP_BASEURL}Files/${plantilla.FileId}`
      );
      fetchImagenes(urls);
    }
  }, [plantillas?.Result?.length]);
  useEffect(() => {
    cargarPlantillas();
  }, []);

  useEffect(() => {
    if (plantillas?.Result !== null && plantillas?.Result?.length > 0) {
      quitPageLoading();
    }
  });

  const cargarPlantillas = () => {
    dispatch(obtenerPlantillas({ count: 20, page: 1, nombre: null }));
  };

  const cargarPlantillaPorId = (id) => {
    dispatch(obtenerPlantillaPorId({ PlantillaCertificadoId: id }));
    if (plantilla && plantilla.Contenido !== undefined)
      setTemplate(plantilla?.Contenido);
    setTemplateId(plantilla?.PlantillaCertificadoId);
  };

  return (
    <section className="sub-menu-container palette">
      {/* <input
        type="search"
        className="registers-input-search"
        placeholder="Búsqueda"
      /> */}
      <ul>
        {imagenes.length > 0 ? (
          imagenes?.map((pl, i) => (
            <li key={i}>
              <button
                className="btn-template"
                onClick={() => cargarPlantillaPorId(pl.PlantillaCertificadoId)}
              >
                <img src={pl.Data} alt="plantilla" />
              </button>
            </li>
          ))
        ) : (
          <h2>Cargando...</h2>
        )}
      </ul>
    </section>
  );
}

export default TemplatesToolbar;
