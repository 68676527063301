import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { obtenerLogs } from "../../Redux/reducers/logsSlice";
import LogModal from "./LogModal";
import LogTableRow from "./LogTableRow";

export default function Logs() {
  const { logs } = useSelector((state) => state.logs);
  const [item, setItem] = useState();
  const dispatch = useDispatch();
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const { tableName,ids } = params;

  useEffect(() => {
    cargarDatos();
  }, []);
  const cargarDatos = () => {
    // if(tableName)
    dispatch(obtenerLogs(tableName));
    // if(ids)
    // dispatch(obtenerLogs({ids: ids}));

  };

  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    return previous;
  }

  return (
    <>
      <motion.main
        className="main-logs"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <LogModal
          log={item}
          isVisible={showModal}
          toggleVisibility={setShowModal}
        />

        {logs &&
          logs.map((e, i) => (
            <>
              {i >= 1 ? (
                <>
                  <div className="vertical-line "></div>
                  <h4 className="date-logs">
                    {new Date(e.ModifiedAt).toLocaleString("es-DO")}
                  </h4>
                </>
              ) : (
                <h4>{new Date(e.ModifiedAt).toLocaleString("es-DO")}</h4>
              )}
              <table className="table-log">
                <tbody>
                  <LogTableRow
                    key={e.LogId}
                    usuario={e.UserId}
                    date={new Date(e.ModifiedAt).toLocaleString("es-DO")}
                    data={JSON.parse(e.Changes)}
                  />
                </tbody>
              </table>
            </>
          ))}
      </motion.main>
    </>
  );
}
