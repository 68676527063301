import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useComponentVisible from "../../Hooks/useComponentVisible";
import noImg from "../../IMG/no-person-img.png";
import { obtenerUsuarioPorId } from "../../Redux/reducers/usuariosSlice";

function UserMenu() {
  const navigate = useNavigate();

  const [menuVisibility, setMenuVisibility] = useState(false);
  const { ref, isComponentVisible } = useComponentVisible(menuVisibility);
  const dispatch = useDispatch();
  const displayUserOptions = () => {
    setMenuVisibility(!menuVisibility);
  };

  const token = localStorage.getItem("token");
  const user = jwtDecode(token);
  const { usuario } = useSelector((state) => state.usuarios);
  useEffect(() => {
    dispatch(obtenerUsuarioPorId(user.id));
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  };

  return (
    <div id="user-menu">
      <button
        className={
          isComponentVisible
            ? "btn user-btn bold is-showing-options"
            : "btn user-btn bold"
        }
        onClick={displayUserOptions}
      >
        <img
          src={noImg}
          className="circular-container-sm"
          alt="Foto de perfil"
        />
        <span>{usuario.FullName ? usuario.FullName : "Menú de usuario"}</span>
        <i className="Material-Icons-Outlined"></i>
      </button>
      <div
        className={
          isComponentVisible
            ? "user-effect-container is-visible"
            : "user-effect-container"
        }
      >
        <div className="buble-1"></div>
        <div className="buble-2"></div>
        <div className="buble-3"></div>
      </div>
      <div
        className={
          isComponentVisible
            ? "user-options dark-text is-visible"
            : "user-options dark-text"
        }
        ref={ref}
      >
        <Link to={"/configuraciones"} className="btn flex-container">
          <i className="Material-Icons-Outlined"></i>Configuración
        </Link>

        <button
          className="btn flex-container"
          onClick={logout}
          href="login.html"
        >
          <i className="Material-Icons-Outlined"></i>Cerrar sesión
        </button>
      </div>
    </div>
  );
}

export default UserMenu;
