import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import Modal from "../../../Components/Other/Modal";
import ReactSelectGroup from "./ReactSelectGroup";

function SummerNoteReactV({
  name,
  control,
  rules = {},
  isEditing,
  height = 200,
  lista,
  val = undefined,
}) {
  const [showModal, setShowModal] = useState(false);
  const [valor, setValor] = useState(undefined);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.jQuery(document.getElementsByName(name)[0]).summernote({
      height: height,
      toolbar: [
        ["style", ["style"]],
        ["font", ["bold", "underline", "clear"]],
        ["color", ["color"]],
        ["para", ["ul", "ol", "paragraph"]],
        ["table", ["table"]],
        ["insert", ["link", "picture", "video"]],
        ["myButton", ["Variable"]],
      ],
      buttons: {
        Variable: HelloButton,
      },
      onCreateLink: function (link) {
        return link; // return origin link text
      },
      callbacks: {
        onChange: onChange,
      },
    });
  }, []);

  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: "",
  });

  var HelloButton = function (context) {
    var ui = context.ui;
    // create button
    var button = ui.button({
      contents: "Variables",
      // tooltip: 'Variables',
      click: function (e) {
        window
          .jQuery(document.getElementsByName(name)[0])
          .summernote("restoreRange");

        setShowModal(true);
        //}
      },
    });
    return button.render(); // return button as jquery object
  };

  useEffect(() => {
    if (isEditing) {
      window
        .jQuery(document.getElementsByName(name)[0])
        .summernote("code", val);
    }
  }, [isEditing,val]);

  const closeModal = (e) => {
    e.preventDefault();
    setError(false);
    setShowModal(false);
  };

  const insertarParametro = (e) => {
    e.preventDefault();
    if (!valor) {
      setError(true);
      return false;
    }

    window
      .jQuery(document.getElementsByName(name)[0])
      .summernote("restoreRange");
    window
      .jQuery(document.getElementsByName(name)[0])
      .summernote("insertText", `{{${valor}}}`);

    setShowModal(false);
    setValor(undefined);
  };

  return (
    <>
      <textarea
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
      ></textarea>

      <Modal
        isVisible={showModal}
        title={"Variables"}
        bottomItems={
          <div className="form-btn-group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={insertarParametro}
            >
              Insertar
            </button>
            <button
              type="button"
              className="btn alternative-btn"
              onClick={closeModal}
            >
              Cancelar
            </button>
          </div>
        }
      >
        <>
          <div>
            <div className="form-group">
              <label>Variables</label>
              <div className="is-required">
                <ReactSelectGroup
                  lista={lista}
                  value={valor}
                  handleChange={(e) => {
                    setError(false);
                    setValor(e);
                  }}
                />
              </div>
              <p
                className={
                  error === true ? "is-invalid error-info" : "grey-text"
                }
              >
                Seleccione la variable a insertar
              </p>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default SummerNoteReactV;
