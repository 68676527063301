import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Transformer, Rect } from "react-konva";
import usePositionOnArray from "../../Hooks/usePositionOnArray";
import ContextMenu from "./ContextMenu";
import Element from "./Elements";

const CertificadoCanvas = ({
  data,
  setData,
  draggedElement,
  canvasSize,
  scale,
  addStepToHistory,
  history,
  historyIndex,
  selectShape,
  undo,
  selectedId,
  textRegister,
  isEditable = true,
  id = null,
  setImgArray,
  guid = null,
}) => {
  const [onContextId, setOnContextId] = useState(null);
  const [contextMenu, setContextMenu] = useState({ x: null, y: null });
  const [copiedShape, setCopiedShape] = useState();
  const [nodesArray, setNodes] = React.useState([]);
  const [isSelectingMultiple, setIsSelectingMultiple] = useState(false);
  const { moveInArrayAbsolute, moveInArrayRelative } = usePositionOnArray(
    data?.content,
    selectedId
  );

  const Konva = window.Konva;
  const stageRef = useRef();

  useEffect(() => {
    if (data.content == null) {
      setData({
        img: null,
        content: JSON.parse(JSON.stringify(history[0])),
      });
    }
  }, [data, history]);

  useEffect(() => {
    if (
      history?.length > 1 &&
      history[historyIndex] !== undefined &&
      data?.content !== null
    ) {
      setData({
        img: stageRef?.current?.toDataURL(),
        content: JSON.parse(JSON.stringify(history[historyIndex])),
      });

      if (setImgArray) {
        setImgArray(stageRef?.current?.toDataURL({ pixelRatio: 3 }));
      }
    }
  }, [historyIndex, history]);

  const checkDeselect = (e) => {
    setOnContextId(null);
    selectShape([]);
  };

  const trRef = useRef();
  const selectionRectRef = useRef();
  const layerRef = useRef();
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  const updateSelectionRect = () => {
    const node = selectionRectRef.current;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(0, 161, 255, 0.3)",
    });
    node.getLayer().batchDraw();
  };

  const onMouseDown = (e) => {
    if (e.target.attrs.componenteId === 0) {
      checkDeselect(e);
      const pos = e.target.getStage().getPointerPosition();
      selection.current.x1 = pos.x;
      selection.current.y1 = pos.y;
      selection.current.x2 = pos.x;
      selection.current.y2 = pos.y;
      updateSelectionRect();
      selection.current.visible = true;
    }
  };

  const onMouseMove = (e) => {
    if (!selection.current.visible && selectedId != [0]) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
    setIsSelectingMultiple(true);
  };

  const onMouseUp = () => {
    if (isSelectingMultiple) {
      const selBox = selectionRectRef.current.getClientRect();
      const elements = [];
      let elBox;
      let content = data.content;
      layerRef?.current?.children.forEach((elementNode) => {
        elBox = elementNode.getClientRect();
        if (Konva.Util.haveIntersection(selBox, elBox)) {
          var obj = content.find(
            (o) => o.componenteId == elementNode.attrs.componenteId
          );
          if (obj && obj.componenteId !== undefined && obj.componenteId !== 0) {
            elements.push({ ...obj });
          }
        }
      });
      selectShape(elements);
      selection.current.visible = false;
      updateSelectionRect();
      Konva.listenClickTap = false;
      setIsSelectingMultiple(false);
    }
  };

  // const onClickTap = (e) => {
  //   // if we are selecting with rect, do nothing
  //   if (selectionRectRef.current.visible) {
  //     return;
  //   }
  //   let layer = layerRef.current;
  //   let tr = trRef.current;
  //   // do we pressed shift or ctrl?
  //   const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
  //   const isSelected = tr.nodes().indexOf(e.target) >= 0;

  //   if (!metaPressed && !isSelected) {
  //     // if no key pressed and the node is not selected
  //     // select just one
  //     tr.nodes([e.target]);
  //   } else if (metaPressed && isSelected) {
  //     // if we pressed keys and node was selected
  //     // we need to remove it from selection:
  //     const nodes = tr.nodes().slice(); // use slice to have new copy of array
  //     // remove node from array
  //     nodes.splice(nodes.indexOf(e.target), 1);
  //     tr.nodes(nodes);
  //   } else if (metaPressed && !isSelected) {
  //     // add the node into selection
  //     const nodes = tr.nodes().concat([e.target]);
  //     tr.nodes(nodes);
  //   }
  //   layer?.draw();
  // };

  useEffect(() => {
    if (selectedId !== null && selectedId?.length > 0) {
      let arrayOfFoundedId = [];
      layerRef?.current?.children
        ?.filter((el) => selectedId.includes(el.attrs.componenteId) === true)
        ?.forEach((elementNode) => {
          arrayOfFoundedId.push(elementNode);
        });
      trRef.current.nodes(arrayOfFoundedId);
      trRef.current.getLayer().batchDraw();
    }
  }, [selectedId]);

  const moveSelected = (movementX, movementY) => {
    let dataArray = data.content.slice();
    let element = dataArray.find(
      (element) => element.componenteId == selectedId
    );
    let currentX = element.x;
    let currentY = element.y;
    dataArray[dataArray.indexOf(element)] = {
      ...element,
      x: currentX + movementX,
      y: currentY + movementY,
    };
    addStepToHistory(dataArray);
  };

  const deleteSelected = () => {
    let dataArray = data.content.slice();
    let newArray = dataArray.filter(
      (element) => element.componenteId != selectedId
    );
    trRef.current.nodes([]);
    addStepToHistory(newArray);
  };

  const handleKeyDown = (e) => {
    var key = e.key; // keyCode detection
    var ctrl = e.ctrlKey ? e.ctrlKey : key == "Control" ? true : false; // ctrl detection
    if (key == "v" && ctrl) {
      paste(stageRef.current.getPointerPosition);
    } else if (key == "c" && ctrl) {
      copy();
    } else if (key == "z" && ctrl) {
      undo();
    }
    if (key == "ArrowRight") moveSelected(1, 0);
    if (key == "ArrowLeft") moveSelected(-1, 0);
    if (key == "ArrowUp") moveSelected(0, -1);
    if (key == "ArrowDown") moveSelected(0, 1);
    if (key == "Backspace") deleteSelected();
  };

  const copy = () => {
    setCopiedShape(
      data.content.find((element) => element.componenteId == selectedId)
    );
  };

  const paste = (position) => {
    stageRef.current.setPointersPositions(position);
    addItem(copiedShape, stageRef.current.getPointerPosition);
  };

  const addItem = (obj, position) => {
    let componenteId = +Math.random().toString().slice(12);
    let element = { ...obj };
    if (element["data"] && element.type !== "image") delete element["data"];
    let newStep = history[historyIndex].concat([
      {
        ...element,
        componenteId,
        ...position,
      },
    ]);
    addStepToHistory(newStep);
  };

  return (
    <div
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className="editor-container"
      onDrop={(e) => {
        e.preventDefault();
        stageRef.current.setPointersPositions(e);
        addItem(draggedElement, stageRef.current.getPointerPosition());
      }}
      onDragOver={(e) => e.preventDefault()}
    >
      {isEditable && onContextId && contextMenu.x && contextMenu.y && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          moverRelativo={(movement) =>
            addStepToHistory(moveInArrayRelative(movement))
          }
          moverAbsoluto={(position) =>
            addStepToHistory(moveInArrayAbsolute(position))
          }
          borrar={deleteSelected}
          copiar={() => copy()}
          pegar={(pos) => paste(pos)}
        />
      )}
      <Stage
        ref={stageRef}
        width={canvasSize.canvasWidth}
        id={id}
        height={canvasSize.canvasHeight}
        scaleX={scale / 100}
        scaleY={scale / 100}
        // onMouseDown={checkDeselect}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        // onClick={onClickTap}
        onTouchStart={checkDeselect}
        style={{ borderRadius: "20px" }}
      >
        <Layer ref={layerRef}>
          {data?.content?.map((element, i) => (
            <Element
              UUID={element.archivoNombre == "ComponenteQR.png" && guid}
              key={element.componenteId}
              type={element.type}
              shapeProps={{
                draggable: isEditable && element.isDraggable,
                ...element,
              }}
              isSelected={selectedId?.includes(element.componenteId)}
              onSelect={(e) => {
                setOnContextId(null);
                if (isEditable) {
                  selectShape([element.componenteId]);
                }
              }}
              onChange={(newAttrs) => {
                const dataArray = data.content.slice();
                dataArray[i] = newAttrs;
                addStepToHistory(dataArray);
              }}
              onContextMenu={(e) => {
                e.evt.preventDefault();
                setContextMenu({ x: e.evt.layerX, y: e.evt.layerY });
                setOnContextId(element.componenteId);
              }}
              text={
                element.reference && textRegister
                  ? textRegister[element.reference]
                  : element.text
              }
            />
          ))}

          <Transformer
            // ref={trRef.current[getKey]}
            ref={trRef}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
          <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef} />
        </Layer>
      </Stage>
    </div>
  );
};

export default CertificadoCanvas;
