import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";

function Table(props) {
  const [skeleton, setSkeleton] = useState(false);

  useEffect(() => {
    setSkeleton(props.isLoading);
  }, [props.isLoading]);

  return (
    <div className="table-container">
      {skeleton ? (
        <AnimatePresence>
          <motion.div
            className={"skeleton-parent"}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="whole-space">
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
            </div>
          </motion.div>
        </AnimatePresence>
      ) : (
        <table className={props.class}>{props.children}</table>
      )}
    </div>
  );
}

export default Table;
