import { useEffect, useState } from "react";

function useCanvasPagination(root = window, rootMargin = "0px", threshold = 0) {
  const [totalPages, setTotalPages] = useState();
  const [pageIntersected, setPageIntersected] = useState();

  const callbackFunction = (entries) => {
    entries.forEach((entry, index) => {
      if (entry.isIntersecting) {
        setPageIntersected(+index + 1);
      }
    });
  };

  const options = {
    root: document.querySelector(root),
    rootMargin,
    threshold,
  };

  const pages = Array.from(document.getElementsByClassName("editor-container"));
  const observer = new IntersectionObserver(callbackFunction, options);

  useEffect(() => {
    setTotalPages(pages.length);
  },[pages]);

  const scrollFunction=()=>{
    pages?.forEach((c) => {
      if (c) observer.observe(c);
    });
  }

  useEffect(() => {
    scrollFunction()
    document.querySelector(root).addEventListener("scroll", () => {
      pages?.forEach((c) => {
        if (c) observer.observe(c);
      });
    });
  });

  return [totalPages, pageIntersected, scrollFunction];
}

export default useCanvasPagination;
