import React, { useRef } from "react";
import Summernotereact from "./SummernoteReact";
import { useState } from "react";
import { useEffect } from "react";
import ReactSelect from "../../Components/Other/ReactSelect";

export const TextoCorto = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        <label>{props.Label}</label>
      </abbr>
      <div className={props.requerido ? "is-required" : ""}>
        {props.requerido && (
          <abbr title="Campo obligatorio">
           <i className={`material-icons required-symbol ${props.errors?"red-icon":"input-symbol"}`}></i>
          </abbr>
        )}
        <input
          ref={props.refe}
          className="form-control"
          autoComplete="off"
          value={props.value}
          name={props.nombre}
          onChange={props.onChange}
        />
      </div>
      <p className="error">{props.errors}</p>
      {props.Valor != props.ValorPorDefecto && (
        <button className="btn simple-btn" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Numerico = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        {" "}
        <label>{props.Label}</label>
      </abbr>
      <div className={props.requerido ? "is-required" : ""}>
      {props.requerido && (
        <abbr title="Campo obligatorio">
          <i className={`material-icons required-symbol ${props.errors?"red-icon":"input-symbol"}`}></i>
        </abbr>
      )}
      <input
        autoComplete="off"
        type="number"
        className="form-control"
        name={props.nombre}
        onChange={(e)=>props.onChange(+e.target.value)}
        value={props.value}
      />
      </div>
      <p className="error">{props.errors}</p>
      {props.Valor != props.ValorPorDefecto && (
        <button className="btn simple-btn" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const TextoLargo = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        {" "}
        <label>{props.Label}</label>
      </abbr>
      <div className={props.requerido ? "is-required" : ""}>
      {props.requerido && (
        <abbr title="Campo obligatorio">
            <i className={`material-icons required-symbol ${props.errors?"red-icon":"input-symbol"}`}></i>
        </abbr>
      )}
      <textarea
        autoComplete="off"
        className="form-control"
        name={props.nombre}
        onChange={props.onChange}
        value={props.value}
      />
      </div>
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="btn simple-btn" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Correo = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        {" "}
        <label>{props.Label}</label>
      </abbr>
      <div className={props.requerido ? "is-required" : ""}>
      {props.requerido && (
        <abbr title="Campo obligatorio">
           <i className={`material-icons required-symbol ${props.errors?"red-icon":"input-symbol"}`}></i>
        </abbr>
      )}
      <input
        type="email"
        autoComplete="off"
        className="form-control"
        name={props.nombre}
        onChange={props.onChange}
        value={props.value}
      />
      </div>
      <p className="error">{props.errors}</p>
      {props.Valor != props.ValorPorDefecto && (
        <button className="btn simple-btn" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};
export const Clave = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        {" "}
        <label>{props.Label}</label>
      </abbr>
      <div className={props.requerido ? "is-required" : ""}>
      {props.requerido && (
        <abbr title="Campo obligatorio">
           <i className={`material-icons required-symbol ${props.errors?"red-icon":"input-symbol"}`}></i>
        </abbr>
      )}
      <input
        type="password"
        className="form-control"
        name={props.nombre}
        onChange={props.onChange}
        value={props.value}
      />
      </div>
      <p className="error">{props.errors}</p>
      {props.Valor != props.ValorPorDefecto && (
        <button className="btn simple-btn" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Booleano = (props) => {
  const convertToBoolean = (myString) => {
    if (myString != undefined) {
      let myBool = myString.toString().toLowerCase() === "true";
      return myBool;
    }
  };

  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        <label>{props.Label}</label>
      </abbr>
      <div className={props.requerido ? "is-required" : ""}>
      {props.requerido && (
        <abbr title="Campo obligatorio">
           <i className={`material-icons required-symbol ${props.errors?"red-icon":"input-symbol"}`}></i>
        </abbr>
      )}
      <input
        onChange={props.onChange}
        type="checkbox"
        className="form-control-check.inline"
        name={props.nombre}
        checked={convertToBoolean(props.value)}
      />
      </div>
      <p className="error">{props.errors}</p>
      {props.Valor != props.ValorPorDefecto && (
        <button className="btn simple-btn" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Opciones = (props) => {
  const [val, setVal] = useState();

  useEffect(() => {
    setVal(props.valor);
  }, [props.valor]);

  function handleDefault(e) {
    setVal(e.target.value);
    props.onChange(e);
  }
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        <label>{props.Label}</label>
      </abbr>
      <div className={props.requerido ? "is-required" : ""}>
      {props.requerido && (
        <abbr title="Campo obligatorio">
           <i className={`material-icons required-symbol ${props.errors?"red-icon":"input-symbol"}`}></i>
        </abbr>
      )}
      <select
        ref={props.refe}
        className="form-control"
        onInput={props.onChange}
        value={props.value}
        name={props.nombre}
      >
        <option value={""}>Seleccione</option>
        {props.Opciones?.map((item, i) => {
          return (
            //selected={props.valor == item.id}
            <option key={i} value={item.Id}>
              {item.Opcion}
            </option>
          );
        })}
      </select>
      </div>
      <p className="error">{props.errors}</p>
      {props.Valor != props.ValorPorDefecto && (
        <button className="btn simple-btn" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Editor = (props) => {
  return (
    <div className="editorContainerModal form-group">
      <abbr title={props.descripcion}>
        <label>{props.Label}</label>
      </abbr>
      {props.requerido && (
        <abbr title="Campo obligatorio">
          <i className="material-icons important"></i>
        </abbr>
      )}
      <div className="divEditorModal">
        <Summernotereact
          name={props.nombre}
          rules={props.rules}
          isEditing={props.value != props.ValorPorDefecto}
          control={props.control}
          {...props}
        />
        <p className="error">{props.errors}</p>
        {props.Valor != props.ValorPorDefecto && (
          <button className="btn simple-btn" onClick={props.handleDefault}>
            Restablecer al valor por defecto
          </button>
        )}
      </div>
    </div>
  );
};

export const Radio = (props) => {
  const convertToBoolean = (myString) => {
    if (myString != undefined) {
      let myBool = myString.toString().toLowerCase() === "true";
      return myBool;
    }
  };

  const isValid = (e) => {
    return typeof convertToBoolean(e) == "boolean";
  };

  const [val, setVal] = useState();

  useEffect(() => {
    setVal(convertToBoolean(props.valor));
  }, [props.valor]);

  function handleDefault(e) {
    setVal(convertToBoolean(e.target.value));
    props.onChange(e);
  }

  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        <label>{props.Label}</label>
      </abbr>
      <div className={props.requerido ? "is-required" : ""}>
      {props.requerido && (
        <abbr title="Campo obligatorio">
            <i className={`material-icons required-symbol ${props.errors?"red-icon":"input-symbol"}`}></i>
        </abbr>
      )}
      <div className="check-container">
        <div className="form-control-check inline">
          <input
            name={props.nombre}
            onChange={handleDefault}
            type="radio"
            value={true}
            checked={val == true}
          />
          <label>Si</label>
        </div>
        <div className="form-control-check inline">
          <input
            type="radio"
            value={false}
            onChange={handleDefault}
            name={props.nombre}
            checked={val == false}
          />
          <label>No</label>
        </div>
      </div>
      </div>
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="btn simple-btn" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const MultiOpciones = ({
  setValue,
  nombre,
  opciones,
  requerido,
  label,
  onChange,
  descripcion,
  control,
  valor,
  valorPorDefecto,
  placeholder,
  errors,
  handleDefault,
}) => {
  const [options, setOpciones] = useState();
  useEffect(() => {
    setOpciones(opciones);
  }, [opciones]);

  return (
    <div className="form-group">
      <abbr title={descripcion}>
        <label>{label}</label>
      </abbr>
      {requerido && (
        <abbr title="Campo obligatorio">
           <i className={`material-icons required-symbol ${errors?"red-icon":"input-symbol"}`}></i>
        </abbr>
      )}
        <ReactSelect
          onChange={(val) => onChange(val.map((c) => c.value))}
          name={nombre}
          value={opciones?.filter((c) => valor?.includes(c.value))}
          options={opciones}
          isMulti={true}
          isValid={errors == undefined}
          isRequired={requerido}
        />
        <p className="error">{errors}</p>
        {valor != valorPorDefecto && (
          <button className="btn simple-btn" onClick={handleDefault}>
            Restablecer al valor por defecto
          </button>
        )}
      </div>
  );
};
