import React, { useState, useEffect } from "react";
import HomeImg from "../IMG/LookingAtDiploma.svg";
import GirlSitting from "../IMG/GirlSitting.svg";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Card from "../Components/Other/Card";
import emptyImg from "../IMG/Empty1.svg";
import Mosaic from "../Components/DataBooks/Mosaic";
import Carousel from "../Components/Other/Carousel";
import { Link } from "react-router-dom";
import {
  obtenerTopPlantillas,
  obtenerTopPlantillasProyectos,
} from "../Redux/reducers/plantillasSlice";
import WebApi from "../Api/WebApi";
function Inicio() {
  const [isLoading, setIsLoading] = useState();
  const [plantillasTop, setPlantillasTop] = useState([]);
  const [plantillasProyectos, setPlantillasProyectos] = useState([]);

  const dispatch = useDispatch();
  const { topPlantillas, plantillasProyectosRecientes } = useSelector(
    (state) => state.plantillas
  );
  const navigate = useNavigate();
  const fetchDataTopPlantilla = async (urls) => {
    try {
      const responses = await Promise.all(
        urls.map(async (url) => {
          const response = await WebApi.api().get(url);
          return response.data;
        })
      );
      const updatedData = topPlantillas?.map((item) => {
        const response = responses.find(
          (res) => res.Result.FileId === item.FileId
        );
        return {
          ...item,
          ...response?.Result,
        };
      });
      setPlantillasTop(updatedData);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchDataPlantillasProyectos = async (urls) => {
    try {
      const responses = await Promise.all(
        urls.map(async (url) => {
          const response = await WebApi.api().get(url);
          return response.data;
        })
      );
      const updatedData = plantillasProyectosRecientes?.map((item) => {
        const response = responses.find(
          (res) => res.Result.FileId === item.FileId
        );
        return {
          ...item,
          ...response?.Result,
        };
      });
      setPlantillasProyectos(updatedData);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    cargarDatos();
  }, []);

  useEffect(() => {
    if ((plantillasTop.length > 0) & (plantillasProyectos !== null))
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
  }, [plantillasProyectos, plantillasTop]);

  useEffect(() => {
    if (topPlantillas?.length > 0) {
      const urls = topPlantillas?.map(
        (plantilla) =>
          `${process.env.REACT_APP_BASEURL}Files/${plantilla.FileId}`
      );
      fetchDataTopPlantilla(urls);
    }
  }, [topPlantillas]);
  useEffect(() => {
    if (plantillasProyectosRecientes?.length > 0) {
      const urls = plantillasProyectosRecientes?.map(
        (plantilla) =>
          `${process.env.REACT_APP_BASEURL}Files/${plantilla.FileId}`
      );
      fetchDataPlantillasProyectos(urls);
    }
  }, [plantillasProyectosRecientes]);

  const cargarDatos = () => {
    dispatch(obtenerTopPlantillasProyectos());
    dispatch(obtenerTopPlantillas());
  };

  const renderProjectsMosaics = () => {
    let grid = plantillasProyectos?.map((item) => (
      <button
        key={item.PlantillaCertificadoId}
        className="btn"
        onClick={() =>
          navigate(`/edicion-plantilla/${item.PlantillaCertificadoId}`)
        }
      >
        <Mosaic imagen={item.Data} editar={null} borrar={null} />
      </button>
    ));
    return grid;
  };
  const renderTemplatesMosaics = () => {
    let grid = plantillasTop?.map((item) => (
      <button
        key={item.PlantillaCertificadoId}
        className="btn"
        onClick={() =>
          navigate(`/edicion-plantilla/${item.PlantillaCertificadoId}`)
        }
      >
        <Mosaic imagen={item.Data} editar={null} borrar={null} />
      </button>
    ));
    return grid;
  };

  return (
    <motion.main
      className="home-page"
      id="main"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Card classN="flex-container home-cover" isLoading={isLoading}>
        <img
          src={GirlSitting}
          alt="Ilustracion inicio"
          className="cover-backing"
          hidden={isLoading}
        />
        <div>
          <h2 className="blue-text">Diseña y personaliza en minutos</h2>
          <p className="dark-text">
            Elije una plantilla prediseñada, y crea certificados, diplomas o
            premios sin importar la cantidad.
          </p>
        </div>
        <div>
          <img
            src={HomeImg}
            alt="ilustracion mirando hacia certificado"
            className="is-hidden-on-mobile"
          />
        </div>
      </Card>
      <div>
        <Link to="plantillas" className="home-carousel-link">
          Ver todas las plantillas
        </Link>
        <Carousel
          isLoading={isLoading}
          isNotShowing={plantillasTop?.length === 0}
          title="Plantillas creadas recientemente"
          long={1000}
        >
          {plantillasTop?.length > 0 ? (
            renderTemplatesMosaics()
          ) : (
            <div className="container-col-75">
              <div className="flex-container">
                <div className="container-col-50">
                  <img src={emptyImg} alt="Ilustración desierto" />
                </div>
                <h4>No has utilizado una plantilla aún</h4>
              </div>
              <button className="btn btn-alternative home-btn-start">
                Empezar a crear
              </button>
            </div>
          )}
        </Carousel>
      </div>
      <div>
        <Link to="certificados" className="home-carousel-link">
          Ver todos los proyectos{" "}
        </Link>
        <Carousel
          isLoading={isLoading}
          isNotShowing={plantillasProyectos?.length === 0}
          title="Plantillas utilizadas en proyectos"
          long={1000}
        >
          {plantillasProyectos?.length > 0 ? (
            renderProjectsMosaics()
          ) : (
            <div className="container-col-75">
              <div className="flex-container">
                <div className="container-col-50">
                  <img src={emptyImg} alt="Ilustración desierto" />
                </div>
                <h4>No has creado un proyecto aún</h4>
              </div>
              <button className="btn btn-alternative home-btn-start">
                Empezar a crear
              </button>
            </div>
          )}
        </Carousel>
      </div>
    </motion.main>
  );
}

export default Inicio;
