import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ContextActions from "../Components/DataBooks/ContextActions";
import Options from "../Components/DataBooks/Options";
import PageActions from "../Components/DataBooks/PageActions";
import Form from "../Components/Form/Form";
import Card from "../Components/Other/Card";
import Modal from "../Components/Other/Modal";
import ModalRemove from "../Components/Other/ModalRemove";
import usePagination from "../Hooks/usePagination";
import NoResults from "../IMG/NoResults.svg";
import {
  actualizarEmpresa,
  agregarEmpresa,
  eliminarEmpresa,
  obtenerEmpresas,
} from "../Redux/reducers/empresasSlice";
import useSubmit from "../Hooks/useSubmit";
import FormToast from "../Components/Form/FormToast";
import MosaicData from "../Components/DataBooks/MosaicData";

export default function ListEmpresas() {
  const [showModal, setShowModal] = useState();
  const [removingItem, setRemovingItem] = useState(null);
  const [dataEdit, setDataEdit] = useState({});
  const [data, setData] = useState();
  const [wayOfDisplayData, setWayOfDisplayData] = useState(2);
  const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const dispatch = useDispatch();

  const { onSubmit, sendingData, successCode } = useSubmit(
    dataEdit?.EmpresaId,
    agregarEmpresa,
    actualizarEmpresa
  );

  const { empresas, isLoading } = useSelector((state) => state.empresas);

  const modelForm = {
    EmpresaNombre: {
      label: "Nombre",
      type: "text",
      placeholder: "Nombre",
      required: true,
      rules: {
        required: true,
      },
    },
    Direccion: {
      type: "text",
      label: "Dirección",
      placeholder: "Dirección",
      required: true,
      rules: {
        required: true,
      },
    },
    RNC: {
      label: "RNC",
      type: "text",
      placeholder: "RNC",
      required: true,
      rules: {
        required: true,
      },
    },
    Telefono: {
      label: "Teléfono",
      type: "text",
      placeholder: "No. telefónico",
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //ESLINT
    displayPage,
  ] = usePagination(registrosPorPagina, empresas.length);

  useEffect(() => {
    setData(empresas?.slice(startPageIndex, endPageIndex));
  }, [startPageIndex, endPageIndex, empresas]);

  useEffect(() => {
    cargarDatos();
  }, [successCode]);

  const cargarDatos = (nombre = null) => {
    dispatch(obtenerEmpresas({ name: nombre }));
    setShowModal(false);
  };

  const eliminar = async () => {
    let { payload } = await dispatch(eliminarEmpresa({ ...removingItem }));
    if (payload.success) {
      setDeleteSuccess(true);
      setTimeout(() => {
        setRemovingItem(null);
        setDeleteSuccess(false);
      }, 2000);
      cargarDatos();
    }
  };

  const editModal = (item) => {
    setDataEdit(item);
    setShowModal(true);
  };

  const renderTable = () => {
    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>Nombre de la empresa</th>
              <th>Dirección</th>
              <th>RNC</th>
              <th>Teléfono</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr key={item.EmpresaId}>
                <td>{item.EmpresaNombre}</td>
                <td>{item.Direccion}</td>
                <td>{item.RNC}</td>
                <td>{item.Telefono}</td>
                <td>
                  <Options
                    actions={[
                      {
                        onclick: () => editModal(item),
                        icon: <i className="Material-Icons-Outlined"></i>,
                        text: "editar",
                      },
                      {
                        onclick: () => setRemovingItem(item),
                        icon: <i className="Material-Icons-Outlined"></i>,
                        text: "eliminar",
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <FormToast
        code={successCode !== 0 && successCode}
        title={successCode === 1 ? "¡Bien!" : "Algo anda mal"}
        desc={
          successCode === 1
            ? "Datos almacenados correctamente"
            : "Revisa los datos e intenta de nuevo"
        }
      />
      <Modal isVisible={showModal} title={dataEdit ? "Editar" : "Agregar"}>
        <Form
          sendingData={sendingData}
          dynamicForm={modelForm}
          onSubmit={onSubmit}
          onRecoil={() => {
            setShowModal(false);
          }}
          dataToEdit={dataEdit}
        />
      </Modal>

      <ContextActions
        btnNew="Nueva empresa"
        onClick={() => {
          editModal(null);
        }}
        searchFunction={cargarDatos}
        wayOfDisplayData={wayOfDisplayData}
        setWayOfDisplayData={setWayOfDisplayData}
        logs="Empresas"
        showAll={false}
      />

      <ModalRemove
        success={deleteSuccess}
        title="Eliminar empresa"
        onRecoil={() => setRemovingItem(null)}
        onSubmit={() => eliminar()}
        isVisible={removingItem !== null}
        recordName={removingItem?.EmpresaNombre}
      />

      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card isLoading={isLoading} type={"table"}>
          <h2 className="dark-text table-section-name">Empresas</h2>
          {!data?.length > 0 ? (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          ) : wayOfDisplayData === 1 ? (
            <MosaicData
              data={data}
              idField="EmpresaId"
              titleField="EmpresaNombre"
              editar={(id) => editModal(data.find((e) => e.EmpresaId == id))}
              borrar={(item) => setRemovingItem(item)}
            />
          ) : (
            <div className="table-container">{renderTable()}</div>
          )}
          <PageActions
            count={totalPages} //PageCount
            onChange={(value) => displayPage(value)}
            changeRegistersCount={(value) => setRegistrosPorPagina(value)}
            registersCount={registrosPorPagina} //RowsCount
          />
        </Card>
      </motion.main>
    </>
  );
}
