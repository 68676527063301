import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import useSubmit from "../../../Hooks/useSubmit";
import {
  actualizarDatosCertificado,
  almacenarDatosCertificado,
  eliminarConfiguracionDatosCertificado,
} from "../../../Redux/reducers/datosCertificadoSlice";
import Form from "../../../Components/Form/Form";
import FormToast from "../../../Components/Form/FormToast";
import ModalRemove from "../../../Components/Other/ModalRemove";
import DataToolbar from "./DataToolbar";
import Modal from "../../../Components/Other/Modal";
import Table from "../../../Components/DataBooks/Table";

function DatosPlantillas() {
  const [isRemovingItem, setIsRemovingItem] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [settingsPreview, setSettingsPreview] = useState(undefined);
  const [isModalVisible,setIsModalVisible]=useState(false);
  const [isLoading, setIsLoading] = useState();
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  
  const { reset } = useForm();
  const dispatch = useDispatch();
  const headers = [
    {id:1,name:"Referencia/ clave del campo"},
    {id:2,name:"Nombre del campo"},
    {id:3,name:"Tipo de dato"},
    {id:4,name:"¿Es obligatorio?"}
  ];
  const { onSubmit,sendingData,successCode } = useSubmit(
    isEditing,
    almacenarDatosCertificado,
    actualizarDatosCertificado
  );
  useEffect(()=>{
    if(isEditing)
    setIsModalVisible(true)
  },[isEditing])

  useEffect(()=>{
    if(settingsPreview==undefined){
      setIsLoading(true)
    }
    else{
      setIsLoading(false)
    }
  },[settingsPreview])

  const eliminar = (id) => {
    dispatch(eliminarConfiguracionDatosCertificado(id));
    setDeleteSuccess(true);
    setTimeout(() => {
      setIsRemovingItem(false);
      setDeleteSuccess(false);
    }, 2000);

  };

  const FieldsDataForm = {
    ConfiguracionDatoNombre: {
      label: "Nombre de la configuración",
      type: "text",
      required: true,
      rules: {
        required: true,
      },
    },
    dynamicFieldArray: {
      Clave: {
        label: "Referencia del campo",
        type: "text",
        defaultValue: "",
        required: true,
        rules: {
          required: true,
        },
      },
      Label: {
        label: "Nombre del campo",
        type: "text",
        defaultValue: "",
        required: true,
        rules: {
          required: true,
        },
      },
      TipoDatoId: {
        label: "Tipo de dato",
        type: "dropdown",
        defaultValue: "",
        options: [
          { id: 1, name: "Texto" },
          { id: 10, name: "Numérico" },
          { id: 2, name: "Texto largo" },
          { id: 3, name: "Email" },
          { id: 4, name: "Fecha" },
          { id: 5, name: "Opciones" },
          { id: 7, name: "Check" },
          { id: 8, name: "Archivo" },
        ],
        optionIdName: "id",
        optionValueName: "name",
        required: true,
        rules: {
          required: true,
        },
      },
      EsObligatorio: {
        label: "¿Es obligatorio?",
        type: "checkbox",
        defaultValue: false,
        required: false,
      },
    },
  };


  const renderTable = () => {
    return (
      <Table
        class="table display dataTable" isLoading={isLoading}
      >
        <thead>
          <tr>
            {headers?.map((header) => (
              <th key={header.id}>{header.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {settingsPreview?.dynamicFieldArray && settingsPreview.dynamicFieldArray?.map((item,index) => (
            <tr key={item.Id}>
              <td>{item.Clave}</td>
              <td>{item.Label}</td>
              <td>{item.TipoDatoNombre}</td>
              <td>{item.EsObligatorio? <i className='material-icons blue-text'></i>: <i className='material-icons'></i>}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <AnimatePresence>
      <div className="form-title" key="dataSettingsOption">
        <br />
        <br />
        <button
          className="btn btn-primary settings-right-superior-container"
          onClick={() => setIsModalVisible(true)}
        >
          Nueva configuración
        </button>
      </div>
      <motion.div className="flex-container data-settings-container" key="dataSettingContainer">
        <section
          transition={{
            duration: 2,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className="sub-menu-container palette"
        >
          <div>
            <DataToolbar setSettingsPreview={setSettingsPreview} reload={successCode}/>
          </div>
        </section>
        {settingsPreview !== undefined && (
          <div>
            <div className="editor-data-settings-container">
              <div className="editor-data-settings">
                <div className="flex-container">
                  <button
                    className="btn is-visible-on-mobile is-visible-on-tablet"
                    onClick={() => setSettingsPreview("0")}
                  >
                    <i className="material-icons"></i>
                  </button>
                  <h3>{settingsPreview?.ConfiguracionDatoNombre}</h3>
                </div>
                {renderTable()}
                <div className="flex-container form-btn-group">
                  <button
                    className="btn btn-alternative"
                    onClick={() => setIsEditing(true)}
                  >
                    Editar
                  </button>
                  <button
                    className="btn btn-alternative"
                    onClick={() => setIsRemovingItem(true)}
                  >
                    Eliminar
                  </button>
                </div>
                <ModalRemove
                      success={deleteSuccess}
                  onRecoil={() => setIsRemovingItem(false)}
                  title="Eliminar configuración de datos"
                  onSubmit={() => eliminar(settingsPreview?.ConfiguracionDatoId)}
                  isVisible={isRemovingItem}
                  recordName={settingsPreview?.ConfiguracionDatoNombre}
                />
              </div>
                <Modal isVisible={isModalVisible} title={isEditing
                      ? "Editar configuración de datos"
                      : "Nueva configuración de datos"}>
                  <Form
                    dynamicForm={FieldsDataForm}
                    btnAddToArray="Agregar campo"
                    onSubmit={(data) => onSubmit(data)}
                    successCode={successCode}
                    isEditing={isEditing}
                    dataToEdit={isEditing && settingsPreview}
                    onRecoil={() => {
                      reset({});
                      setIsEditing(false);
                      setIsModalVisible(false);
                    }}
                    sendingData={sendingData}
                  />
                  <FormToast
                    code={successCode}
                    title={successCode == 1 ? "¡Bien!" : "Algo anda mal"}
                    desc={
                      successCode == 1
                        ? "Datos almacenados correctamente"
                        : "Revisa los datos e intenta de nuevo"
                    }
                  />
                </Modal>
            </div>
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default DatosPlantillas;
