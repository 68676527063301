import React, { useEffect, useState } from "react";
import { obtenerProyectos } from "../Redux/reducers/proyectosSlice";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import Card from "../Components/Other/Card";
import ContextActions from "../Components/DataBooks/ContextActions";
import usePagination from "../Hooks/usePagination";
import PageActions from "../Components/DataBooks/PageActions";
import NoResults from "../IMG/NoResults.svg";
import MosaicData from "../Components/DataBooks/MosaicData";
import Options from "../Components/DataBooks/Options";
import Modal from "../Components/Other/Modal";
import jsPDF from "jspdf";
import WebApi from "../Api/WebApi";

export default function ListaCertificados() {
  const [wayOfDisplayData, setWayOfDisplayData] = useState(1);
  const [data, setData] = useState();
  const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
  const dispatch = useDispatch();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [imgSelected, setImgSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const { proyectos  } = useSelector((state) => state.proyectos);
  const [page, setPage] = useState(1);

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //ESLINT
    displayPage,
  ] = usePagination(registrosPorPagina, proyectos[0]?.PageCount);
  const fetchData = async (urls) => {
    try {
      const responses = await Promise.all(
        urls.map(async (url) => {
          const response = await WebApi.api().get(url);
          return response.data;
        })
      );
      const updatedData = proyectos?.map((item) => {
        const response = responses.find(
          (res) => res.Result.FileId === item.FileId
        );
        return {
          ...item,
          Certificado: response?.Result.Data,
        };
      });
      setData(updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (proyectos.length > 0) {
      const urls = proyectos?.map(
        (proyecto) => `${process.env.REACT_APP_BASEURL}Files/${proyecto.FileId}`
      );
      fetchData(urls);
    }
  }, [proyectos]);

  useEffect(() => {
    setData(proyectos?.slice(startPageIndex, endPageIndex));
  }, [startPageIndex, endPageIndex]);

  useEffect(() => {
    setLoading(true);
    cargarDatos();
  }, [page]);

  const cargarDatos = (CertificadoNombre = "") => {
    dispatch(
      obtenerProyectos({
        CertificadoNombre: CertificadoNombre,
        Page: page,
        RowsCount: registrosPorPagina,
      })
    );
  };

  const addImgToPdf = () => {
    var pdf = new jsPDF("l", "px", [680, 420]);
    pdf.addImage(imgSelected, 0, 0, 680, 420);
    pdf.save("Certificado.pdf");
  };

  const renderTable = () => {
    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>Previsualización</th>
              <th>Nombre</th>
              <th>Creado por</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr key={item.CertificadoId}>
                <td>
                  <img
                    alt="Previsualización de plantilla"
                    src={item.Certificado}
                  ></img>
                </td>
                <td>{item.Nombre}</td>
                <td>{item.CreadoPor}</td>
                <td>
                  <Options
                    actions={[
                      {
                        icon: <i className="Material-Icons-Outlined"></i>,
                        text: "Ver",
                        onclick: () => {
                          setImgSelected(item.Certificado);
                          setModalVisibility(true);
                        },
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <Modal
        isVisible={modalVisibility}
        title="Vista previa de certificado"
        bottomItems={
          <div className="form-btn-group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={addImgToPdf}
            >
              Descargar como pdf
            </button>

            <button
              type="button"
              className="btn btn-alternative"
              onClick={() => setModalVisibility(false)}
            >
              Cerrar
            </button>
          </div>
        }
      >
        <div>
          <img
            className="guid-view-img"
            src={imgSelected}
            alt="Imagen del certificado seleccionado"
          />
        </div>
      </Modal>
      <ContextActions
        searchFunction={(n) => cargarDatos(n)}
        setWayOfDisplayData={setWayOfDisplayData}
        wayOfDisplayData={wayOfDisplayData}
      />
      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card
          classN="card"
          isLoading={loading}
          type={wayOfDisplayData === 1 ? "mosaic" : "table"}
        >
          <h2 className="dark-text section-name">Tus certificados</h2>
          {!data?.length > 0 ? (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          ) : wayOfDisplayData === 1 ? (
            <MosaicData
              data={data}
              imageField="Certificado"
              titleField="Nombre"
              actions={(item) => [
                {
                  icon: <i className="Material-Icons-Outlined"></i>,
                  text: "Ver",
                  onclick: () => {
                    setImgSelected(item.Certificado);
                    setModalVisibility(true);
                  },
                },
              ]}
            />
          ) : (
            renderTable()
          )}
          <PageActions
            count={totalPages}
            onChange={(value) => {
              displayPage(value);
              setPage(value);
            }}
            changeRegistersCount={(value) => setRegistrosPorPagina(value)}
            registersCount={registrosPorPagina}
          />
        </Card>
      </motion.main>
    </>
  );
}
