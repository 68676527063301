import { AnimatePresence, motion } from "framer-motion";
import React from "react";


function PaletteMenu({
  isSubmenuActive,
  content,
}) {

  return (
    <AnimatePresence>
      <motion.div className="flex-container" id="overlay-container">
        <section
          transition={{
            duration: 2,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className={
            !isSubmenuActive
              ? "sub-menu-container is-hidden palette"
              : "sub-menu-container palette"
          }
        >
          <div>{content}</div>
        </section>
      </motion.div>
    </AnimatePresence>
  );
}

export default PaletteMenu;
