import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useComponentVisible from "../../Hooks/useComponentVisible";

function ContextActions(props) {
  const [viewOptions, setViewOptions] = useState(false);
  const { ref, isComponentVisible } = useComponentVisible(viewOptions);

  const navigate = useNavigate();

  return (
    <>
      <div className="flex-container context-actions-container">
        <input
          className="registers-input-search"
          type="search"
          placeholder="Búsqueda..."
          onChange={(e) => props.searchFunction(e.target.value)}
        />
        {props.btnNew && (
          <button
            className="btn registers-btn-action"
            onClick={() =>
              props.onClick ? props.onClick() : navigate(props.pathToNew)
            }
          >
            <i className="material-icons"></i>
            {props.btnNew}
          </button>
        )}
        {props.logs && (
          <button
            className="btn registers-btn-action"
            onClick={() => {
              navigate(`./logs/${props.logs}`);
            }}
          >
            Cambios realizados
          </button>
        )}
        <button
          ref={ref}
          className="btn registers-btn-action icon-btn-on-mobile"
          id="btn-change-view"
          onClick={() => setViewOptions(!viewOptions)}
        >
          {props.wayOfDisplayData == 2 ? (
            <i className="material-icons"></i>
          ) : (
            <i className="Material-Icons-Outlined"></i>
          )}
          <i className="material-icons"></i>
        </button>
        <div
          ref={ref}
          className={
            isComponentVisible
              ? "view-options-container"
              : "view-options-container is-hidden"
          }
        >
          <button
            className="btn dark-text"
            onClick={() => props.setWayOfDisplayData(2)}
          >
            <i className="material-icons"></i> Lista
          </button>
          <button
            className="btn dark-text"
            onClick={() => props.setWayOfDisplayData(1)}
          >
            <i className="Material-Icons-Outlined"></i> Mosaico
          </button>
        </div>
      </div>
    </>
  );
}

export default ContextActions;
