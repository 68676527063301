
function usePositionOnArray(array, elementId) {

    const moveInArrayRelative = (movementInLayer) => {
        let dataArray = array;
        let element = dataArray.find((element) => element.id == elementId);
        let index = dataArray.indexOf(element);
        var item = dataArray.splice(index, 1);
    
        dataArray.splice(index + movementInLayer, 0, item[0]);
        return dataArray;
      };
    
      const moveInArrayAbsolute = (position = null) => {
        let dataArray = array;
        let lastPosition = dataArray.length - 1;
        let element = dataArray.find((element) => element.id == elementId);
        let index = dataArray.indexOf(element);
        var item = dataArray.splice(index, 1);
    
        dataArray.splice(position != null ? position : lastPosition, 0, item[0]);
        return dataArray;
      };

  return {moveInArrayAbsolute,moveInArrayRelative}
}

export default usePositionOnArray