import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormButtons from "../../../Components/Form/FormButtons";
import { FormInput } from "../../../Components/Form/FormElements";
import FormToast from "../../../Components/Form/FormToast";
import Modal from "../../../Components/Other/Modal";
import useSubmit from "../../../Hooks/useSubmit";
import {
  actualizarPlantillaConfig,
  almacenarPlantillaConfig,
  obtenerTiposPlantillasConfig,
} from "../../../Redux/reducers/plantillasConfigSlice";
import SummerNoteReactV from "./SummerNoteReactV";

function FormPlantillas({
  plantillaDatos,
  isVisible,
  close,
  isEditing,
  reload,
  setCode,
}) {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const { onSubmit, sendingData,successCode } = useSubmit(
    isEditing,
    almacenarPlantillaConfig,
    actualizarPlantillaConfig
  );

  const tipoPlantillaId = watch("TipoPlantillaId");
  const [variables = [], setVariables] = useState([]);
  const { plantillasConfigTipos } = useSelector((state) => state.plantillasConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    if (successCode == 1) {
      reset({});
      setTimeout(()=>{
        close();
        reload();
      },1000)
    }
  }, [successCode]);

  useEffect(() => {
    reset(plantillaDatos);
  }, [plantillaDatos]);

  useEffect(() => {
    dispatch(obtenerTiposPlantillasConfig());
  }, []);

  useEffect(() => {
    let indexHeader, indexMenu, indexNavigation;
    if (isVisible) {
      indexHeader = indexMenu = indexNavigation = 0;
    } else {
      indexHeader = 10;
      indexMenu = 7;
      indexNavigation = 3;
    }
    document
      .getElementById("header")
      .setAttribute("style", `z-index: ${indexHeader}`);
    document
      .querySelector(".vertical-menu")
      .setAttribute("style", `z-index: ${indexMenu}`);
    document
      .getElementById("navigation-tab")
      .setAttribute("style", `z-index: ${indexNavigation}`);
  }, [isVisible]);

  useEffect(() => {
    let a = plantillasConfigTipos?.find((x) => x.TipoPlantillaId == tipoPlantillaId);
    if (a && a.Variables !== undefined) {
      setVariables(JSON.parse(a.Variables));
    }
  }, [tipoPlantillaId]);

  const form = {
    PlantillaNombre: {
      label: "Nombre de la plantilla",
      type: "text",
      placeholder: "Nombre",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
    TipoPlantillaId: {
      label: "Tipo",
      type: "dropdown",
      options: plantillasConfigTipos,
      optionIdName: "TipoPlantillaId",
      optionValueName: "TipoPlantillaNombre",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
    Asunto: {
      label: "Asunto",
      type: "text",
      placeholder: "Asunto",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const formInputs = Object.keys(form).map((e) => {
    const { rules, defaultValue } = form[e];
    return (
      <section key={e}>
        <Controller
          name={e}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <FormInput
                error={errors[e] && "Este campo es requerido"}
                value={field.value}
                onChange={field.onChange}
                {...form[e]}
              />
            </div>
          )}
        />
      </section>
    );
  });

  return (
    <Modal
      isVisible={isVisible}
      title={isEditing ? "Edición de plantilla" : "Nueva plantilla"}
    >
      <form>
        {formInputs}

        <SummerNoteReactV
          control={control}
          name="Cuerpo"
          rules={{ required: "Campo requerido" }}
          isEditing={isEditing}
          val={isEditing ? plantillaDatos.Cuerpo : undefined}
          lista={variables}
        />

        {errors.cuerpo && <p className="error">{errors.cuerpo.message}</p>}

        <FormButtons
          onProgress={handleSubmit(onSubmit)}
          progressAction="Guardar"
          isSendingRequest={sendingData}
          onRecoil={(e) => {
            reset({});
            close(e);
          }}
          recoilAction="Cancelar"
        />
         <FormToast
                    code={successCode}
                    title={successCode==1?"¡Bien!":"Algo anda mal"}
                    desc={successCode==1?"Plantilla almacenada":"Revisa los datos e intenta de nuevo"}
                  />
      </form>
    </Modal>
  );
}

export default FormPlantillas;
