import React, { useState } from "react";
import  { read, utils,write, writeFile} from "xlsx";

function useParseExcel() {

  const [jsonResult, setJsonResult]=useState()

  const handleFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet
      // , {
      // header: 1,
      // defval: "",
      // }
      );
    setJsonResult(jsonData)
  };

  const downloadTemplate=(data, name)=>{
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet);
    writeFile(workbook,`${name}.xlsx`)
  }


  return {downloadTemplate, handleFile, jsonResult};
}

export default useParseExcel;
