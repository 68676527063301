import React, { useRef, useState } from "react";
import imagotipo from "../../IMG/Imagotipo.svg";
import isotipo from "../../IMG/Isotipo.svg";
import MenuItem from "../Layout/MenuItem";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const menu = [
  {
    id: 1,
    nombre: "Plantillas",
    icon: <i className="Material-Icons-Outlined"></i>,
  },
  {
    id: 2,
    nombre: "Imágenes",
    icon: <i className="Material-Icons-Outlined"></i>,
  },
  {
    id: 3,
    nombre: "Textos",
    icon: <i className="Material-Icons-Outlined"></i>,
  },
  {
    id: 4,
    nombre: "Formas",
    icon: <i className="Material-Icons-Outlined"></i>,
  },
  {
    id: 5,
    nombre: "Código QR",
    icon: <i className='material-icons'></i>,
  },
];

function Palette({ isOpened, paletteMenu, disabled }) {
  const subMenuButton = useRef();
  const [submenuActive, setSubmenuActive] = useState(1);
  const navigate = useNavigate();

  const getClassButtonMenu = (id) => {
    return id === submenuActive
      ? "btn main-menu-button  sub-menu-trigger is-selected menu-canvas"
      : "btn main-menu-button sub-menu-trigger";
  };

  return (
    <>
      <div
        id="fake-vertical-menu"
        className={
          isOpened ? "is-hidden-on-mobile" : "is-closed is-hidden-on-mobile"
        }
      ></div>
      <nav className={!isOpened ? "vertical-menu is-closed" : "vertical-menu"}>
        <div className="logo-container">
          {!isOpened ? (
            <img src={isotipo} alt="Logo empresa" onClick={()=>navigate("/")}/>
          ) : (
            <img src={imagotipo} alt="Logo empresa" onClick={()=>navigate("/")}/>
          )}
        </div>
        <div className="options-container">
          <AnimatePresence>
            <motion.section className="main-options">

              <div className="is-hidden-on-mobile">
                <ul>
                  {menu.map((item, i) => (
                    <li key={item.id}>
                      <button
                        ref={subMenuButton}
                        onClick={() => setSubmenuActive(item.id)}
                        className={getClassButtonMenu(item.id)}
                        disabled={i < 4 && disabled}
                      >
                        {item.icon}
                        <span>{item.nombre}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </motion.section>
          </AnimatePresence>
          <div className="is-hidden-on-mobile">
          {paletteMenu(submenuActive)}
          </div>
        </div>
      </nav>
    </>
  );
}

const PaletteMovil = ({disabled, paletteMenu}) => {
  const subMenuButton = useRef();
  const [submenuActive, setSubmenuActive] = useState(1);

  const getClassButtonMenu = (id) => {
    return id === submenuActive
      ? "btn main-menu-button  sub-menu-trigger is-selected menu-canvas"
      : "btn main-menu-button sub-menu-trigger";
  };

  return (
    <div className="is-visible-on-mobile is-visible-on-tablet">
    <div className="mobile-palette-container">
      <ul>
        {menu.map((item, i) => (
          <li key={item.id}>
            <button
              ref={subMenuButton}
              onClick={() => setSubmenuActive(item.id)}
              className={getClassButtonMenu(item.id)}
              disabled={i < 4 && disabled}
            >
              {item.icon}
              <span>{item.nombre}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
    {paletteMenu(submenuActive)}
    </div>
  );
};

export default Palette;
export {PaletteMovil};
