import axios from "axios";
import jwtDecode from "jwt-decode";
import eventBus from "../services/EventBus";

export default (function WebApi() {
  function api() {
    let token = localStorage.getItem("token");
    if (token == null) {
      return axios.create({
        baseURL: process.env.REACT_APP_BASEURL,
      });
    } else {
      const x = axios.create({
        baseURL: process.env.REACT_APP_BASEURL,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (token) {
        verifyTokenExpiration(token);
      }
      return x;
    }
  }

  return { api };
})();

function verifyTokenExpiration(token) {
  let refrToken = JSON.parse(localStorage.getItem("refreshToken"));
  let fechaHoy = Math.floor(new Date().getTime() / 1000.0);
  let decodedToken = jwtDecode(token);
  let tiempoExpiracion = decodedToken.exp;

  if (tiempoExpiracion - fechaHoy < 120) {
    let data = { refreshToken: refrToken.Token };
    axios
      .post(`${process.env.REACT_APP_BASEURL}Refresh`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          localStorage.setItem("token", res.data.result.result.result.token);
        } else {
          //log out
          eventBus.dispatch("logout");
        }
      })
      .catch((err) => {
        eventBus.dispatch("logout");
      });
  }
}
