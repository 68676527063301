import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerFuentesLetra = createAsyncThunk(
  "fuentesLetra/obtenerFuentesLetra",
  async (nombre, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("ArchivosFuentes/Obtener");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);


export const almacenarFuenteLetra = createAsyncThunk(
  "fuentesLetra/almacenarFuenteLetra",
  async (data, { rejectWithValue }) => {
    try {
      data.Fuente.ContentType=data.Fuente.archivoNombre.split(".")[1];
      const res = await WebApi.api().post("ArchivosFuentes",data.Fuente);
      return {
        success: true,
        message: "Configuración de datos almacenada",
        show: true,
      };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);


export const eliminarFuenteLetra = createAsyncThunk(
  "fuentesLetra/eliminarFuenteLetra",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        "ArchivosFuentes/" + id,
        { estadoId: 2 }
      );
      return {
        success: true,
        message: "Configuración de datos eliminada",
        show: true,
      };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

const initialState = {
  fuentesLetra: [],
};

export const fuentesLetraSlice = createSlice({
  name: "fuentesLetra",
  initialState,
  extraReducers: {
    [obtenerFuentesLetra.fulfilled]: (state, action) => {
      state.fuentesLetra = action.payload;
    },
  },
});

export default fuentesLetraSlice.reducer;
