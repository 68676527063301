import { jsPDF } from "jspdf";

function useCanvasToPdf(canvasSize, scale, name, setScale) {
  const addPagesToPdf = () => {
    setScale(190);

    setTimeout(() => {
      var pdf = new jsPDF("l", "px", [
        canvasSize.canvasWidth,
        canvasSize.canvasHeight,
      ]);
      let pages = Array.from(document.getElementsByTagName("canvas"));
      pages?.forEach((page) => {
        pdf.addPage();
        pdf.addImage(
          page.toDataURL({ pixelRatio: 2 }),
          0,
          0,
          canvasSize.canvasWidth,
          canvasSize.canvasHeight
        );
      });
      pdf.deletePage(1);
      pdf.save(`${name}.pdf`);

      setScale(55);
    }, 2000);
  };

  return addPagesToPdf;
}

export default useCanvasToPdf;
