import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerArchivoPorId = createAsyncThunk(
  "archivos/obtenerArchivoPorId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("Files/" + id);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const agregarArchivo = createAsyncThunk(
  "archivos/agregarArchivo",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Files", data);
      return {
        show: true,
        message: "Archivo agregado",
        success: true,
        result: res,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const actualizarArchivo = createAsyncThunk(
  "archivos/actualizarArchivo",
  async (data, { rejectWithValue }) => {
    try {
      await WebApi.api().put("Files/" + data.FileId, data);
      return {
        show: true,
        message: "Campos actualizados",
        success: true,
        result: [],
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const eliminarArchivo = createAsyncThunk(
  "archivos/eliminarArchivo",
  async (data, { rejectWithValue }) => {
    try {
      await WebApi.api().delete("Files/" + data.FileId);
      return {
        show: true,
        message: "Archivo eliminado",
        success: true,
        result: [],
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const archivosSlice = createSlice({
  name: "archivos",
  initialState: {
    isLoading: true,
    archivo: {},
    result: { success: false, message: "", show: false },
  },
  extraReducers: {
    [obtenerArchivoPorId.fulfilled]: (state, action) => {
      state.archivo = action.payload;
    },
    [agregarArchivo.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [actualizarArchivo.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [eliminarArchivo.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
  },
});

export default archivosSlice.reducer;
