import React from "react";
import {motion, AnimatePresence} from 'framer-motion';

function Modal(props) {
 return(
    <AnimatePresence>
      {props.isVisible?(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} 
      className= "overlay modal-overlay"
    >
      <motion.div className={`card modal ${props.classN}`} initial={{scale: 0.2}} animate={{scale:1}} exit={{scale:0.1}} transition={{duration: 1}} >
        <div className="modal-header">
          <h3 className="modal-title">{props.title}</h3>
        </div>
        <div >
        {props.children}
        </div>
        <div className="modal-bottom">
          {props.bottomItems}
        </div>
      </motion.div>
    </motion.div>
      ):null}
    
      </AnimatePresence>
  )


}

export default Modal;
