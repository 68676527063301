import { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { obtenerRecursos, obtenerRecursosPorEmpresa } from '../../../Redux/reducers/recursosSlice';

export default function useCanvasResources(category,isDefault=true) {
    const [resources, setResources] = useState([]);
    const [isLoading, setIsLoading] = useState();

    const { recursos, recursosPorEmpresa} = useSelector((state) => state.recursos);
    const dispatch = useDispatch();
  
    useEffect(() => {
      loadResources();
    }, [category,isDefault]);

    const loadResources = () => {
      setResources(null);
      setIsLoading(true);
      if(isDefault)
      dispatch(obtenerRecursos(category));
      else
      dispatch(obtenerRecursosPorEmpresa(category));
    };

    useEffect(()=>{
      if(isDefault){
        if(recursos?.length>0){
          setResources(recursos);
          setIsLoading(false);
        }
      }else{
        if(recursosPorEmpresa?.length>0){
          setResources(recursosPorEmpresa);
          setIsLoading(false);
        }

      }
    },[recursos,recursosPorEmpresa])
  

    return [resources,loadResources,isLoading];
}