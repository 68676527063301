import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  obtenerPlantillas,
  eliminarPlantilla,
} from "../Redux/reducers/plantillasSlice";
import { obtenerArchivoPorId } from "../Redux/reducers/archivosSlice";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import Card from "../Components/Other/Card";
import ContextActions from "../Components/DataBooks/ContextActions";
import Options from "../Components/DataBooks/Options";
import { useNavigate } from "react-router-dom";
import PageActions from "../Components/DataBooks/PageActions";
import NoResults from "../IMG/NoResults.svg";
import ModalRemove from "../Components/Other/ModalRemove";
import MosaicData from "../Components/DataBooks/MosaicData";
import WebApi from "../Api/WebApi";

export default function ListPlantillas() {
  const [wayOfDisplayData, setWayOfDisplayData] = useState(1);
  const [removingItem, setRemovingItem] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { plantillas } = useSelector((state) => state.plantillas);

  const fetchData = async (urls) => {
    try {
      const responses = await Promise.all(
        urls.map(async (url) => {
          const response = await WebApi.api().get(url);
          return response.data;
        })
      );
      const updatedData = plantillas?.Result?.map((item) => {
        const response = responses.find(
          (res) => res.Result.FileId === item.FileId
        );
        return {
          ...item,
          ...response?.Result,
        };
      });
      setDataSource(updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    cargarDatos();
  }, [page, registrosPorPagina]);

  useEffect(() => {
    if (plantillas?.Result?.length > 0) {
      const urls = plantillas?.Result?.map(
        (plantilla) =>
          `${process.env.REACT_APP_BASEURL}Files/${plantilla.FileId}`
      );
      fetchData(urls);
    }
  }, [plantillas]);

  const cargarDatos = (nombre = null) => {
    dispatch(
      obtenerPlantillas({ count: registrosPorPagina, page: page, nombre })
    );
  };

  const eliminar = async (id) => {
    let { payload } = await dispatch(eliminarPlantilla(id));
    if (payload.success) {
      setDeleteSuccess(true);
      setTimeout(() => {
        setRemovingItem(null);
        setDeleteSuccess(false);
      }, 2000);
      cargarDatos();
      return true;
    }
  };

  const renderTable = () => {
    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>Previsualización</th>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>
            {dataSource.map((item) => (
              <tr key={item.PlantillaCertificadoId}>
                <td>
                  <img
                    alt="Previsualización de plantilla"
                    src={item.Data}
                  ></img>
                </td>
                <td>{item.PlantillaNombre}</td>
                <td>
                  <Options
                    actions={[
                      {
                        icon: <i className="Material-Icons-Outlined"></i>,
                        text: "Editar",
                        onclick: () =>
                          navigate(
                            `edicion-plantilla/${item.PlantillaCertificadoId}`
                          ),
                      },
                      {
                        icon: <i className="material-icons"></i>,
                        text: "Crear proyecto",
                        onclick: () =>
                          navigate(
                            `/nuevo-certificado/${item.PlantillaCertificadoId}`
                          ),
                      },
                      item.EsPredefinida === false && {
                        icon: <i className="Material-Icons-Outlined"></i>,
                        text: "Eliminar",
                        onclick: () => setRemovingItem(item),
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <ModalRemove
        success={deleteSuccess}
        title="Eliminar plantilla"
        onRecoil={() => setRemovingItem(null)}
        onSubmit={() => eliminar(removingItem?.PlantillaCertificadoId)}
        isVisible={removingItem !== null}
        recordName={removingItem?.PlantillaNombre}
      />

      <ContextActions
        wayOfDisplayData={wayOfDisplayData}
        setWayOfDisplayData={setWayOfDisplayData}
        btnNew="Nueva plantilla"
        pathToNew="/nueva-plantilla"
        searchFunction={(nombre) => cargarDatos(nombre)}
      />
      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card
          isLoading={loading}
          type={wayOfDisplayData === 1 ? "mosaic" : "table"}
          cantidad={8}
        >
          <h2 className="dark-text section-name">Plantillas prediseñadas</h2>
          {!dataSource.length > 0 ? (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          ) : wayOfDisplayData === 1 ? (
            <MosaicData
              data={dataSource}
              imageField="Data"
              idField="PlantillaCertificadoId"
              titleField="PlantillaNombre"
              actions={(item) => [
                {
                  icon: <i className="Material-Icons-Outlined"></i>,
                  text: "Editar",
                  onclick: () =>
                    navigate(
                      `/edicion-plantilla/${item.PlantillaCertificadoId}`
                    ),
                },
                {
                  icon: <i className="material-icons"></i>,
                  text: "Crear proyecto",
                  onclick: () =>
                    navigate(
                      `/nuevo-certificado/${item.PlantillaCertificadoId}`
                    ),
                },
                item.EsPredefinida === false && {
                  icon: <i className="Material-Icons-Outlined"></i>,
                  text: "Eliminar",
                  onclick: () => setRemovingItem(item),
                },
              ]}
            />
          ) : (
            renderTable()
          )}
          <PageActions
            count={plantillas?.PageCount}
            onChange={(value) => setPage(value)}
            changeRegistersCount={(value) => setRegistrosPorPagina(value)}
            registersCount={registrosPorPagina}
          />
        </Card>
      </motion.main>
    </>
  );
}
