import jwtDecode from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import imagotipo from "../../IMG/Imagotipo.svg";
import isotipo from "../../IMG/Isotipo.svg";
import noImg from "../../IMG/no-person-img.png";
import { obtenerUsuarioPorId } from "../../Redux/reducers/usuariosSlice";
import MenuItem from "./MenuItem";
import SideSubmenu from "./SideSubmenu";

function Sidebar({ isOpened, toggleMenu }) {
  const subMenuButton = useRef();
  const [isSubmenuActive, setSubmenuActive] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const user = jwtDecode(token);
  const { usuario } = useSelector((state) => state.usuarios);
  useEffect(() => {
    dispatch(obtenerUsuarioPorId(user.id));
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  };

  const menuButtons = [
    {
      route: "/",
      icon: <i className="material-icons"></i>,
      name: "Inicio",
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas","generador(a) de plantillas"],
    },
    {
      route: "plantillas",
      icon: <i className="Material-Icons-Outlined"></i>,
      name: "Plantillas",
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas","generador(a) de plantillas"],
    },
    {
      route: "certificados",
      icon: <i className="material-icons"></i>,
      name: "Tus certificados",
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas"],
    },
    {
      route: "usuarios",
      icon: <i className="material-icons"></i>,
      name: "Usuarios",
      roles: ["administrador(a) del sistema","administrador(a) de plantillas"],
    },
    {
      route: "empresas",
      icon: <i className='Material-Icons-Outlined'></i>,
      name: "Empresas",
      roles: ["administrador(a) del sistema"],
    },
  ];

  const menuButtonsOnMobile = [
    {
      route: "configuraciones",
      icon: <i className="Material-Icons-Outlined"></i>,
      name: "Configuración",
      roles: ["administrador(a) del sistema", "administrador(a) de plantillas","generador(a) de plantillas"],
    },
  ];

  const extractRoles = () => {
    let token = localStorage.getItem("token");

    let jsonToken = jwtDecode(token)

    if (!Array.isArray(jsonToken.roles)) return [jsonToken.roles];
    else return jsonToken.roles;
  };

  const buscarCoincidencia = (arr1, arr2) => {
    for (var i = 0; i < arr1.length; i++) {
      if (arr2.indexOf(arr1[i]) != -1) return true;
    }
    return;
  };

  const displaySubMenu = () => {
    setSubmenuActive(!isSubmenuActive);
  };

  const getClassButtonMenu = ({ isActive }) => {
    return isActive
      ? "btn main-menu-button is-selected"
      : "btn main-menu-button";
  };

  return (
    <>
      <div
        id="fake-vertical-menu"
        className={
          isOpened ? "is-hidden-on-mobile" : "is-closed is-hidden-on-mobile"
        }
      ></div>
      <nav className={!isOpened ? "vertical-menu is-closed" : "vertical-menu"}>
        <div className="logo-container">
          {!isOpened ? (
            <img src={isotipo} alt="Logo empresa" />
          ) : (
            <img src={imagotipo} alt="Logo empresa" />
          )}
        </div>
        <div className="options-container">
          <section className="main-options">
            <div className="is-visible-on-mobile">
              <button className="btn user-btn bold">
                <img
                  src={noImg}
                  className="circular-container-sm"
                  alt="Foto de perfil"
                />
                <span>
                  {usuario.FullName ? usuario.FullName : "Menú de usuario"}
                </span>
              </button>
            </div>
            <ul>
              {menuButtons?.map((btn, i) =>
                buscarCoincidencia(extractRoles(), btn.roles) ? (
                  <li key={i}>
                    <MenuItem
                      route={btn.route}
                      class={getClassButtonMenu}
                      icon={btn.icon}
                      name={btn.name}
                    />
                  </li>
                ) : null
              )}
              {/* <li>
                <button
                  ref={subMenuButton}
                  onClick={displaySubMenu}
                  className="btn main-menu-button sub-menu-trigger"
                >
                  <i className="Material-Icons-Outlined"></i>
                  <span>Estadísticas</span>
                </button>
              </li> */}
            </ul>
            <div className="is-visible-on-mobile">
              <ul>
                {menuButtonsOnMobile.map((btn, i) => (
                  <li onClick={toggleMenu} key={i}>
                    <MenuItem
                      route={btn.route}
                      class={getClassButtonMenu}
                      icon={btn.icon}
                      name={btn.name}
                    />
                  </li>
                ))}
                <li>
                  <MenuItem
                    class="btn main-menu-button"
                    icon={<i className="Material-Icons-Outlined"></i>}
                    name="Cerrar Sesión"
                  />
                </li>
              </ul>
            </div>
          </section>
          <SideSubmenu
            isSubmenuActive={isSubmenuActive}
            displaySubMenu={displaySubMenu}
          ></SideSubmenu>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
