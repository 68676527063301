import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FormInput } from "../../Form/FormElements";

function Generator({
  settings = null,
  setFieldsData,
  pageIntersected,
  fieldsData,
  projectDescription,
  setProjectDescription,
  projectName,
  setProjectName,
  setCreationMethod,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const addCertificateData = (data) => {
    if (JSON.stringify(fieldsData) === JSON.stringify(["0"])) {
      setCreationMethod(3);
      setFieldsData([data]);
    } else {
      setFieldsData(fieldsData.concat([data]));
    }
  };

  const formInputs =
    settings !== null &&
    settings.map((c) => {
      const id = c.Clave;
      return (
        <div key={id}>
          <Controller
            name={id}
            control={control}
            rules={{ required: c.EsObligatorio }}
            render={({ field }) => (
              <div>
                <FormInput
                  error={errors[c.Clave] && "Este campo es requerido"}
                  value={field.value}
                  onChange={field.onChange}
                  label={c.Label}
                  type={c.TipoDatoNombre}
                  required={c.EsObligatorio}
                />
              </div>
            )}
          />
        </div>
      );
    });

  return (
    <div className="generator-main-container">
      <h4>Campos de inserción de datos en certificado</h4>
      {settings !== null ? (
        <>
          <div className="flex-container">
            <div className="generator-inputs-container">
              <form>
                <div className="form-inputs-container">{formInputs}</div>

                <button
                  className="btn btn-primary"
                  onClick={handleSubmit(addCertificateData)}
                >
                  Agregar
                </button>
              </form>
            </div>
          </div>
          <h4>Información del proyecto:</h4>
          <div className="flex-container">
            <div className="generator-inputs-container flex-container project-info-container">
              <div className="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Descripción</label>
                <textarea
                  value={projectDescription}
                  onChange={(e) => setProjectDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <h4>Aún no se ha aplicado ninguna configuración de datos</h4>
      )}
    </div>
  );
}

export default Generator;
