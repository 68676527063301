import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useSubmit from "../../../Hooks/useSubmit";
import Form from "../../../Components/Form/Form";
import FormToast from "../../../Components/Form/FormToast";
import ModalRemove from "../../../Components/Other/ModalRemove";
import Modal from "../../../Components/Other/Modal";
import {
  actualizarConfiguracionApi,
  almacenarConfiguracionApi,
  eliminarConfiguracionApi,
  obtenerConfiguracionApiById,
  obtenerConfiguracionesApi,
  obtenerMetodosApi,
  obtenerMetodosAuthApi,
  obtenerTiposDatosApi,
  obtenerTiposParametrosApi,
} from "../../../Redux/reducers/apiexternaSlice";
import Options from "../../../Components/DataBooks/Options";
import Table from "../../../Components/DataBooks/Table";

function ConfiguracionApi() {
  const [isRemovingItem, setIsRemovingItem] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AuthMethod, setAuthMethod] = useState();
  const [DataTypeParameter, setDataTypeParameter] = useState();

  const {
    configuracionApi,
    isLoading,
    configuracionesApi,
    metodosApi,
    metodosAuthApi,
    tiposDatosApi,
    tiposParametrosApi,
  } = useSelector((state) => state.configuracionApi);

  const { reset } = useForm();
  const dispatch = useDispatch();
  const headers = [
    { id: 1, name: "Nombre" },
    { id: 2, name: "Método" },
    { id: 3, name: "URL" },
    { id: 4, name: "Método de autorización" },
    { id: 5, name: "Usuario de autorización" },
  ];

  const { onSubmit, sendingData, successCode } = useSubmit(
    isEditing,
    almacenarConfiguracionApi,
    actualizarConfiguracionApi
  );

  useEffect(() => {
    setAuthMethod(0);
    cargarDatos();
    if (successCode == 1) {
      setIsModalVisible(false);
      setIsEditing(false);
      reset({});
    }
  }, [successCode]);

  useEffect(() => {
    if (isEditing) setIsModalVisible(true);
  }, [isEditing]);

  const getAuthFields = () => {
    let AuthUser = {
      type: "text",
      label: "Usuario de autorización",
      required: false,
      rules: {
        required: false,
      },
      defaultValue: null,
    };

    let AuthPassword = {
      type: "password",
      label: "Contraseña",
      required: false,
      rules: {
        required: false,
      },
      defaultValue: null,
    };

    if (AuthMethod == 2) {
      return { AuthUser, AuthPassword };
    }
  };

  const getMetadataField = () => {
    let Metadata = {
      type: "text",
      label: "Metadata",
      required: false,
      rules: {
        required: false,
      },
      defaultValue: null,
    };

    if (DataTypeParameter == 3) return { Metadata };
  };

  const fieldsDataForm = {
    ImportName: {
      label: "Nombre del API",
      type: "text",
      required: true,
      rules: {
        required: true,
      },
    },
    MethodId: {
      label: "Método",
      type: "dropdown",
      defaultValue: "",
      options: metodosApi,
      optionIdName: "MethodId",
      optionValueName: "MethodName",
      required: true,
      rules: {
        required: true,
      },
    },
    EndpointURL: {
      label: "URL",
      type: "text",
      required: true,
      rules: {
        required: true,
      },
    },
    AuthMethodId: {
      type: "dropdown",
      label: "Método de autorización",
      defaultValue: "",
      options: metodosAuthApi,
      optionIdName: "AuthMethodId",
      optionValueName: "AuthMethodName",
      required: true,
      changeHand(e) {
        setAuthMethod(e.target.value);
      },
      rules: {
        required: true,
      },
    },
    ...getAuthFields(),
    dynamicFieldArray: {
      DataTypeId: {
        label: "Tipo de dato",
        type: "dropdown",
        defaultValue: "",
        options: tiposDatosApi,
        optionIdName: "DataTypeId",
        optionValueName: "DataTypeName",
        required: true,
        changeHand(e) {
          setDataTypeParameter(e.target.value);
        },
        rules: {
          required: true,
        },
      },
      ParameterTypeId: {
        label: "Tipo de parámetro",
        type: "dropdown",
        defaultValue: "",
        options: tiposParametrosApi,
        optionIdName: "ParameterTypeId",
        optionValueName: "ParameterTypeName",
        required: true,
        rules: {
          required: true,
        },
      },
      ...getMetadataField(),
      KeyName: {
        label: "Clave del campo",
        type: "text",
        defaultValue: "",
        required: true,
        rules: {
          required: true,
        },
      },
      Label: {
        label: "Etiqueta del parámetro",
        type: "text",
        defaultValue: "",
        required: true,
        rules: {
          required: true,
        },
      },
      Value: {
        label: "Valor",
        type: "text",
        defaultValue: "",
        required: false,
        rules: {
          required: false,
        },
      },
      IsFixed: {
        label: "¿Es fijo?",
        type: "checkbox",
        defaultValue: false,
        required: false,
      },
      IsMandatory: {
        label: "¿Es obligatorio?",
        type: "checkbox",
        defaultValue: false,
        required: false,
      },
    },
  };

  const cargarDatos = () => {
    dispatch(obtenerMetodosApi());
    dispatch(obtenerMetodosAuthApi());
    dispatch(obtenerTiposDatosApi());
    dispatch(obtenerTiposParametrosApi());
    dispatch(obtenerConfiguracionesApi());
  };

  const eliminar = async (id) => {
    let { payload } = await dispatch(eliminarConfiguracionApi(id));
    setDeleteSuccess(true);
    setTimeout(() => {
      setIsRemovingItem(false);
      setDeleteSuccess(false);
    }, 2000);
    cargarDatos();
  };

  const renderTable = () => {
    return (
      <Table class="table display dataTable" isLoading={isLoading}>
        <thead>
          <tr>
            {headers?.map((header) => (
              <th key={header.id}>{header.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {configuracionesApi?.map((item, index) => (
            <tr key={item.ApiId}>
              <td>{item.ImportName}</td>
              <td>
                {
                  metodosApi.find((e) => e.MethodId == item.MethodId)
                    ?.MethodName
                }
              </td>
              <td>{item.EndpointURL}</td>
              <td>{item.AuthUser ? item.AuthUser : "N/A"}</td>
              <td>
                <Options
                  actions={[
                    {
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Editar",
                      onclick: () => {
                        dispatch(obtenerConfiguracionApiById(item.ApiId));
                        setIsEditing(true);
                      },
                    },
                    {
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Eliminar",
                      onclick: () => {
                        dispatch(obtenerConfiguracionApiById(item.ApiId));
                        setIsRemovingItem(true);
                      },
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <AnimatePresence>
      <div className="form-title" key="optionsApi">
        <br />
        <br />
        <button
          className="btn btn-primary settings-right-superior-container"
          onClick={() => setIsModalVisible(true)}
        >
          Nueva fuente de datos externa
        </button>
      </div>
      <motion.div className="flex-container data-settings-container" key="apiSettingsContainer">
        <div>
          <div className="editor-data-settings-container">
            <div className="editor-data-settings">
              <div className="flex-container">
                <h3>Fuentes de datos externas</h3>
              </div>
              {renderTable()}
              <ModalRemove
                success={deleteSuccess}
                onRecoil={() => setIsRemovingItem(false)}
                title="Eliminar configuración de datos"
                onSubmit={() => eliminar(configuracionApi?.ApiId)}
                isVisible={isRemovingItem}
                recordName={configuracionApi?.ImportName}
              />
            </div>
            <Modal
              isVisible={isModalVisible}
              title={
                isEditing
                  ? "Editar configuración de datos"
                  : "Nueva configuración de datos"
              }
            >
              <Form
                dynamicForm={fieldsDataForm}
                btnAddToArray="Agregar campo"
                onSubmit={(data) => onSubmit(data)}
                successCode={successCode}
                isEditing={isEditing}
                dataToEdit={isEditing && configuracionApi}
                onRecoil={() => {
                  setAuthMethod(0);
                  reset({});
                  setIsEditing(false);
                  setIsModalVisible(false);
                }}
                sendingData={sendingData}
              />
              <FormToast
                code={successCode}
                title={successCode == 1 ? "¡Bien!" : "Algo anda mal"}
                desc={
                  successCode == 1
                    ? "Datos almacenados correctamente"
                    : "Revisa los datos e intenta de nuevo"
                }
              />
            </Modal>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default ConfiguracionApi;
