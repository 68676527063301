import React, { useRef, useEffect} from "react";
import { Text, Transformer } from "react-konva";
import { Html } from "react-konva-utils";

 function ResizableText({
    text,
    textProps,
    isSelected,
    fontSize=16,
    onSelect,
    onDoubleClick,
    onChange,
    onContextMenu
  }) {
    const textRef = useRef(null);
    const transformerRef = useRef(null);
  
    useEffect(() => {
      if (isSelected && transformerRef.current !== null) {
        transformerRef.current.nodes([textRef.current]);
        transformerRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
  
    function handleResize() {
      if (textRef.current !== null) {
        const textNode = textRef.current;
        const scaleX = textNode.scaleX();
        const scaleY = textNode.scaleY();
  
        textNode.scaleX(1);
        textNode.scaleY(1);
        onChange({
          ...textProps,
          x: textNode.x(),
          y: textNode.y(),
  
          width: textNode.width() * scaleX,
          height: textNode.height() * scaleY,
          rotation: textNode.attrs.rotation
        });
      }
    }
  
    return (
      <>
        <Text
          onClick={onSelect}
          onTap={onSelect}
          ref={textRef}
          fontSize={fontSize}
          perfectDrawEnabled={false}
          onTransformEnd={handleResize}
          draggable
          onDragEnd={(e) => {
            onChange({
              ...textProps,
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
          onDblClick={onDoubleClick}
          onDblTap={onDoubleClick}
          onContextMenu={onContextMenu}
          {...textProps}
          text={text}
        />
        {isSelected && (
          <Transformer
            ref={transformerRef}
          //   enabledAnchors={["middle-left", "middle-right"]}
            boundBoxFunc={(oldBox, newBox) => {
              newBox.width = Math.max(30, newBox.width);
              return newBox;
            }}
          />
        )}
      </>
    );
  }
  

function EditableTextInput({
    x,
    y,
    isEditing,
    onChange,
    text,
    width,
    height,
    value,
    textProps
  }) {
  
    function handleTextChange(e) {
      onChange({
        ...textProps,
        text:e.currentTarget.value
      });
    }
  
    if (isEditing) {
      const style = getStyle(width, height);
      return (
        <Html groupProps={{ x, y }} divProps={{ style: { opacity: 1 } }}>
          <textarea value={value} onChange={(e)=>handleTextChange(e)} style={style} />
        </Html>
      );
    }
    return <Text x={x} y={y} width={width} text={text} />;
  }

  function getStyle(width, height) {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    const baseStyle = {
      width: `${width}px`,
      height: `${height}px`,
      border: "none",
      padding: "0px",
      margin: "0px",
      background: "none",
      outline: "none",
      resize: "none",
      color: "black",
      fontSize: "16px",
      fontFamily: "sans-serif",
    };
    if (isFirefox) {
      return baseStyle;
    }
    return {
      ...baseStyle,
      margintop: "-4px",
    };
  }
  
  export {ResizableText,EditableTextInput}