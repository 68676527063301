import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import useParseExcel from "../../../Hooks/useParseExcel";
import { FormInput } from "../../Form/FormElements";

function GeneratorOptions({
  settings = null,
  setFieldsData,
  setIsModalVisible,
  setCreationMethod
}) {
  const { downloadTemplate, handleFile, jsonResult } = useParseExcel([]);
  const [wbData, setWbData] = useState();

  useEffect(() => {
    let workbookData = {};
    let array = [];
    if (settings) {
      settings?.forEach((element) => {
        workbookData[element.Clave] = "";
      });
      array.push(workbookData);
      setWbData(array);
    }
  }, [settings]);

  useEffect(() => {
    if (jsonResult !== undefined) {
      setFieldsData(jsonResult);}
  }, [jsonResult]);

  return (
    <div>
      {settings !== null ? (
        <div className="generator-options-container flex-container">
          <FormInput
            type="file"
            onChange={(e) => {handleFile(e); setCreationMethod(1)}}
            placeholder="Seleccionar excel"
            label="Subir archivo de excel"
            directChange={true}
          />
          <br />
          <button
            className="btn btn-secondary"
            onClick={() => downloadTemplate(wbData, "Plantilla")}
          >
            Descargar plantilla de datos
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {setIsModalVisible(true); setCreationMethod(2)}}
          >
            Obtener datos de API
          </button>
          <br />
        </div>
      ) : (
        <h4>Aún no se ha aplicado ninguna configuración de datos</h4>
      )}
    </div>
  );
}

export default GeneratorOptions;
