import React, { useEffect, useState, useRef } from "react";
import ReactSelect from "../Other/ReactSelect";

function FormInput(props) {
  const [error, setError] = useState();
  const switchForm = useRef();
  const writableSelectRef = useRef();
  const inputWritableSelect = useRef();
  const rangeNumberInputRef = useRef();
  const rangeInputRef = useRef();
  const inputFileRef = useRef();
  const fileNameRef = useRef();
  const [archivo, setArchivo] = useState(null);

  useEffect(() => {
    if (archivo !== null) props.onChange(archivo);
  }, [archivo]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const expresiones = {
    usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{7,14}$/, // 7 a 14 numeros.
  };

  const errorMsg = {
    password: "La contraseña debe ser de 4 a 12 dígitos.",
    correo: "El formato del correo electrónico no es válido.",
  };

  const validate = (e) => {
    let valid;
    if (props.type === "email") {
      valid = expresiones.correo.test(e.target.value);
      !valid ? setError(errorMsg.correo) : setError();
    } else if (props.type === "password") {
      valid = expresiones.password.test(e.target.value);
      !valid ? setError(errorMsg.password) : setError();
    }
  };

  switch (props.type) {
    case "text":
    case "email":
    case "date":
    case "password":
      return (
        <div
          className={props.long ? "form-group form-group-large" : "form-group"}
        >
          {props.label && <label>{props.label}</label>}
          <div className={props.required ? "is-required" : ""}>
            {props.icon ? (
              props.icon
            ) : props.required ? (
              <i
                className={`material-icons required-symbol ${
                  error ? "red-icon" : "input-symbol"
                }`}
              >
                
              </i>
            ) : null}
            <input
              type={props.type}
              className={error ? "is-invalid" : null}
              placeholder={props.placeholder && props.placeholder}
              autoComplete="off"
              onChange={props.onChange}
              onBlur={validate}
              value={props.value}
              name={props.name}
              {...props}
            />
          </div>
          {error ? <p className="is-invalid error-info">{error}</p> : undefined }
          
        </div>
      );

    case "dropdown":
      return (
        <div className="form-group">
          <label>{props.label}</label>
          <div className={props.required ? "is-required select-required" : ""}>
            {props.required ? (
              <i
                className={`material-icons required-symbol  ${
                  error ? "red-icon" : "input-symbol"
                }`}
              >
                
              </i>
            ) : (
              props.icon
            )}
            <select
              onChange={(e) => props.onChange(e)}
              className={error ? "is-invalid" : ""}
              value={props.value}
              style={props.style}
              defaultValue={props.defaultValue}
              hidden={props.hidden}
            >
              <option hidden value="">
                {props.placeholder}
              </option>
              {props.options?.map((option, i) => (
                <option key={i} value={option[props.optionIdName]} selected={option[props.optionIdName]==props.value}>
                  {option[props.optionValueName]}
                </option>
              ))}
            </select>
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "dropdown-multiple":
      return (
        <div
          className={props.long ? "form-group form-group-large" : "form-group"}
        >
          <label>{props.label}</label>
          <ReactSelect
            onChange={(val) => props.onChange(val.map((c) => c.value))}
            name={props.name}
            value={props.options.filter((c) => props.value?.includes(c.value))}
            options={props.options}
            isMulti={true}
            isValid={error == undefined}
            isRequired={props.required}
          />
          {error && <p className="is-invalid error-info">{error}</p>}
        </div>
      );

    case "radio":
      return (
        <div className="form-group">
          <label>{props.label}</label>
          <div>
            {props.options?.map((option) => (
              <div key={option}>
                <input
                  type="radio"
                  label={option}
                  value={option}
                  onChange={(value) => props.onChange(value)}
                  checked={props.value === option}
                />
                <label>{option}</label>
              </div>
            ))}
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "checkbox":
      return (
        <div className="form-group">
          <>
            <input
              type="checkbox"
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
            />
            <label>{props.label}</label>
          </>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "switch":
      return (
        <div>
          <div className="flex-container">
            <label>{props.label}</label>
            <div className="switch-container">
              <input
                type="checkbox"
                ref={switchForm}
                className="checkbox-switch"
                checked={props.value}
                readOnly
              />
              <label
                className="fake-switch"
                onClick={() => {
                  switchForm.current.checked = !switchForm.current.checked;
                  props.onChange(switchForm.current.checked);
                }}
              >
                <span>{props.positiveOption}</span>
                <span>{props.negativeOption}</span>
              </label>
            </div>
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "simple-switch":
      return (
        <div>
          <div className="flex-container">
            <label>{props.label}</label>
            <div className="switch-container">
              <input
                type="checkbox"
                ref={switchForm}
                className="checkbox-switch"
                checked={props.value}
                readOnly
              />
              <label
                className="fake-switch fake-switch-unlabeled"
                onClick={() => {
                  switchForm.current.checked = !switchForm.current.checked;
                  props.onChange(switchForm.current.checked);
                }}
              ></label>
            </div>
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "color-picker":
      return (
        <>
          <div className="flex-container">
            <label>{props.label}</label>
            <input
              className="color-picker"
              type="color"
              value={props.value}
              onChange={props.onChange}
            ></input>
          </div>
          <p className="is-invalid error-info">{error}</p>
        </>
      );

    case "writable-dropdown":
      return (
        <div className="form-group">
          <label>{props.label}</label>
          <div className={props.required ? "is-required select-required" : ""}>
            {props.required ? (
              <i
                className={`material-icons required-symbol  ${
                  error ? "red-icon" : "input-symbol"
                }`}
              >
                
              </i>
            ) : (
              props.icon
            )}
            <input
              className="writable-select-input"
              type="number"
              value={props.value ? props.value : props.options[0].value}
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
              ref={inputWritableSelect}
            />
            <select
              onChange={(e) => {
                inputWritableSelect.current.value = e.target.value;
                props.onChange(e.target.value);
              }}
              className={error ? "is-invalid" : ""}
              ref={writableSelectRef}
            >
              <option hidden value="">
                {props.placeholder ? props.placeholder : "Seleccione"}
              </option>
              {props.options?.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "number":
      return (
        <div className="flex-container range-container">
          <input
            className="number-input"
            ref={rangeNumberInputRef}
            type="number"
            min={props.min ? props.min : "0"}
            max={props.max ? props.max : "100"}
            defaultValue={props.value}
            onChange={(v) => props.onChange(v.target.value)}
          />
        </div>
      );

    case "range":
      const changeInputsRange = (value) => {
        rangeInputRef.current.value = value;
        rangeNumberInputRef.current.value = value;
        props.onChange(value);
      };
      return (
        <div className="flex-container range-container">
          <input
            ref={rangeInputRef}
            id={props.id}
            type="range"
            defaultValue={props.value}
            min={props.min ? props.min : "0"}
            max={props.max ? props.max : "100"}
            onChange={(v) => changeInputsRange(v.target.value)}
          />
          <input
            className="input-transparent number-input"
            ref={rangeNumberInputRef}
            type="number"
            min={props.min ? props.min : "0"}
            max={props.max ? props.max : "100"}
            defaultValue={props.value}
            onChange={(v) => changeInputsRange(v.target.value)}
          />
          <label className="number-input-unit">{props.unit}</label>
        </div>
      );

    case "file":
      const handleChangeFile = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        fileNameRef.current.value = file.name;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          setArchivo({
            archivoNombre: file.name,
            ContentType: file.type,
            data: reader.result,
          });
        };
      };

      return (
        <div className="file-input-container">
          <button
            className="btn btn-file"
            onClick={() => inputFileRef.current.click()}
          >
            <i className="Material-Icons-Outlined"></i>
          </button>
          <input
            readOnly
            className="file-input-name"
            ref={fileNameRef}
            onClick={() => inputFileRef.current.click()}
            placeholder={props.placeholder}
          ></input>
          <input
            ref={inputFileRef}
            type="file"
            id="file"
            aria-label="Subir archivo"
            style={{
              visibility: "hidden",
              position: "absolute",
              pointerEvents: "none",
            }}
            onChange={(e) => {
              props.directChange ? props.onChange(e) : handleChangeFile(e);
            }}
          />
        </div>
      );

    case "pop-apps":
      return (
        <div>
          <div className="flex-container">
            <img className="card-app-img-container" src={props.image} />
          </div>
        </div>
      );

    default:
      return null;
  }
}

export { FormInput };
