import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion} from "framer-motion";
import { jsPDF } from "jspdf";
import Card from "../Components/Other/Card";
import { obtenerCertificadoPorGUID } from "../Redux/reducers/proyectosSlice";
import { useParams } from "react-router-dom";

export default function CertificadoGUID() {
  const dispatch = useDispatch();
  const { certificado, isLoading } = useSelector((state) => state.proyectos);

  const params = useParams();
  let certificadoRef = useRef();

  useEffect(() => {
    dispatch(obtenerCertificadoPorGUID(params.guid));
  }, []);

  const addImgToPdf = () => {
    var pdf = new jsPDF("l", "px", [680, 420]);
    pdf.addImage(certificadoRef.current, 0, 0, 680, 420);
    pdf.save("Certificado.pdf");
  };

  return (
    <>
      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card isLoading={isLoading} cantidad={1} type="mosaic" classN="guid-view-card">
          <img
            ref={certificadoRef}
            className="guid-view-img"
            src={certificado[0]?.Certificado}
            alt="Imagen del certificado validado a través de código QR"
          />
          <button
            className="btn btn-primary btn-guid-view"
            onClick={() => addImgToPdf()}
          >
            Descargar
          </button>
        </Card>
      </motion.main>
    </>
  );
}
